import {BehaviorSubject, forkJoin, Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {Injectable, EventEmitter} from '@angular/core'
import {ActivatedRouteSnapshot, Router} from '@angular/router'
import {WcbAuthService} from './auth.service'
import {TranslateService} from '@ngx-translate/core'
import {WcbUser} from '../../../views/secured/user/user'
import {environment} from '../../../../environments/environment'
import * as Sentry from '@sentry/angular-ivy'
import {SystemAclService} from '../../../views/secured/system/system.acl.service'
import {WcbSnackBar} from "../snack-bar/snack-bar.service";

@Injectable({
    providedIn: 'root'
})
export class WcbUserService {
    userDataChangedEmitter = new EventEmitter<WcbUser | null>()

    private user = new BehaviorSubject<WcbUser | null>(null)
    private acl = new BehaviorSubject([] as string[])

    constructor(
        private authService: WcbAuthService,
        private router: Router,
        private snackService: WcbSnackBar,
        private systemAclSettings: SystemAclService,
        public translate: TranslateService
    ) {
    }

    /**
     * Get current user
     * @returns {WcbUser}
     */
    getUser(): WcbUser | null {
        return this.user.value
    }

    /**
     * Get current user as BehaviorSubject
     */
    getUser$(): BehaviorSubject<WcbUser | null> {
        return this.user
    }

    isAllowed(action: string) {
        return this.acl.getValue().includes(action) || this.user.value?.role === 'dev'
    }

    isInAdminRole(role: string) {
        return ['dev', 'admin', 'manager', 'quotation'].includes(role)
    }

    isWorkerRole(role: string) {
        return ['worker'].includes(role)
    }

    /**
     * Try to get authenticated user before start
     */
    public tryInitUserAndAclByToken(): Promise<void> {
        return new Promise<void>((resolve) => {
            const authToken = localStorage.getItem(environment.authHeaderName);
            if (authToken) {
                forkJoin({user: this.authService.getByAuthToken(), acl: this.systemAclSettings.listForUser()})
                    .subscribe((data) => {
                        if (data && data.user) {
                            this.setUser(data.user);
                        }
                        if (data && data.acl) {
                            this.acl.next(data.acl)
                        }
                        resolve()
                    })
            } else {
                resolve()
            }
        })
    }

    /**
     * Check if user is authenticated
     * @returns {Observable<boolean> | boolean}
     */
    public isAuthenticated(): Observable<boolean> | boolean {
        if (!this.user.value) {
            const authToken = localStorage.getItem(environment.authHeaderName);
            if (authToken) {
                return this.authService.getByAuthToken().pipe(
                    map((user: WcbUser) => {
                        if (user) {
                            this.setUser(user);
                            return this.user.value?.authenticated || false;
                        } else {
                            this.router.navigate(['/pub/sign-in'])
                            return false;
                        }
                    })
                )
            }
        }

        if (!this.user.value?.authenticated) {
            this.router.navigate(['/pub/sign-in'])
        }
        return this.user.value?.authenticated || false;
    }

    /**
     * Check if user is authorized to go to route
     * @param {RouterStateSnapshot} router
     * @returns {Observable<boolean>}
     */
    public isAuthorized(router: ActivatedRouteSnapshot): Observable<boolean> {
        const route = '/' + router.pathFromRoot.flatMap(part => part.url).join('/');
        return this.acl.pipe(
            // skipWhile(acls => !acls.length),
            map((acls) => {
                if (this.user.value && this.user.value?.role === 'dev') {
                    return true;
                }
                for (let i = 0; i < acls.length; i++) {
                    const regexp = new RegExp('^\\' + acls[i] + '#?.?$')
                    if (regexp.test(route)) {
                        return true
                    }
                }
                console.log('Denied route: ' + route);
                this.snackService.warning(this.translate.instant('snack.permission-denied'))
                if (this.user.value && !this.isInAdminRole(this.user.value?.role || 'guest')) {
                    this.router.navigate(['/pub/sign-in']);
                }
                return false
            })
        )
    }

    /**
     * Sets current user
     * @param {WcbUser} data
     */
    setUser(data: WcbUser | any): Promise<void> {
        return new Promise((resolve, reject) => {
            if (data && data.uID) {
                data.authenticated = true
                this.user.next(data);
            } else {
                this.user.next({
                    uID: 0,
                    personalID: '',
                    username: '',
                    role: 'guest',
                    authenticated: false,
                    firstname: '',
                    lastname: '',
                    thumbnail: ''
                })
            }

            Sentry.setUser({
                id: data.uID ? data.uID.toString() : 0,
                username: (this.user.value?.firstname + ' ' + this.user.value?.lastname + '[' + this.user.value?.role + ']'),
                email: this.user.value?.username
            })
            this.userDataChangedEmitter.emit(this.user.value)

            if (data && data.role !== 'guest') {
                this.systemAclSettings.listForUser().subscribe(aclForRole => {
                    this.acl.next(aclForRole)
                    resolve()
                })
            } else {
                resolve()
            }
        })
    }

    /**
     * Perform new user password API request
     * @param {string} passwordOld
     * @param {string} passwordNew
     * @param {number} uID
     */
    setNewPassword(passwordOld: string, passwordNew: string, uID: number) {
        const passwordData = [passwordOld, passwordNew, uID.toString(10)];
        return this.authService.setPassword(passwordData);
    }

    /**
     * Destroys user on client (logout)
     * @param {boolean} auto
     */
    destroyUser(auto: boolean) {
        this.authService.logoutUser().subscribe(res => {
            const role = this.user.value?.role || 'guest';
            this.user.next(null);
            this.acl.next([]);
            localStorage.removeItem(environment.authHeaderName)
            this.userDataChangedEmitter.emit(this.user.value)
            this.snackService.success(this.translate.instant(auto ? 'snack.logout-automatically' : 'snack.logout-success'))
            if (this.isInAdminRole(role) || this.isWorkerRole(role)) {
                this.router.navigate(['/pub/sign-in'])
            } else if (environment.module.web || environment.module.gallery) {
                this.router.navigate(['/']);
            } else {
                this.router.navigate(['/pub/sign-in']);
            }
        });
    }
}
