import {
    ChangeDetectionStrategy,
    Component
} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-text-block]',
    templateUrl: './text-block.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class TextBlockComponent {

}
