import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    standalone: true
})
export class SanitizeUrlPipe implements PipeTransform {

    constructor(
        private sanitizer: DomSanitizer
    ) {

    }

    transform(url: string, args: 'html' | 'style' | 'script' | 'url' | 'resourceUrl' = 'url') {
        switch (args) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(url);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(url);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(url);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(url);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(url);
            default:
                return this.sanitizer.bypassSecurityTrustUrl(url)
        }

    }

}
