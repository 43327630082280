import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MatButtonModule} from '@angular/material/button'
import {MatCardModule} from '@angular/material/card'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatIconModule} from '@angular/material/icon'
import {TranslateModule} from '@ngx-translate/core'
import {CookieOverviewComponent} from './cookie-overview.component';
import {MatTableModule} from '@angular/material/table';
import {CookieSettingsComponent} from './cookie-settings.component/cookie-settings.component';
import {FormsModule} from '@angular/forms';
import {CookieAcceptModalComponent} from './cookie-accept-modal.component/cookie-accept.modal.component';
import {CookieConsentService} from './cookie.service';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatIconModule,
        TranslateModule,
        MatTableModule,
        MatDialogModule,
        FormsModule,
        CookieOverviewComponent,
        CookieSettingsComponent,
        CookieAcceptModalComponent
    ],
    providers: [
        CookieConsentService
    ],
    exports: [
        CookieAcceptModalComponent
    ]
})
export class CookieModule {
}
