import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {Subscription} from 'rxjs';
import {Gallery} from '../../../../views/secured/gallery/gallery';
import {GalleryService} from '../../../../views/secured/gallery/gallery.service';
import { ActivatedRoute, UrlSegment, RouterLink } from '@angular/router';
import {HeadTagsService} from '../../../services/head-tags.service';
import {Animations} from '../../../animations/animations';
import {skip} from 'rxjs/operators';
import {ImageViewerComponent} from '../image-viewer-component/image-viewer.component';
import {MatDialog} from '@angular/material/dialog';
import { ImageApiSrcPipe } from '../../../pipes/image-api-src.pipe';
import { SanitizeUrlPipe } from '../../../pipes/sanitize-url.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ImageFadeInDirective } from '../../../directives/image.fadeIn.directive';
import { ShowIfInViewDirective } from '../../../directives/showIfInView.directive';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, RouterLink, MatIconModule, NgFor, ShowIfInViewDirective, ImageFadeInDirective, TranslateModule, SanitizeUrlPipe, ImageApiSrcPipe]
})
export class GalleryComponent implements OnInit, OnDestroy {

    @Input() galleryAlias: string | false = false
    @ViewChild('wrapper') wrapper?: ElementRef
    dimensions?: { width: number, height: number }
    gallery?: Gallery
    sub: Subscription[] = []
    galleryReady = false
    backPage?: UrlSegment[] = []

    constructor(
        private headTagsService: HeadTagsService,
        private galleryService: GalleryService,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        this.backPage = this.galleryService.lastRefererPage
    }

    ngOnInit() {
        this.galleryAlias = this.activatedRoute.snapshot.paramMap.get('alias') || this.galleryAlias
        this.initialize()

        this.activatedRoute.paramMap.pipe(
            skip(1)
        ).subscribe((params) => {
            const alias = params.get('alias')
            if (alias) {
                this.galleryAlias = alias
                this.gallery = undefined;
                this.initialize()
            }
        })
    }

    ngOnDestroy() {
        this.sub?.forEach(s => s.unsubscribe())
    }

    initialize() {
        if (this.galleryAlias) {
            this.galleryService.getGalleryByAlias(this.galleryAlias).subscribe((gallery) => {
                this.gallery = gallery
                this.headTagsService.setTitle(this.gallery.name)

                setTimeout(() => {
                    this.dimensions = {
                        width: this.wrapper?.nativeElement.offsetWidth,
                        height: this.wrapper?.nativeElement.offsetHeight
                    }
                    this.galleryReady = true
                    this.cdr.markForCheck()
                }, 0)
            })
        }
    }

    openImageViewer(index: number) {
        if (this.gallery && this.gallery.images) {
            this.dialog.open(ImageViewerComponent, {
                disableClose: false,
                maxWidth: '90vw',
                maxHeight: '90vh',
                width: '90vw',
                height: '90vh',
                backdropClass: 'image-backdrop',
                data: {
                    index: index,
                    images: [...this.gallery.images]
                }
            })
        }
    }

}
