<div class="flex-col p-05" *ngIf="galleryReady && gallery">
    <div class="flex-row flex-wrap space-between items-center p-05">
        <h1 class="flex-50 flex-shrink flex-grow">{{gallery.name}}</h1>
        <a
            class="flex-50 flex-grow flex-shrink justify-end items-center flex-lt-sm-100 flex-lt-sm-justify-start flex-lt-sm-items-center"
            *ngIf="backPage && backPage.length" [routerLink]="['/', backPage.toString()]"
            title="{{'web.gallery.back-to-main'|translate}}">
            <mat-icon class="icon-xs">arrow_back</mat-icon>
            {{'web.gallery.back-to-main'|translate}}
        </a>
    </div>
    <div class="p-05" [innerHTML]="gallery.description|safe:'html'"></div>
    <div class="flex-row flex-wrap">
        <div *ngFor="let image of gallery.images; let index = index" @slideInOut
             class="flex-col flex-xs-100 flex-gt-xs-50 flex-gt-sm-33-33 flex-gt-md-25 p-05 pb-1">
            <div class="flex-col flex-grow justify-start items-stretch mat-elevation-z3 p-05">
                <img class="mt-05" [src]="image.path|imgAPISrc" loading="lazy"
                     alt="{{image.alt}}" appImageFadeIn *appShowIfInView (click)="openImageViewer(index)"/>
            </div>
        </div>
    </div>
    <a class="justify-start items-center p-05"
       *ngIf="backPage && backPage.length" [routerLink]="['/', backPage.toString()]"
       title="{{'web.gallery.back-to-main'|translate}}">
        <mat-icon class="icon-xs">arrow_back</mat-icon>
        {{'web.gallery.back-to-main'|translate}}
    </a>
</div>

