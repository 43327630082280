import {Injectable, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

interface ITheme {
    name: string;
    baseColor?: string;
    isActive?: boolean;
}

@Injectable({providedIn: 'root'})
export class ThemeService {
    public egretThemes: ITheme[] = [
        {
            name: 'front-theme',
            baseColor: '#3f51b5',
            isActive: false
        },
        {
            name: 'back-theme',
            baseColor: '#3f51b5',
            isActive: true
        }
    ];
    public activatedTheme?: ITheme;

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    // Invoked in AppComponent and apply 'activatedTheme' on startup
    changeTheme(r: Renderer2, index = 0) {
        const theme = this.egretThemes[index]
        if (theme) {
            this.egretThemes.forEach((t: ITheme) => r.removeClass(this.document.body, t.name))
            r.addClass(this.document.body, theme.name);
            this.flipActiveFlag(theme);
        }
    }

    flipActiveFlag(theme: ITheme) {
        this.egretThemes.forEach(t => {
            t.isActive = false;
            if (t.name === theme.name) {
                t.isActive = true;
                this.activatedTheme = theme;
            }
        });
    }
}
