import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {MenuItem} from '../../../menu';
import {PageService} from '../../../../../services/page.service';
import {map} from 'rxjs/operators';
import { RouterLink } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-menu-item-list-block]',
    templateUrl: './menu-item-list-block.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatListModule, NgFor, RouterLink, AsyncPipe]
})
export class MenuItemListBlockComponent {

    @Input() id: number | null = null
    @Input() mainMenuItemData?: { link: string }
    menuItem$: Observable<MenuItem>

    constructor(
        private pageService: PageService
    ) {
        this.menuItem$ = this.pageService.listActivePages().pipe(
            map((response) => response.data.filter(i => this.mainMenuItemData && i.link === this.mainMenuItemData.link)[0] || null)
        )
    }

}
