import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, HostListener, Input,
    OnInit, Output, ViewChild
} from '@angular/core'
import {EventEmitter} from '@angular/core'
import {debounceTime, skip, skipWhile} from 'rxjs/operators'
import {BehaviorSubject} from 'rxjs'
import {ImageData} from '../../../block'
import {defaultImageData, noImage} from '../../../default.blocks'
import {MatDialog} from '@angular/material/dialog'
import {ImageBlockSettingsComponent} from './image-block-settings-component/image-block-settings.component'
import {ImageApiSrcPipe} from '../../../../../pipes/image-api-src.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: '[app-image-block-editor]',
    templateUrl: './image-block-editor.component.html',
    styleUrls: ['./image-block-editor.component.scss']
    // changeDetection: ChangeDetectionStrategy.OnPush
    ,
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, MatSliderModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, ImageApiSrcPipe]
})
export class ImageBlockEditorComponent implements OnInit, AfterViewInit {

    imageDataSub: BehaviorSubject<any> = new BehaviorSubject<any>({})
    width = 100
    height = 100
    left = 0
    top = 0
    imageSrc: BehaviorSubject<string> = new BehaviorSubject<string>('')
    maxHeightInPercent = 100
    maxWidthInPercent = 0
    imageRatio = 1
    blockDimensions?: { width: number, height: number }
    loading = false

    @Input() id: number | null = null
    @Input() imageData: ImageData = defaultImageData
    @Output() blockData = new EventEmitter<any>()
    @ViewChild('image') image?: ElementRef
    @ViewChild('imageWrapper') imageWrapper?: ElementRef

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkDimensions()
    }

    constructor(
        private dialog: MatDialog,
        private element: ElementRef,
        private cd: ChangeDetectorRef,
        private apiSrcPipe: ImageApiSrcPipe
    ) {
    }

    ngOnInit() {
        this.height = this.imageData.height || this.height
        this.width = this.imageData.width || 0
        this.left = this.imageData.left || this.left
        this.top = this.imageData.top || this.top
        this.imageData.src = this.imageData.src || noImage
    }

    ngAfterViewInit() {
        this.checkDimensions()
        this.setRealImageDimensions().then(maxDimensions => {
            if (maxDimensions) {
                this.imageData.max = maxDimensions
            }
        })

        // new block
        if (!this.width) {
            this.setImageDimensionsToDefault()
            this.onEditImage(true)
        }

        this.imageDataSub = new BehaviorSubject<any>(this.imageData)
        this.imageDataSub.asObservable().pipe(
            skip(1),
            skipWhile(val => val.src === ''),
            debounceTime(600)
        ).subscribe(data => {
            this.blockData.emit(data)
            this.loading = false
        })

        this.cd.detectChanges()
    }

    checkDimensions() {
        this.blockDimensions = {
            width: this.element.nativeElement.offsetWidth,
            height: this.element.nativeElement.offsetHeight
        }
        this.setApiSrc(this.imageData.src)
    }

    setApiSrc(imagePathToFile: string) {
        this.imageData.src = imagePathToFile;
        this.imageSrc.next(this.apiSrcPipe.getImageSrc(imagePathToFile, true));
    }

    onEditImage(goToFiles = false) {
        const dialogRef = this.dialog.open(ImageBlockSettingsComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                imageData: {...this.imageData},
                goToFiles: goToFiles
            }
        })
        dialogRef.afterClosed().subscribe((newImageData) => {
            if (newImageData) {
                this.loading = true
                this.setApiSrc(newImageData.src)
                this.setImageDimensions(newImageData, true)
            }
        })
    }

    onZoom(zoom: number | string) {
        this.width = parseInt('' + zoom, 10)
        const image = this.imageDataSub.value
        image.width = parseInt('' + zoom, 10)
        image.height = image.width / this.imageRatio
        this.height = image.height
        this.adjustLeft(image)
        this.imageDataSub.next(image)
    }

    adjustLeft(image: ImageData) {
        if (this.width + this.left < 100 && this.width >= 100) {
            this.left = -(this.width - 100)
        } else if (this.width < 100) {
            // center if image is smaller then block
            this.left = 50 - this.width / 2
        }
        image.left = this.left
    }

    onSetLeft(left: number) {
        this.left = left
        const image = this.imageDataSub.value
        image.left = left
        this.imageDataSub.next(image)
    }

    setImageDimensionsToDefault() {
        this.setImageDimensions(this.imageDataSub?.value || this.imageData, true)
    }

    async setImageDimensions(newImageData: any, force = false) {
        if (force || (this.imageDataSub.value)) {
            if (newImageData) {
                if (this.imageDataSub.value.src !== newImageData.src || force) {
                    await this.setRealImageDimensions().then(maxImageDimensions => {
                        this.width = this.maxWidthInPercent > 100 ? 100 : this.maxWidthInPercent
                        this.height = this.width / this.imageRatio
                        this.left = 0
                        this.top = 0
                        newImageData.height = this.height
                        newImageData.width = this.width
                        newImageData.top = 0
                        newImageData.left = 0
                        newImageData.imageRatio = this.imageRatio
                        newImageData.max = maxImageDimensions
                        this.adjustLeft(newImageData)
                    })
                }
                this.imageDataSub.next(newImageData)
            } else {
                this.loading = false
            }
        } else {
            this.loading = false
        }
    }

    setRealImageDimensions() {
        return new Promise<{ width: number, height: number } | false>((resolve, reject) => {
            const img = new Image()
            img.onload = (even) => {
                const max = {
                    width: img.width,
                    height: img.height
                }
                this.imageRatio = img.width / img.height
                this.maxHeightInPercent = 100
                this.maxWidthInPercent = 100 * img.width / (this.blockDimensions?.width || 1000)
                resolve(max)
            }
            img.onerror = (error) => {
                resolve(false)
            }
            img.src = this.imageSrc.value
        })
    }

}
