import {Component, OnInit, AfterViewInit, Renderer2, Inject} from '@angular/core'
import {ThemeService} from './shared/services/theme.service'
import {UpdaterService} from './shared/services/updater/updater.service'
import {HeadTagsService} from './shared/services/head-tags.service'
import {DOCUMENT} from '@angular/common'
import {CookieConsentService} from './views/cookie/cookie.service'
import {RouterOutlet} from '@angular/router';
import {UrlService} from "./shared/services/url.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet]})
export class AppComponent implements OnInit, AfterViewInit {

    title = 'test';

    constructor(
        private themeService: ThemeService,
        private renderer: Renderer2,
        private updater: UpdaterService,
        private tagsService: HeadTagsService, // to initialize
        private urlService: UrlService, // to initialize
        private cookieService: CookieConsentService, // to initialize
        @Inject(DOCUMENT) private document: Document
    ) {
        tagsService.changePageTitle()
    }

    ngOnInit() {
        this.updater.initVersionChecker()
        this.document.documentElement.lang = 'cs'
    }

    ngAfterViewInit() {
        this.themeService.changeTheme(this.renderer)
    }
}

