import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog'
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit
} from '@angular/core'
import {Animations} from '../../../animations/animations';
import {WindowService} from '../../../services/window.service';
import {GalleryImage} from '../../../../views/secured/gallery/gallery';
import { ImageApiSrcPipe } from '../../../pipes/image-api-src.pipe';
import { ImageFadeInDirective } from '../../../directives/image.fadeIn.directive';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    animations: [Animations.slide],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatIconModule, NgFor, NgIf, ImageFadeInDirective, ImageApiSrcPipe]
})
export class ImageViewerComponent implements OnInit {
    currentImage?: GalleryImage
    currentIndex = 0
    images: GalleryImage[] = []
    dimensions?: { width: number; height: number }

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ImageViewerComponent>,
        private windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: { index: number, images: GalleryImage[] },
    ) {
        this.currentIndex = data.index
        this.images = data.images
        this.dialogRef.addPanelClass('image-viewer')
        this.checkImageSrcData()
    }

    ngOnInit() {
        this.currentImage = this.images[this.currentIndex]
    }

    checkImageSrcData() {
        this.dimensions = {
            width: Math.floor(this.windowService.getWidth()),
            height: Math.floor(this.windowService.getHeight())
        }
    }

    setNextImage() {
        this.currentIndex++
        this.currentIndex = this.currentIndex > (this.images.length - 1) ? 0 : this.currentIndex
        this.currentImage = this.images[this.currentIndex];
    }

    setPrevImage() {
        this.currentIndex--
        this.currentIndex = this.currentIndex < 0 ? (this.images.length - 1) : this.currentIndex
        this.currentImage = this.images[this.currentIndex];
    }

}
