import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable, repeat, shareReplay} from 'rxjs';
import {WcbAclAction} from './system';
import {BaseApiService} from "../../../shared/services/base-api.service";
import {finalize, retryWhen} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SystemAclService extends BaseApiService<WcbAclAction> {

    override endPoint = environment.restApiHost + 'v1/settings/acl/';
    private listForUser$?: Observable<Array<string>>
    private listRoles$?: Observable<Array<string>>


    invalidateCache() {
        this.listForUser$ = undefined
    }

    /**
     * API request for list of acl roles
     * @returns {Observable<DataSet>}
     */
    listRoles(workerRolesOnly = false): Observable<Array<string>> {
        if (!this.listRoles$) {
            this.listRoles$ =
                this.http.get<Array<string>>(this.endPoint + 'roles', {params: {search: JSON.stringify({'worker-only': workerRolesOnly})}}).pipe(
                    finalize(() => this.listRoles$ = undefined),
                    shareReplay({refCount: true, bufferSize: 1, windowTime: 5000})
                );
        }
        return this.listRoles$;
    }

    /**
     * API request for list of acl
     * @returns {Observable<Array<string>>}
     */
    listForUser(): Observable<Array<string>> {
        if (!this.listForUser$) {
            this.listForUser$ = this.http.get<Array<string>>(this.endPoint + 'user')
                .pipe(
                    finalize(() => this.listForUser$ = undefined),
                    shareReplay(1),
                )
        }
        return this.listForUser$;
    }

}
