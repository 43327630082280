<div class="default-bg" style="padding-top: 8rem">
    <div class="app-error">
        <div class="fix">
            <mat-icon class="error-icon" color="warn">error</mat-icon>
            <div class="error-text">
                <h1 class="error-title">404</h1>
                <div class="error-subtitle">{{ 'page-not-found' | translate }}</div>
            </div>
        </div>

        <div class="error-actions">
            <a
                mat-raised-button
                color="primary"
                class="mb-1 mr-05"
                href="/"
            >
                {{ 'back-to-homepage' | translate }}
            </a>
        </div>
    </div>
</div>
