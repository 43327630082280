<div class="flex-col flex-100 flex-grow justify-center front-theme">
    <mat-sidenav-container (swipeleft)="sidenav?.close()">

        <!-- mobile menu -->
        <mat-sidenav *ngIf="sidenavMode === 'over'" #sidenav [mode]="sidenavMode"
                     [autoFocus]="false"
                     [fixedInViewport]="sidenavMode === 'over'" [(opened)]="sidenavActive"
                     [fixedTopGap]="0">

            <app-main-menu [direction]="'column'"></app-main-menu>
        </mat-sidenav>
        <mat-sidenav-content class="flex-col" (swiperight)="sidenav?.open()">
            <header>
                <div class="header-top-wrapper">
                    <!-- desktop header -->
                    <div class="flex-row justify-start hide-lt-md">
                        <div class="flex-150px">
                            <a [routerLink]="['../']" style="max-width: 100%">
                                <img class="m-1" style="max-width: calc(100% - 2rem)" src="/assets/images/logo.svg"
                                     alt="{{'app.logo-title'|translate}}"/>
                            </a>
                        </div>

                        <div class="flex-row justify-start items-end flex-grow gap-1">
                            <div class="flex-row flex-wrap flex-grow">
                                <div class="flex-row flex-grow flex-auto justify-start items-center mb-1 mr-1">
                                    <mat-icon class="mr-05">email</mat-icon>
                                    <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                </div>
                                <div class="flex-row flex-auto flex-grow justify-start items-center mb-1">
                                    <mat-icon class="mr-05">support_agent</mat-icon>
                                    {{'basic-info.phone'|translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- mobile header -->
                    <ng-container *ngIf="sidenavMode === 'over'">
                        <div class="hide-gt-sm flex-col pb-1 pt-1">
                            <div class="flex-row justify-end items-center flex-100 hide-gt-xs"
                                 style="font-size: 0.8rem;">
                                <div
                                    class="flex-row flex-xs-50 justify-center items-center flex-gt-xs-grow flex-gt-xs-auto mr-1">
                                    <mat-icon class="mr-05" style="overflow: hidden">email</mat-icon>
                                    <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                </div>
                                <div
                                    class="flex-row justify-center items-center flex-xs-50 flex-gt-xs-auto flex-gt-xs-grow">
                                    <mat-icon class="mr-05" style="overflow: hidden">support_agent</mat-icon>
                                    {{'basic-info.phone'|translate}}
                                </div>
                            </div>
                            <div class="justify-start items-end flex-gt-xs-grow">
                                <div class="flex-grow flex-shrink flex-100px">
                                    <a [routerLink]="['../']" style="max-width: 100%">
                                        <img class="mr-05 mt-05 ml-1" style="max-width: calc(100% - 2rem)"
                                             src="/assets/images/logo.svg"
                                             alt="{{'basic-info.company'|translate}}"/>
                                    </a>
                                </div>
                                <div
                                    class="flex-row flex-wrap justify-end items-center flex-grow mb-0 pb-0 pr-05 xs-mt-05">
                                    <div
                                        class="hide-xs justify-start items-center flex-gt-xs-row flex-gt-xs-wrap flex-grow flex-shrink flex-gt-xs-70"
                                        style="font-size: 0.8rem">
                                        <div
                                            class="flex-row justify-start items-center flex-xs-50 flex-gt-xs-auto flex-gt-xs-grow mr-1">
                                            <mat-icon class="mr-05">email</mat-icon>
                                            <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                        </div>
                                        <div
                                            class="flex-row flex-xs-50 justify-start items-center flex-gt-xs-auto flex-gt-xs-grow">
                                            <mat-icon class="mr-05">support_agent</mat-icon>
                                            {{'basic-info.phone'|translate}}
                                        </div>
                                    </div>
                                    <button mat-raised-button color="primary"
                                            class="flex-40px justify-center items-center head-btn"
                                            (click)="sidenav?.toggle()">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="header-top-menu-wrapper">
                    <div>
                        <!-- desktop menu -->
                        <app-main-menu class="hide-lt-md"></app-main-menu>
                        <!-- mobile menu toggle -->
                    </div>
                </div>
            </header>

            <div class="main-content flex-grow flex-row mat-elevation-z8">
                <article class="flex-80">
                    <router-outlet></router-outlet>
                </article>
                <aside class="flex-20 sidebar-right">
                    <app-grid [maxColumns]="1" [location]="'right'"
                              [type]="'aside'"></app-grid>
                </aside>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <footer>
        <div>
            <app-grid [maxColumns]="4" [location]="'footer'"
                      [type]="'aside'"></app-grid>
            <p class="copy mt-1">&copy; 2023 {{'basic-info.company'|translate}} |
                <span class="ml-05 mr-05">Created by <a href="https://www.web-co.cz"
                                                        target="_blank">Web-Co IT s.r.o.</a></span>
                |<a [routerLink]="['/', 'cookie']" class="ml-05 mr-05" style="white-space: nowrap"
                >{{'cookie.settings'|translate}}</a>
            </p>
        </div>
    </footer>
</div>
