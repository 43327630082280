<div [ngClass]="{'dragging-on': draggingIsActive}">
    <div class="page-grid"
         [style.--item-min-width]="gridTemplateColumnsStyles[maxColumns]">
        <div *ngFor="let block of blocks" [style]="{'grid-column': block.cols.start + '/'+ block.cols.end,
                'grid-row': block.rows.start + '/'+ block.rows.end,
                background: block.background}">

            <div class="grid-block draggable" [ngSwitch]="block.type">
                <div class="block-borders horizontal"
                     (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
                     (drop)="onDrop($event, block, 'top')"></div>
                <div class="block-borders vertical"
                     *ngIf="maxColumns > 1 && block.cols.start === 1"
                     (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
                     (drop)="onDrop($event, block,'left')"></div>
                <div class="block-borders vertical"
                     *ngIf="maxColumns > 1"
                     (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
                     (drop)="onDrop($event, block, 'right')" [style.right]="0"></div>
                <div class="block-borders horizontal"
                     *ngIf="block.rows.end === maxRow + 1"
                     (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
                     (drop)="onDrop($event, block, 'bottom')"></div>

                <div class="block-actions">
                    <button mat-button mat-raised-button (click)="onRemoveBlock(block)" type="button">
                        <mat-icon class="grid-block-remove">delete</mat-icon>
                    </button>
                    <button mat-button mat-raised-button
                            *ngSwitchCase="'image'" (click)="onEditImage(block.tmpID)" type="button">
                        <mat-icon class="grid-block-remove">edit</mat-icon>
                    </button>
                    <button mat-button mat-raised-button
                            *ngSwitchCase="'text-image'" (click)="onEditImage(block.tmpID)" type="button">
                        <mat-icon class="grid-block-remove">edit</mat-icon>
                    </button>
                    <button mat-button mat-raised-button
                            *ngSwitchCase="'menu-item-list'" (click)="onSetMenuItemList(block.tmpID)"
                            type="button">
                        <mat-icon class="grid-block-remove">edit</mat-icon>
                    </button>
                    <button mat-button mat-raised-button
                            *ngSwitchCase="'company-address'" (click)="onSetCompanyAddress(block.tmpID)"
                            type="button">
                        <mat-icon class="grid-block-remove">edit</mat-icon>
                    </button>
                    <button mat-button mat-raised-button draggable="true" class="dragger" type="button"
                            (dragstart)="onDraggingStart($event, block)"
                            (dragend)="onDragEnd($event)">
                        <mat-icon>drag_indicator</mat-icon>
                    </button>
                </div>
                <div app-contact-block [id]="block.tmpID"
                     *ngSwitchCase="'contact'"></div>
                <div app-menu-item-list-block-editor [id]="block.tmpID" *ngSwitchCase="'menu-item-list'"
                     (blockData)="setBlockData(block.tmpID, $event)"
                     [mainMenuItemData]="getLinkData(block.data)"></div>
                <div #blockWithImage app-image-block-editor [id]="block.tmpID"
                     (blockData)="setBlockData(block.tmpID, $event)"
                     [imageData]="getImageData(block.data)"
                     *ngSwitchCase="'image'">
                </div>
                <div #blockWithImage app-text-image-block-editor [id]="block.tmpID"
                     (blockDataChanges)="setBlockData(block.tmpID, $event)"
                     [blockData]="getTextImageData(block.data)"
                     *ngSwitchCase="'text-image'">
                    <div content [innerHTML]="getText(block)|safe:'html'"></div>
                </div>
                <div app-company-address-block-editor [id]="block.tmpID"
                     (blockData)="setBlockData(block.tmpID, $event)"
                     [companyAddressData]="getCompanyAddressData(block.data)"
                     *ngSwitchCase="'company-address'">
                    <div content [innerHTML]="getText(block)|safe:'html'"></div>
                </div>
                <div app-gallery-block [id]="block.tmpID"
                     *ngSwitchCase="'gallery'">
                </div>
                <div app-slider-block [id]="block.tmpID"
                     *ngSwitchCase="'slider'">
                </div>
                <div app-downloads-block [id]="block.tmpID"
                     *ngSwitchCase="'downloads'">
                </div>
                <div app-text-block-editor [id]="block.tmpID"
                     (blockData)="setBlockData(block.tmpID, $event)"
                     *ngSwitchDefault>
                    <div content [innerHTML]="getText(block)|safe:'html'"></div>
                </div>
            </div>
        </div>
    </div>
    <button mat-button class="addBlock" (click)="onAddBlock('end')" type="button">
        <mat-icon>add_circle</mat-icon>&nbsp;{{'web.edit-mode.add-block'|translate}}</button>
</div>
