import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';
import {AnimationBuilder} from '@angular/animations';
import {Animations} from '../animations/animations';

@Directive({
    selector: '[appImageFadeIn]',
    standalone: true
})
export class ImageFadeInDirective {

    @HostBinding('style.opacity') imageOpacity = '0'

    @HostListener('load', ['$event']) onLoad() {
        const factory = this.builder.build(Animations.fadeIn)
        const player = factory.create(this.element.nativeElement)
        player.play();
    }

    constructor(public builder: AnimationBuilder, private element: ElementRef) {
    }

}
