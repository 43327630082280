import {ChangeDetectionStrategy, Component} from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loader',
    templateUrl: './app-loader.component.html',
    styleUrls: ['./app-loader.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatProgressSpinnerModule, TranslateModule]
})
export class AppLoaderComponent {
    title: string = 'loading';

    constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) {
    }

}
