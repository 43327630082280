import {Component, Inject, ChangeDetectionStrategy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MenuItem} from '../../../../menu';
import {Observable} from 'rxjs';
import {PageService} from '../../../../../../services/page.service';
import {map} from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-menu-item-list-block-settings-modal',
    templateUrl: './menu-item-list-block-settings-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, AsyncPipe, TranslateModule]
})
export class MenuItemListBlockSettingsModalComponent {

    public settingForm!: FormGroup;
    public menuItems$: Observable<MenuItem[]>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { link: string },
        public dialogRef: MatDialogRef<MenuItemListBlockSettingsModalComponent>,
        private pageService: PageService,
        private fb: UntypedFormBuilder
    ) {
        this.menuItems$ = this.pageService.listActivePages().pipe(
            map(response => response.data.filter(i => i.children && i.children.length > 0))
        )
        this.buildItemForm(this.data);
    }

    buildItemForm(menuItem: { link: string }) {
        this.settingForm = this.fb.group({
            link: [menuItem && menuItem.link || '', Validators.required]
        });
    }

    submit() {
        this.dialogRef.close(this.settingForm?.value);
    }
}
