import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {
    defaultCompanyAddressBlock,
    defaultContactBlock, defaultDownloadsBlock, defaultGalleryBlock,
    defaultImageBlock, defaultMenuItemListBlock,
    defaultTextBlock,
    defaultTextImageBlock
} from '../default.blocks'
import {WcbConfirmService} from '../../../services/wcb-confirm/wcb-confirm.service'
import {TranslateModule} from '@ngx-translate/core'
import {Block, GridBlockData} from '../block'
import {takeUntil} from 'rxjs/operators'
import {AppBlockChooserService} from '../../../services/app-block-chooser/app-block-chooser.service'
import {PageService} from '../../../services/page.service'
import {GridService} from './grid.service'
import {HeadTagsService} from '../../../services/head-tags.service';
import {
    TextImageBlockEditorComponent
} from './block-component/text-image-block-component/text-image-block-editor.component';
import {
    MenuItemListBlockEditorComponent
} from './block-component/menu-item-list-block-component/menu-item-list-block-editor.component';
import {ImageBlockEditorComponent} from './block-component/image-block-component/image-block-editor.component';
import {
    CompanyAddressBlockEditorComponent
} from './block-component/company-address-block-component/company-address-block-editor.component';
import {SanitizeUrlPipe} from '../../../pipes/sanitize-url.pipe';
import {TextBlockEditorComponent} from './block-component/text-block-component/text-block-editor.component';
import {DownloadsBlockComponent} from './block-component/downloads-block-component/downloads-block.component';
import {SliderBlockComponent} from './block-component/slider-block-component/slider-block.component';
import {GalleryBlockComponent} from './block-component/gallery-block-component/gallery-block.component';
import {ContactBlockComponent} from './block-component/contact-block-component/contact-block.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgClass, NgFor, NgSwitch, NgIf, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import {GridComponent} from "./grid.component";

@Component({
    selector: 'app-grid-editor',
    templateUrl: './grid-editor.component.html',
    styleUrls: ['./grid-editor.component.scss'],
    standalone: true,
    imports: [NgClass, NgFor, NgSwitch, NgIf, MatButtonModule, MatIconModule, NgSwitchCase, ContactBlockComponent, MenuItemListBlockEditorComponent, ImageBlockEditorComponent, TextImageBlockEditorComponent, CompanyAddressBlockEditorComponent, GalleryBlockComponent, SliderBlockComponent, DownloadsBlockComponent, NgSwitchDefault, TextBlockEditorComponent, SanitizeUrlPipe, TranslateModule]
})

export class GridEditorComponent extends GridComponent implements OnDestroy, OnInit {

    @Input() isEditingMode = true
    @ViewChildren('blockWithImage') imageBlocks?: QueryList<ImageBlockEditorComponent | TextImageBlockEditorComponent>
    @ViewChildren(CompanyAddressBlockEditorComponent) companyAddressBlocks?: QueryList<CompanyAddressBlockEditorComponent>
    @ViewChildren(MenuItemListBlockEditorComponent) menuItemListBlocks?: QueryList<MenuItemListBlockEditorComponent>
    movingBlock: Block | null = null
    draggingIsActive = false

    @HostListener('dragover', ['$event']) onDragOver(evt: Event) {
        evt.preventDefault()
    }

    constructor(
        public override  router: Router,
        public override activatedRoute: ActivatedRoute,
        public override pageService: PageService,
        public override gridService: GridService,
        public override headTagsService: HeadTagsService,
        public override cdRef: ChangeDetectorRef,
        private confirmService: WcbConfirmService,
        private blockChooserService: AppBlockChooserService
    ) {
        super(router, activatedRoute, pageService, gridService, headTagsService, cdRef)
    }

    override ngOnInit() {
        super.ngOnInit()

        this.pageService.dataChanged.pipe(
            takeUntil(this.destroyed)
        ).subscribe(() => {
            this.initPage()
        })
    }

    override initPage() {
        if (this.type === 'article') {
            const pageAlias = this.activatedRoute.snapshot.paramMap.get('alias') || 'home';
            this.pageService.getByAlias(pageAlias).subscribe((pageData) => {
                this.headTagsService.setTitle(pageData.title)
                this.gridID = pageData.id
                this.blocks = pageData.blocks || []
                this.gridService.updateBlocks(pageData.id, 'article', this.location, this.blocks, true)
                this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
            })
        } else if (this.type === 'aside') {
            this.pageService.getBlocksByLocation(this.location).subscribe((gridData: GridBlockData) => {
                this.blocks = gridData.blocks || []
                this.gridService.updateBlocks(0, 'aside', this.location, this.blocks, true)
                this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
            })
        } else if (this.type === 'product') {
            // blocks and gridID (productID) is set through @Input
            this.gridService.updateBlocks(this.gridID || 0, 'product', 'main', this.blocks, true)
            this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
        }
    }

    setBlockData(tmpID: number, data: any) {
        if (this.blocks && this.type) {
            const block = this.blocks.find((b) => b.tmpID === tmpID) || {data: null}
            block.data = data
            this.gridService.updateBlocks(this.gridID || 0, this.type, this.location, this.blocks)
        }
    }

    onRemoveBlock(block: Block) {
        this.confirmService
            .confirm({
                message: 'web.edit-mode.confirm.block-delete'
            })
            .subscribe(res => {
                if (res) {
                    const removingBlockSize = block.cols.end - block.cols.start
                    this.grid[block.rows.start - 1].splice(block.cols.start - 1, removingBlockSize, ...Array(removingBlockSize).fill(0))
                    this.growHorizontal()
                    this.renderMatrix()
                }
            });
    }

    onAddBlock(position: 'start' | 'end') {
        this.blockChooserService.choose({excludeBlockTypes: this.excludeBlocks})
            .subscribe(type => {
                if (type) {
                    let newBlock: Block
                    let defaultBlock = null

                    switch (type) {
                        case 'contact':
                            defaultBlock = {...defaultContactBlock}
                            break
                        case 'image':
                            defaultBlock = {...defaultImageBlock}
                            break
                        case 'text-image':
                            defaultBlock = {...defaultTextImageBlock}
                            break
                        case 'menu-item-list':
                            defaultBlock = {...defaultMenuItemListBlock}
                            break
                        case 'company-address':
                            defaultBlock = {...defaultCompanyAddressBlock}
                            break
                        case 'gallery':
                            defaultBlock = {...defaultGalleryBlock}
                            break
                        case 'downloads':
                            defaultBlock = {...defaultDownloadsBlock}
                            break
                        default:
                            defaultBlock = {...defaultTextBlock, type: type}
                    }

                    let maxTmpID = 0
                    this.blocks.forEach((b) => {
                        if (b.tmpID > maxTmpID) {
                            maxTmpID = b.tmpID
                        }
                    })

                    defaultBlock.rows = {start: this.maxRow + 1, end: this.maxRow + 2}
                    defaultBlock.cols = {start: 1, end: this.maxColumns + 1}

                    if (position === 'start') {
                        newBlock = {...defaultBlock, tmpID: maxTmpID + 1, position: 0}
                    } else {
                        newBlock = {...defaultBlock, tmpID: maxTmpID + 1, position: this.blocks.length + 1}
                    }
                    this.blocks.push(newBlock)
                    if (this.gridID && this.type) {
                        this.gridService.updateBlocks(this.gridID, this.type, this.location, this.blocks)
                    }
                    this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
                }
            })
    }

    onEditImage(tmpID: number) {
        if (this.imageBlocks) {
            const editingBlock = this.imageBlocks.find((i) => i.id === tmpID)
            if (editingBlock) {
                editingBlock.onEditImage()
            }
        }
    }

    onSetMenuItemList(tmpID: number) {
        if (this.menuItemListBlocks) {
            const editingBlock = this.menuItemListBlocks.find((i) => i.id === tmpID)
            if (editingBlock) {
                editingBlock.onChooseMenuItem()
            }
        }
    }

    onSetCompanyAddress(tmpID: number) {
        if (this.companyAddressBlocks) {
            const editingBlock = this.companyAddressBlocks.find((i) => i.id === tmpID)
            if (editingBlock) {
                editingBlock.onChangeMap()
            }
        }
    }


    /** ####################################################################################################################################
     *  ## Blocks positions <=> grid matrix representation
     *  ################################################################################################################################# */



    renderMatrix() {
        let position = 1
        const blocks: Array<Block> = []
        for (let r = 0; r < this.grid.length; r++) {
            let blockID = this.grid[r][0]
            let start = 0
            let end = 0
            for (let c = 0; c <= this.grid[r].length; c++) {
                end = c
                if (this.grid[r][c] !== blockID) {
                    const block = this.blocks.find((b) => b.tmpID === blockID)
                    if (block && block.tmpID) {
                        block.rows = {start: r + 1, end: r + 2}
                        block.cols = {start: start + 1, end: end + 1}
                        block.position = position
                        blocks.push(block)
                        position++
                    }
                    start = end
                }
                blockID = this.grid[r][c]
            }
        }
        this.maxRow = this.grid.length
        this.blocks = blocks
        if (this.type) {
            this.gridService.updateBlocks(this.gridID || 0, this.type, this.location, this.blocks)
        }

    }


    /** ####################################################################################################################################
     *  ## Dragging blocks, layout recalculating
     *  ################################################################################################################################# */

    onDraggingStart(event: DragEvent, movingBlock: Block) {
        this.draggingIsActive = true
        this.movingBlock = movingBlock
    }

    onDragEnter(event: any) {
        const el = new ElementRef(event.toElement)
        el.nativeElement.classList.add('active')
    }

    onDragLeave(event: any) {
        const el = new ElementRef(event.toElement)
        el.nativeElement.classList.remove('active')
    }

    onDragEnd(event: any) {
        this.draggingIsActive = false
    }

    onDrop(event: any, targetBlock: Block | null, position: 'top' | 'left' | 'right' | 'bottom' | 'start' | 'end') {
        if (event) {
            this.onDragLeave(event)
        }
        this.draggingIsActive = false
        const oldBlock = Object.assign({}, this.movingBlock)
        const newBlock = Object.assign({}, this.movingBlock)
        const blockInGrid: Array<number> = []
        for (let i = (newBlock.cols.start - 1); i < (newBlock.cols.end - 1); i++) {
            blockInGrid.push(newBlock.tmpID)
        }

        if (targetBlock && this.movingBlock !== targetBlock) {
            const targetBlockInGrid: Array<number> = []
            for (let i = (targetBlock.cols.start - 1); i < (targetBlock.cols.end - 1); i++) {
                targetBlockInGrid.push(targetBlock.tmpID)
            }
            if (position === 'top') {
                // adds to grid
                const newStart = targetBlock.cols.start - 1
                const newRow = targetBlock.rows.start - 1
                const addedRow = this.grid[newRow].map((b) => {
                    return b !== targetBlock.tmpID ? 0 : b
                });
                this.grid[newRow].splice(newStart, targetBlockInGrid.length, ...Array(targetBlockInGrid.length).fill(newBlock.tmpID))

                // remove from grid
                this.grid[oldBlock.rows.start - 1].splice(oldBlock.cols.start - 1, blockInGrid.length, ...blockInGrid.map(() => 0))

                // add old row (move target)
                this.grid.splice(newRow + 1, 0, addedRow)


            } else if (position === 'bottom') {
                // adds to grid
                const newRow = targetBlock.rows.end - 1
                this.grid[newRow] = this.grid[newRow] || new Array(4).fill(0)
                const addedRow = this.grid[newRow].map((b, index) => {
                    return index + 1 >= targetBlock.cols.start && index + 1 < targetBlock.cols.end ? newBlock.tmpID : 0
                });

                // remove from grid
                this.grid[oldBlock.rows.start - 1].splice(oldBlock.cols.start - 1, blockInGrid.length, ...blockInGrid.map(() => 0))

                // add to new row
                this.grid.splice(newRow, 0, addedRow)


            } else if (position === 'left') {

                // adds to grid
                const newStart = targetBlock.cols.start - 1
                const newRow = targetBlock.rows.start - 1
                this.grid[newRow].splice(newStart, 0, ...blockInGrid)

                // remove from grid
                let oldStart = oldBlock.cols.start - 1
                const oldRow = oldBlock.rows.start - 1
                if (oldStart >= newStart && oldRow === newRow) {
                    oldStart += blockInGrid.length
                }
                this.grid[oldRow].splice(oldStart, blockInGrid.length, ...blockInGrid.map(() => 0))
                this.resizeBlocksToCols(newRow, targetBlock, newStart + 1)


            } else if (position === 'right') {
                // adds to grid
                const newStart = targetBlock.cols.end - 1
                const newRow = targetBlock.rows.start - 1
                this.grid[newRow].splice(newStart, 0, ...blockInGrid)

                // remove from grid
                let oldStart = oldBlock.cols.start - 1
                const oldRow = oldBlock.rows.start - 1
                if (oldStart >= newStart && oldRow === newRow) {
                    oldStart += blockInGrid.length
                }
                this.grid[oldBlock.rows.start - 1].splice(oldStart, blockInGrid.length, ...blockInGrid.map(() => 0))
                this.resizeBlocksToCols(newRow, targetBlock, newStart + 1)
            }
            this.growHorizontal()
        } else if (position === 'start' || position === 'end') {
            // remove from grid
            if (oldBlock.rows.start - 1 >= 0) {
                this.grid[oldBlock.rows.start - 1].splice(oldBlock.cols.start - 1, blockInGrid.length, ...blockInGrid.map(() => 0))
            }

            // add old row (move target)
            this.grid.splice(position === 'end' ? this.grid.length : 0, 0, Array(this.maxColumns).fill(newBlock.tmpID))
            this.growHorizontal()
        }

        this.movingBlock = null
        this.renderMatrix()
    }

    resizeBlocksToCols(row: number, newBlock: any, newStart: number) {
        // needs to shrink
        if (this.grid[row].length > this.maxColumns && this.movingBlock) {
            let targetSize = (newBlock.cols.end - newBlock.cols.start) / 2
            const removingSize = this.movingBlock.cols.end - this.movingBlock.cols.start + newBlock.cols.end - newBlock.cols.start
            targetSize = (targetSize < 1 ? 1 : targetSize)
            if (newBlock.cols.end <= newStart) {
                // new block on right
                this.grid[row].splice(newBlock.cols.start - 1,
                    removingSize, ...Array(targetSize).fill(newBlock.tmpID), ...Array(targetSize).fill(this.movingBlock.tmpID))
            } else {
                // new block on left
                this.grid[row].splice(newStart - 1,
                    removingSize, ...Array(targetSize).fill(this.movingBlock.tmpID), ...Array(targetSize).fill(newBlock.tmpID))
            }
        }
    }

    growHorizontal() {
        for (let i = 0; i < this.grid.length; i++) {
            const rowLength = this.grid[i] ? this.grid[i].length : 1
            let row: number[] = []
            let b
            let s

            const uniqueBlocks = this.grid[i]?.reduce((uniques: number[], block) => {
                if (!uniques.includes(block) && block !== 0) {
                    uniques.push(block)
                }
                return uniques
            }, [])


            const uniqueBlockCount = uniqueBlocks.length
            const maxSize = this.maxColumns / (uniqueBlockCount > this.maxColumns ? this.maxColumns : uniqueBlockCount)

            if (rowLength <= this.maxColumns) {
                // growing
                row = Array(this.maxColumns).fill(0).map((val, idx) => this.grid[i][idx] || 0)
                b = 0
                s = 1
                let grownRow = []
                let oneBiggerBlockUsed = Math.floor(maxSize) * 100 !== maxSize * 100
                if (!isFinite(maxSize)) { // skip rows filled by zeros
                    this.grid.splice(i, 1)
                    i--
                    continue
                }
                for (let k = 0; k < row.length; k++) {
                    if (maxSize === this.maxColumns) {
                        grownRow = Array(maxSize).fill(uniqueBlocks[0])
                        break
                    } else if (maxSize === this.maxColumns / 2) {
                        grownRow = [...Array(maxSize).fill(uniqueBlocks[0]), ...Array(maxSize).fill(uniqueBlocks[1])]
                        break
                    }

                    // fill gaps
                    if (Math.floor(maxSize) === 1 && row[k] === 0) {
                        let grownBlock
                        if (k % 2) {
                            grownBlock = row[k - 1]
                        } else {
                            grownBlock = row[k + 1]
                        }
                        grownRow.push(grownBlock)
                        continue
                    }

                    //
                    if (row[k] === b) {
                        s++
                        if (s > Math.floor(maxSize) && !oneBiggerBlockUsed) {
                            continue
                        } else if (oneBiggerBlockUsed && b === row[k + 1]) {
                            oneBiggerBlockUsed = !oneBiggerBlockUsed
                        }
                    } else {
                        b = row[k] !== 0 ? row[k] : b
                        s = 1
                    }
                    grownRow.push(b)

                }
                this.grid[i] = grownRow

            } else if (rowLength > this.maxColumns) {
                // shrinking
                // remove 0blocks first
                const extraRow: number[] = []
                this.grid[i].filter((v) => v !== 0).map((id) => {
                    row.push(id)
                })
                // actual shrinking
                if (row.length > this.maxColumns) {
                    b = row[0]
                    s = 1
                    const shrinkRow = [b]
                    for (let k = 1; k < row.length; k++) {
                        if (row[k] === b) {
                            s++
                            if (s > maxSize) {
                                continue
                            }
                        } else {
                            b = row[k]
                            s = 1
                        }
                        if (k < this.maxColumns) {
                            shrinkRow.push(row[k])
                        } else {
                            extraRow.push(row[k])
                        }
                    }
                    row = shrinkRow
                }
                this.grid[i] = row
                if (extraRow.length) {
                    this.grid.splice(i + 1, 0, Array(this.maxColumns).fill(0).map((val, idx) => extraRow[idx] || 0))
                }
            }
        }
    }

}
