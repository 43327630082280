import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientMessageService {
    public endPoint: string

    constructor(private http: HttpClient) {
        this.endPoint = environment.restApiHost + 'v1/web/message/'
    }

    /**
     * API request to send client message
     */
    sendMessage(messageData: any): Observable<any> {
        return this.http.post<boolean>(this.endPoint, messageData)
    }

}
