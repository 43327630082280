import {
    ChangeDetectionStrategy,
    Component
} from '@angular/core'
import {GalleryService} from '../../../../../../views/secured/gallery/gallery.service';
import {Observable} from 'rxjs';
import {DataSet} from '../../../../../models/DataSet';
import {Gallery} from '../../../../../../views/secured/gallery/gallery';
import {map} from 'rxjs/operators';
import {Animations} from '../../../../../animations/animations';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ImageApiSrcPipe } from '../../../../../pipes/image-api-src.pipe';
import { ImageFadeInDirective } from '../../../../../directives/image.fadeIn.directive';
import { ShowIfInViewDirective } from '../../../../../directives/showIfInView.directive';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-gallery-block]',
    templateUrl: './gallery-block.component.html',
    styleUrls: ['./gallery-block.component.scss'],
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, ShowIfInViewDirective, ImageFadeInDirective, RouterLink, AsyncPipe, ImageApiSrcPipe]
})
export class GalleryBlockComponent {

    galleryList$: Observable<Gallery[]>
    loading = true

    constructor(
        private galleryService: GalleryService,
        private router: ActivatedRoute) {

        this.galleryList$ = galleryService.listAll().pipe(
            map((galleryData: DataSet<Gallery>) => {
                    this.loading = false
                    return galleryData?.data || []
                }
            )
        )
        this.galleryService.setRefererPage(this.router.snapshot.url)
    }

}

