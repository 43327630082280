import {Observable} from 'rxjs';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';

import {WcbConfirmComponent} from './wcb-confirm.component';
import {TranslateService} from '@ngx-translate/core';

export interface IConfirmData {
    title?: string
    message?: string
    messageData?: any
    acceptButtonMessage?: string
    isTablet?: boolean
    icon?: string
}

@Injectable({providedIn: 'root'})
export class WcbConfirmService {

    constructor(private dialog: MatDialog, private translate: TranslateService) {
    }

    public confirm(data: IConfirmData = {}): Observable<boolean> {
        data.title = this.translate.instant(data.title || 'confirm.title');
        data.message = this.translate.instant(data.message || 'are-you-sure', data.messageData || {});
        let dialogRef: MatDialogRef<WcbConfirmComponent>;
        dialogRef = this.dialog.open(WcbConfirmComponent, {
            minWidth: '300px',
            maxWidth: '80vw',
            minHeight: data.isTablet ? '25vh' : undefined,
            disableClose: true,
            data: {title: data.title, message: data.message, acceptButtonMessage: data.acceptButtonMessage, isTablet: data.isTablet, icon: data.icon || 'dangerous'}
        });
        return dialogRef.afterClosed();
    }
}
