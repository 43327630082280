<h3 mat-dialog-title>{{ 'web.company-address.block-settings'|translate }}</h3>
<div mat-dialog-content>
    <form *ngIf="settingForm" [formGroup]="settingForm" (ngSubmit)="submit()">
        <div class="flex-col">
            <mat-form-field>
                <input
                    matInput
                    formControlName="address"
                    placeholder="{{ 'web.company-address.address' | translate }}"
                >
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    formControlName="height"
                    placeholder="{{ 'web.company-address.height' | translate }}"
                >
                <span matSuffix>px</span>
            </mat-form-field>
            <div class="flex-100 mt-1">
                <button
                    mat-button
                    color="warn"
                    type="button"
                    (click)="dialogRef.close(false)"
                >
                    {{ 'cancel' | translate }}
                </button>
                <span class="flex-1"></span>
                <button mat-raised-button color="primary" [disabled]="settingForm.invalid">
                    {{ 'set' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
