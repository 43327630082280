import {ChangeDetectionStrategy, Component} from '@angular/core'
import {ConsentData, CookieConsentService} from '../cookie.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-cookie-settings',
    templateUrl: './cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatIconModule, MatCheckboxModule, FormsModule, MatButtonModule, TranslateModule]
})
export class CookieSettingsComponent {

    consent: ConsentData = {c: 0, a: 0, m: 0}

    constructor(
        private cookieService: CookieConsentService) {
        this.consent = cookieService.consent.value
    }

    handleSetSettings() {
        this.consent = this.cookieService.setSettings(this.consent)
    }

    handleAcceptAll() {
        this.consent = this.cookieService.acceptAll()
    }

}
