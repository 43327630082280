import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, HostListener, Input,
    OnInit, Output, ViewChild
} from '@angular/core'

import {EventEmitter} from '@angular/core'
import {debounceTime, skip, skipWhile} from 'rxjs/operators'
import {BehaviorSubject, merge} from 'rxjs'
import {TextImageData, ImageData} from '../../../block'
import {defaultImageData, defaultTextImageBlock} from '../../../default.blocks'
import {ImageBlockSettingsComponent} from '../image-block-component/image-block-settings-component/image-block-settings.component';
import {MatDialog} from '@angular/material/dialog';
import {ImageApiSrcPipe} from '../../../../../pipes/image-api-src.pipe';
import { SanitizeUrlPipe } from '../../../../../pipes/sanitize-url.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-text-image-block-editor]',
    templateUrl: './text-image-block-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, NgClass, NgTemplateOutlet, MatSliderModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, AsyncPipe, SanitizeUrlPipe, ImageApiSrcPipe]
})
export class TextImageBlockEditorComponent implements OnInit, AfterViewInit {

    html: BehaviorSubject<string> = new BehaviorSubject<string>('')
    imageDataSub: BehaviorSubject<ImageData> = new BehaviorSubject<ImageData>(defaultImageData)
    imageSrc: BehaviorSubject<string> = new BehaviorSubject<string>('')
    blockDimensions?: { width: number, height: number }

    width = 25
    align = 'right'
    valign = 'top'
    maxWidthInPercent = 100
    loading = false

    @ViewChild('editable') editable?: ElementRef
    @ViewChild('image') imageElement?: ElementRef
    @Input() id?: number
    @Output() blockDataChanges = new EventEmitter<TextImageData>()
    @Input() blockData: TextImageData = defaultTextImageBlock.data as TextImageData

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkDimensions()
    }

    constructor(
        private cd: ChangeDetectorRef,
        private dialog: MatDialog,
        private imageApiSrcPipe: ImageApiSrcPipe) {
    }

    ngOnInit() {
        this.html.next(this.blockData.text)
        this.imageDataSub.next(this.blockData.image)
        this.width = this.blockData.image.width || 25
        this.align = this.blockData.image.align || 'right'
        this.valign = this.blockData.image.valign || 'top'
    }

    getBlockData(): TextImageData {
        return {
            text: this.html.value,
            image: this.imageDataSub.value
        }
    }

    ngAfterViewInit() {
        this.checkDimensions()
        this.setRealImageDimensions()
            .then(maxDimensions => {
                    if (maxDimensions) {
                        const imageData = {...this.imageDataSub.value, max: maxDimensions}
                        this.imageDataSub.next(imageData)
                    }
                }
            )

        merge(
            this.html.asObservable().pipe(
                skipWhile(val => val === ''),
                debounceTime(600)
            ),
            this.imageDataSub.asObservable().pipe(
                skip(1),
                skipWhile(val => val.src === ''),
                debounceTime(600)
            )
        ).subscribe(data => {
            this.blockDataChanges.emit(this.getBlockData())
            this.loading = false
        })

        if (this.editable) {
            // @todo this.initEditor(this.editable)
        }
    }

    onChange() {
        this.html.next('' /* @todo this.editor.getData()*/)
    }

    checkDimensions() {
        this.blockDimensions = {
            width: this.imageElement?.nativeElement.offsetWidth,
            height: this.imageElement?.nativeElement.offsetHeight
        }
        this.setApiSrc(this.imageDataSub.value.src)
    }

    setApiSrc(imagePathToFile: string) {
        this.imageSrc.next(imagePathToFile);
        this.cd.detectChanges()
    }

    onZoom(zoom: number | string) {
        this.width = parseInt('' + zoom, 10)
        const image = this.imageDataSub.value
        image.width = parseInt('' + zoom, 10)
        this.imageDataSub.next(image)
    }

    toggleImageAlign() {
        const alignOptions = ['left', 'center', 'right']
        const alignIndex = alignOptions.findIndex(a => a === this.align)
        const align = alignIndex === 2 ? 'left' : alignOptions[alignIndex + 1]
        const imageData = this.imageDataSub.value
        imageData.align = this.align = align
        this.imageDataSub.next(imageData)
    }

    toggleImageValign() {
        const valign = this.valign === 'top' ? 'bottom' : 'top'
        const imageData = this.imageDataSub.value
        imageData.valign = this.valign = valign
        this.imageDataSub.next(imageData)
    }

    onEditImage(goToFiles = false) {
        const dialogRef = this.dialog.open(ImageBlockSettingsComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                imageData: {...this.imageDataSub.value},
                goToFiles: goToFiles
            }
        })
        dialogRef.afterClosed().subscribe((newImageData) => {
            if (newImageData) {
                this.loading = true
                this.setApiSrc(newImageData.src)
                this.setImageDimensions(newImageData)
            }
        })
    }

    setImageDimensionsToDefault() {
        this.setImageDimensions(this.imageDataSub?.value || defaultImageData, true)
    }

    async setImageDimensions(newImageData: ImageData, force = false) {
        if (force || (this.imageDataSub && this.imageDataSub.value)) {
            if (newImageData) {
                if ((this.imageDataSub && this.imageDataSub.value.src !== newImageData.src) || force) {
                    await this.setRealImageDimensions().then(maxImageDimensions => {
                        this.width = this.maxWidthInPercent
                        newImageData.width = this.width
                        if (maxImageDimensions) {
                            newImageData.max = maxImageDimensions
                        }
                    })
                }
                this.imageDataSub.next(newImageData)
            } else {
                this.loading = false
            }
        } else {
            this.loading = false
        }
    }

    setRealImageDimensions() {
        return new Promise<{ width: number, height: number } | false>((resolve, reject) => {
            const img = new Image()
            img.onload = (even) => {
                const max = {
                    width: img.width,
                    height: img.height
                }
                resolve(max)
            }
            img.onerror = (error) => {
                resolve(false)
            }
            img.src = this.imageApiSrcPipe.getImageSrc(this.imageSrc.value)
        })
    }

}

