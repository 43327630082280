<div class="m-333 flex-row">
    <span class="flex-1 title">
        {{(gallery ? 'web.gallery.edit-title' : 'web.gallery.add-title')|translate}}
    </span>
    <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
</div>

<form *ngIf="galleryForm" (ngSubmit)="handleSubmit()" [formGroup]="galleryForm">
    <mat-card>
        <mat-card-content>
            <div class="flex-50 flex-col">

                <mat-form-field>
                    <mat-label>{{ 'web.gallery.name' | translate }}</mat-label>
                    <input matInput
                           type="text"
                           required
                           formControlName="name"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ 'web.gallery.description' | translate }}</mat-label>
                    <textarea matInput formControlName="description" style="height: 60px;" #description>
                                {{description.value}}
                            </textarea>
                </mat-form-field>
                <mat-checkbox formControlName="active">{{'web.gallery.active'|translate}}</mat-checkbox>

            </div>
            <mat-divider class="mb-1 mt-1"></mat-divider>

            <div class="flex-row flex-wrap">
        <span *ngIf="!gallery?.id" class="flex-row items-center"><mat-icon class="icon-xs mr-05">thumb_up</mat-icon>
            {{'web.gallery.snack.save-before-upload'|translate}}</span>
                <ng-container *ngIf="gallery && gallery.id">
                    <div class="flex-col flex-48">
                        <span class="title">{{'web.gallery.images'|translate}}</span>
                        <div cdkDropList (cdkDropListDropped)="onFileDrop($event, gallery.images || [])"
                             class="flex-col flex-grow border-bottom"
                             formGroupName="images">
                            <div *ngFor="let img of gallery.images; let i = index; trackBy:trackImageById"
                                 class="flex-row justify-start items-center p-05"
                                 cdkDrag [formGroupName]="i">
                                <img class="flex-140px mr-1" [src]="img.thumbnail|safe:'resourceUrl'" alt=""/>
                                <div class="flex-row flex-wrap flex-offset-2 flex-grow flex-68">
                                    <div class="flex-100">
                                        <mat-form-field>
                                            <mat-label>{{'web.gallery.image-title'|translate}}</mat-label>
                                            <input matInput type="text" formControlName="alt"/>
                                            <mat-hint>{{img.file}}</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="flex-50 justify-start items-center">
                                        <mat-icon cdkDragHandle>drag_indicator</mat-icon>
                                        <button mat-icon-button type="button" color="primary"
                                                (click)="handleFileDownload(img, false)">
                                            <mat-icon>visibility</mat-icon>
                                        </button>
                                        <button mat-icon-button type="button" color="primary"
                                                (click)="handleFileDownload(img, true)">
                                            <mat-icon>download</mat-icon>
                                        </button>
                                        <button mat-icon-button type="button" color="warn"
                                                (click)="handleDeleteImage(img)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col flex-48 flex-offset-2">
                        <app-upload-file [endPoint]="docsEndPoint"
                                         [id]="gallery.id"
                                         [fileIDType]="'image'"
                                         [isMultiple]="true"
                                         fileTitle="{{'web.gallery.image-upload'|translate}}"
                                         (fileIsOk)="handleUploadResult($event)"></app-upload-file>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<ng-template #saveButton>
    <!-- ACTIONS -->
    <div *ngIf="galleryForm" class="flex-row justify-end items-center">
        <button mat-button mat-raised-button type="submit" color="primary" class="mr-05"
                (click)="handleSubmit()"
                appEnableAllowed="/sec/gallery/new"
                [isValid]="galleryForm.valid">
            <mat-icon>save</mat-icon>
            {{ 'save' | translate }}
        </button>
        <button mat-button type="button" color="warn" (click)="handleClose()">
            <mat-icon>close</mat-icon>
            {{ 'close' | translate }}
        </button>
    </div>
</ng-template>
