<div class="flex-row flex-wrap flex-100 justify-start pt-0 pr-1 pl-1 pb-1">

    <div *ngFor="let gallery of galleryList$|async" [@slideInOut]
         class="flex-col flex-xs-100 flex-gt-xs-50 flex-gt-sm-33-33 flex-gt-md-25 p-05 pb-1">
        <div class="flex-col flex-grow justify-start items-stretch mat-elevation-z3 p-05">
            <img class="mt-05" [src]="gallery.thumbnail|imgAPISrc" alt="{{gallery.name}}" appImageFadeIn
                 *appShowIfInView
                 loading="lazy" [routerLink]="['/', gallery.alias]"
                 style="min-height: 120px; max-height: 120px; max-width: 100%; object-fit: cover"/>
            <a class="mt-1 mat-subheading-2 text-center" title="{{gallery.name}}" [routerLink]="['/', gallery.alias]">
                {{gallery.name}}
            </a>
        </div>
    </div>
</div>

