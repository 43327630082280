import {
    AfterViewInit,
    Directive,
    ElementRef,
    Host,
    Input,
    Optional,
    Renderer2
} from '@angular/core';
import {WcbUserService} from '../services/auth/user.service';
import {MatTab} from "@angular/material/tabs";
import {MatButton} from "@angular/material/button";

@Directive({
    selector: '[appEnableAllowed]',
    standalone: true,
})
export class EnableAllowedDirective implements AfterViewInit {

    private isInitialized = false

    @Input('appEnableAllowed') aclClientAction?: string

    private _isValid = true;
    private shouldDisable = false;

    @Input('isValid') set isValid(val: boolean) {
        this._isValid = val;
        this.toggleElement()
        this.isInitialized = true
    }

    constructor(
        private userService: WcbUserService,
        private el: ElementRef,
        private renderer: Renderer2,
        @Optional() @Host() private matTab: MatTab,
        @Optional() @Host() private matButton: MatButton
    ) {
    }

    ngAfterViewInit() {
        if (!this.isInitialized) { // if there is no isValid input
            this.toggleElement();
        }
    }

    toggleElement() {
        if (this.aclClientAction) {
            this.shouldDisable = !this.userService.isAllowed(this.aclClientAction) || !this._isValid;
            this.updateDisabledState();
        }
    }

    private updateDisabledState() {
        const comp = this.matButton || this.matTab
        if (comp) {
            if (comp.disabled !== this.shouldDisable) {
                comp.disabled = this.shouldDisable;
            }
        } else {
            if ('disabled' in this.el.nativeElement) {
                this.renderer.setProperty(this.el.nativeElement, 'disabled', this.shouldDisable);
            }
        }
    }
}
