import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {ChooserDialogData} from './app-block-chooser.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';

@Component({
    selector: 'app-block-chooser',
    template: `<h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content class="flex-row flex-wrap gap-1 p-1">
        <p style="width: 100%">{{ data.message }}</p>
        <mat-grid-list style="width: 100%" cols="3" rowHeight="2:1">
            <mat-grid-tile *ngFor="let type of blockTypes">
                <button
                        mat-raised-button
                        color="primary"
                        style="min-height: 100%; width: 100%"
                        (click)="dialogRef.close(type.type)">
                    <mat-icon>{{type.icon}}</mat-icon>&nbsp;
                    {{'web.edit-mode.block.types.' + type.type|translate}}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <div mat-dialog-actions class="flex-end">
        <button
                color="default"
                mat-raised-button
                (click)="dialogRef.close(false)">{{'cancel'|translate}}</button>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatGridListModule, NgFor, MatButtonModule, MatIconModule, TranslateModule]
})
export class AppBlockChooserComponent {

    blockTypes = [
        {type: 'text', icon: 'article'},
        {type: 'image', icon: 'insert_photo'},
        {type: 'text-image', icon: 'art_track'},
        {type: 'gallery', icon: 'perm_media'},
        {type: 'downloads', icon: 'downloads'},
        {type: 'slider', icon: 'view_carousel'},
        {type: 'contact', icon: 'feedback'},
        {type: 'menu-item-list', icon: 'list'},
        {type: 'company-address', icon: 'directions'}
    ]

    constructor(
        public dialogRef: MatDialogRef<AppBlockChooserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChooserDialogData
    ) {
        if (data.excludeBlockTypes && data.excludeBlockTypes.length > 0) {
            this.blockTypes = this.blockTypes.filter((block) => {
                return !data.excludeBlockTypes?.includes(block.type)
            })
        }
    }
}
