import {ChangeDetectionStrategy, Component} from '@angular/core'
import {CanDeactivateComponent} from '../../../services/not-saved-changes.guard';
import {GridService} from '../grid-component/grid.service';
import { GridEditorComponent } from '../grid-component/grid-editor.component';

@Component({
    selector: 'app-page-editor',
    templateUrl: './page-editor.component.html',
    standalone: true,
    imports: [GridEditorComponent],
})
export class PageEditorComponent implements CanDeactivateComponent {

    constructor(private gridService: GridService) {
    }

    canDeactivate() {
        return !this.gridService.hasChangedBlocks(['article', 'aside'])
    }

}
