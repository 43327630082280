<ng-container *ngIf="companyAddressData">
    <div [innerHTML]="companyAddressData.text"></div>
    <div *ngIf="companyAddressData.address !== ''" class="gmap_outer"
         [style.height.px]="companyAddressData.height">
        <div *appShowIfInView class="gmap_canvas" [style.height.px]="companyAddressData.height">
            <iframe class="gmap_iframe" width="100%" [style.height.px]="companyAddressData.height" frameborder="0"
                    loading="lazy"
                    scrolling="no" marginheight="0" marginwidth="0"
                    [src]="'https://maps.google.com/maps?width=100%&amp;height='+companyAddressData.height+'px&amp;hl=cs&amp;q='+companyAddressData.address+'&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'|safe:'resourceUrl'">
            </iframe>
        </div>
    </div>
</ng-container>
