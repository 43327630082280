import {catchError, concatMap, finalize, map, retry, retryWhen} from 'rxjs/operators'
import {AppLoaderService} from '../app-loader/app-loader.service'
import {Injectable} from '@angular/core'
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor, HttpParams,
    HttpRequest,
    HttpResponse
} from '@angular/common/http'
import {delay, Observable, of, throwError, timer} from 'rxjs'
import {Router} from '@angular/router'
import {TranslateService} from '@ngx-translate/core'
import {InterceptorHelpers} from './helpers'

@Injectable()
export class WcbResponseInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private translate: TranslateService,
        private loader: AppLoaderService,
        private helpers: InterceptorHelpers
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if (!this.isQuietRequest(req)) {
            this.loader.open()
        }

        let sanitizedBody: any = null
        if (req.body) {
            sanitizedBody = Object.assign({}, req.body)
            sanitizedBody = this.helpers.momentToISO(sanitizedBody)
        }

        // request
        const dateTimeSanitizedReq = req.clone({
            body: sanitizedBody,
            params: (req.params ?? new HttpParams())
                .set('lang', this.translate.currentLang)
        });

        return next
            .handle(dateTimeSanitizedReq)
            .pipe(
                retry({count: 3, delay: this.shouldRetryRequest}),
                catchError((error) => {
                        if (error.status && error.status === 401 && !req.url.match(/(login)/)) {
                            this.router.navigate(['/pub/sign-in']);
                        }
                        throw error
                    }
                ),
                map(evt => {
                    // response
                    if (evt instanceof HttpResponse && typeof evt.body.data !== 'undefined') {
                        evt = evt.clone({body: evt.body.data});
                        this.helpers.convertToMoment(evt);
                    }
                    return evt;
                }),
                finalize(() => {
                    if (!this.isQuietRequest(req)) { // && !req.url.match(/(order)/)) {
                        this.loader.close();
                    }
                })
            )

    }

    isQuietRequest(req: HttpRequest<any>): boolean {
        return !!(req.reportProgress || req.url.match(/(version\.js|routing)/));
    }

    shouldRetryRequest(error: HttpErrorResponse) {
        if (error.status === 0) {
            return timer(2000)
        }
        throw error;
    }

}
