import {DataSet} from '../../../shared/models/DataSet';
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Gallery} from './gallery';
import {UrlSegment} from '@angular/router';
import {BaseApiService} from "../../../shared/services/base-api.service";

@Injectable({
    providedIn: 'root'
})
export class GalleryService extends BaseApiService<Gallery> {

    override endPoint = environment.restApiHost + 'v1/gallery/'
    public lastRefererPage: UrlSegment[] = []

    public setRefererPage(url: UrlSegment[]) {
        this.lastRefererPage = url
    }

    public listAll(activeOnly = true): Observable<DataSet<Gallery>> {
        const queryParams = new HttpParams().append('page', '0')
            .append('search', JSON.stringify(activeOnly ? {active: 1} : {}))
            .append('limit', '-1')
            .append('orderBy', 'position')
            .append('ts', new Date().getTime().toString()); // prevent caching in IE
        return this.list(queryParams);
    }

    public getGalleryByAlias(alias: string) {
        return this.http.get<Gallery>(this.endPoint + alias)
    }

    public saveGalleryPositioning(galleryPositioning: Array<{ id: number, position: number, active: number }>) {
        return this.http.post(this.endPoint + 'position', galleryPositioning).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }

    public listFilesOfGallery(galleryID: number, type: 'image' | 'doc' = 'image') {
        return this.http.get<Array<any>>(this.docsEndPoint + galleryID + '/' + type)
    }

}
