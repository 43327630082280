import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CompanyAddressData} from '../../../block';
import { SanitizeUrlPipe } from '../../../../../pipes/sanitize-url.pipe';
import { ShowIfInViewDirective } from '../../../../../directives/showIfInView.directive';
import { NgIf } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-company-address-block]',
    templateUrl: './company-address-block.component.html',
    styleUrls: ['./company-address-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ShowIfInViewDirective, SanitizeUrlPipe]
})
export class CompanyAddressBlockComponent {

    @Input() id: number | null = null
    @Input() companyAddressData?: CompanyAddressData
}
