import {finalize, map} from 'rxjs/operators'
import {DataSet} from '../models/DataSet'
import {BehaviorSubject, Observable} from 'rxjs'
import {environment} from '../../../environments/environment'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable, EventEmitter} from '@angular/core'
import {MenuItem} from '../components/web/menu'
import {Page} from '../components/web/page'
import {GridBlockData} from '../components/web/block'

@Injectable({
    providedIn: 'root'
})
export class PageService {
    public endPoint: string
    public blockEndPoint: string

    private cachedActiveMenuList: BehaviorSubject<DataSet<MenuItem>> | null = null
    dataChanged: EventEmitter<boolean> = new EventEmitter()

    constructor(private http: HttpClient) {
        this.endPoint = environment.restApiHost + 'v1/web/page/'
        this.blockEndPoint = environment.restApiHost + 'v1/web/block/'
    }

    /**
     * Perform emit of data changed
     * @emits dataChanged<boolean>
     */
    dataChangedEmit() {
        this.cachedActiveMenuList = null
        this.dataChanged.emit(true)
    }

    /**
     * API request for list of pages
     * @returns {Observable<DataSet>}
     */
    list(): Observable<DataSet<MenuItem>> {
        return this.http.get<DataSet<MenuItem>>(this.endPoint)
    }

    /**
     * API request for list of active pages in main menu
     * @returns {Observable<DataSet>}
     */
    listActivePages(): Observable<DataSet<MenuItem>> {
        if (!this.cachedActiveMenuList) {
            this.cachedActiveMenuList = new BehaviorSubject<DataSet<MenuItem>>({count: 0, data: []});
            const httpParams = new HttpParams().append('search', JSON.stringify({active: true}))
            this.http.get<DataSet<any>>(this.endPoint, {params: httpParams}).subscribe((data: DataSet<any>) => {
                if (this.cachedActiveMenuList) {
                    this.cachedActiveMenuList.next(data)
                }
            })
        }
        return this.cachedActiveMenuList
    }

    /**
     * API request for page details
     * @param {number} pageID
     * @returns {Observable<Page>}
     */
    get(pageID: number): Observable<Page> {
        return this.http.get<Page>(this.endPoint + pageID)
    }

    /**
     * API request to get blocks by its location
     * @param location
     */
    getBlocksByLocation(location: string) {
        const httpParams = new HttpParams().append('search', JSON.stringify({location: location}))
        return this.http.get<GridBlockData>(this.blockEndPoint, {params: httpParams}).pipe(
            map((result) => {
                result.blocks.map((block) => {
                    block.tmpID = block.id
                    try {
                        if (typeof block.data === 'string') {
                            block.data = JSON.parse(block.data)
                        }
                    } catch (e) {
                        // it is string of html
                    }
                })
                return result
            })
        )
    }

    /**
     * API request for page details
     * @param {string} pageAlias
     * @returns {Observable<Page>}
     */
    getByAlias(pageAlias: string): Observable<Page> {
        return this.http.get<Page>(this.endPoint + pageAlias).pipe(
            map((result) => {
                if (result.blocks) {
                    result.blocks.map((block) => {
                        block.tmpID = block.id
                        try {
                            if (typeof block.data === 'string') {
                                block.data = JSON.parse(block.data)
                            }
                        } catch (e) {
                            // it is string of html
                        }
                    })
                }
                return result
            })
        )
    }

    /**
     * API request to update or insert page details
     * @param pagesSetting
     * @returns {Observable<DataSet>}

     */
    savePagesSettings(pagesSetting: Array<MenuItem>): Observable<boolean> {
        return this.http.post<boolean>(this.endPoint + 'settings', pagesSetting).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }

    /**
     * API request to update or insert page details
     * @param pageData
     * @returns {Observable<Page>}
     */
    savePage(pageData: Page): Observable<Page> {
        return this.http.post<Page>(this.endPoint, pageData).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }

    /**
     * API request to delete page
     * @param {number} pageID
     * @returns {Observable<void>}
     */
    delete(pageID: number): Observable<void> {
        return this.http.delete<void>(this.endPoint + pageID).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }
}
