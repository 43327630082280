import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RoutePartsService} from './route-parts.service';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class HeadTagsService {

    appTitle = 'app.title';
    pageTitle = '';

    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        private translate: TranslateService) {

    }

    changePageTitle() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(routeChange => {
                const routeParts = this.routePartsService.generateRouteParts(
                    this.activeRoute.snapshot
                );
                if (!routeParts.length) {
                    return this.title.setTitle(this.translate.instant(this.appTitle))
                }
                // Extract title from parts;
                this.pageTitle = routeParts
                    .reverse()
                    .map(part => part.title)
                    .reduce((partA, partI) => {
                        return `${this.translate.instant(partA)} > ${this.translate.instant(partI)}`
                    })
                this.pageTitle += ` | ${this.translate.instant(this.appTitle)}`
                this.title.setTitle(this.pageTitle)
            });
    }

    setTitle(title: string) {
        this.pageTitle = `${title} | ${this.translate.instant(this.appTitle)}`
        this.title.setTitle(this.pageTitle)
    }
}
