<mat-nav-list class="space-between items-center main-navigation"
              [ngClass]="{'flex-row': direction === 'row', 'flex-col': direction === 'column'}">
    <ng-container *ngFor="let item of menu">
        <ng-container
            *ngIf="item.children && (item.children|filter:'isActive').length > 0  && item.isActive">
            <a mat-list-item
               *ngIf="dropdown.childMenu"
               [ngClass]="{'active': activeItemID === item.id}"
               [matMenuTriggerFor]="dropdown.childMenu">{{item.title}}</a>
            <app-menu-item #dropdown (isActivated)="activeItemByChildren($event, item.id)"
                           [items]="item.children"></app-menu-item>
        </ng-container>
        <ng-container *ngIf="!item.children || (item.children|filter:'isActive').length < 1  && item.isActive">
            <a mat-list-item *ngIf="!item.isExternal; else externalLink" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}"
               [routerLink]="item.isHomepage ? './' : item.link">{{item.title}}</a>
            <ng-template #externalLink>
                <a mat-list-item href="https://{{item.link}}"
                   target="_blank">{{item.title}}</a>
            </ng-template>
        </ng-container>
    </ng-container>
</mat-nav-list>
