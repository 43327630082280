import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, HostListener, OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core'
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router'
import {filter} from 'rxjs/operators';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import {WindowService} from '../../../services/window.service';
import {WcbUserService} from '../../../services/auth/user.service';
import {Subscription} from 'rxjs';
import {WcbUser} from '../../../../views/secured/user/user';
import {MatDialog} from '@angular/material/dialog';
import {LayoutService} from '../../../services/layout.service';
import { TranslateModule } from '@ngx-translate/core';
import { GridEditorComponent } from '../../web/grid-component/grid-editor.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MainMenuComponent } from '../../web/main-menu-component/main-menu.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-front-layout-editor',
    templateUrl: './front-layout-editor.template.html',
    standalone: true,
    imports: [
        MatSidenavModule,
        NgIf,
        MainMenuComponent,
        RouterLink,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        RouterOutlet,
        GridEditorComponent,
        TranslateModule,
    ],
})
export class FrontLayoutEditorComponent implements OnInit, OnDestroy {
    sidenavMode: MatDrawerMode = 'side'
    sidenavActive = true
    @ViewChild('sidenav') sidenav?: MatSidenav
    userSub?: Subscription
    currentUser?: WcbUser

    constructor(
        private router: Router,
        public currentUserService: WcbUserService,
        private windowService: WindowService,
        private dialog: MatDialog,
        private layoutService: LayoutService,
        private cdr: ChangeDetectorRef
    ) {

        this.userSub = this.currentUserService.getUser$().subscribe((user: WcbUser | null) => {
            this.currentUser = user ?? undefined
            this.cdr.markForCheck()
        })

        // Close sidenav after route change in mobile
        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                if (this.sidenav && this.sidenavMode === 'over') {
                    this.sidenav.close()
                }
            });
    }

    ngOnInit() {
        this.setMenuState()
    }

    ngOnDestroy() {
        this.userSub?.unsubscribe()
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setMenuState()
    }

    isMobile() {
        return this.windowService.matchMedia(`(max-width: 959px)`)
    }

    toggleMenu() {
        this.sidenavActive = !this.sidenavActive
    }

    setMenuState() {
        this.sidenavMode = this.isMobile() ? 'over' : 'side'
        this.sidenavActive = !this.isMobile()
    }
}
