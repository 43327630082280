<mat-menu #childMenu="matMenu" class="front-theme">
    <ng-container *ngFor="let item of items">
        <ng-container *ngIf="item.children && (item.children|filter:'isActive').length > 0 && item.isActive">
            <a *ngIf="menu.childMenu" mat-menu-item [matMenuTriggerFor]="menu.childMenu"
               [ngClass]="{'active': isActive}">{{item.title}}</a>
            <app-menu-item #menu (isActivated)="activeByChildren($event)" [items]="item.children"></app-menu-item>
        </ng-container>
        <ng-container *ngIf="!item.children || (item.children|filter:'isActive').length === 0 && item.isActive">
            <a *ngIf="!item.isExternal" mat-menu-item routerLinkActive="active"
               [routerLink]="item.isHomepage ? './' : item.link">{{item.title}}</a>
            <a *ngIf="item.isExternal" mat-menu-item href="https://{{item.link}}"
               target="_blank">{{item.title}}</a>
        </ng-container>
    </ng-container>
</mat-menu>

