import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, HostListener, Input,
    OnInit, ViewChild
} from '@angular/core'

import {BehaviorSubject} from 'rxjs'
import {TextImageData} from '../../../block'
import {defaultTextImageBlock} from '../../../default.blocks'
import {LayoutService} from '../../../../../services/layout.service';
import { ImageApiSrcPipe } from '../../../../../pipes/image-api-src.pipe';
import { SanitizeUrlPipe } from '../../../../../pipes/sanitize-url.pipe';
import { ImageFadeInDirective } from '../../../../../directives/image.fadeIn.directive';
import { ShowIfInViewDirective } from '../../../../../directives/showIfInView.directive';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-text-image-block]',
    templateUrl: './text-image-block.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, NgTemplateOutlet, ShowIfInViewDirective, ImageFadeInDirective, AsyncPipe, SanitizeUrlPipe, ImageApiSrcPipe]
})
export class TextImageBlockComponent implements OnInit, AfterViewInit {

    imageSrc: BehaviorSubject<string> = new BehaviorSubject<string>('')
    blockDimensions?: { width: number, height: number }

    width = 25
    align = 'right'
    valign = 'top'
    maxWidthInPercent = 100
    loading = false
    widthInPx = 150
    heightInPx = 150
    imageRatio = 0.5625 // 16:9

    @ViewChild('image') imageElement?: ElementRef
    @Input() id?: number
    @Input() blockData: TextImageData = defaultTextImageBlock.data as TextImageData

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkDimensions()
    }

    constructor(
        private layoutService: LayoutService,
        private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.blockData.image.max) {
            this.imageRatio = this.blockData.image.max.height / this.blockData.image.max.width
        }
        this.width = this.blockData.image.width || 25
        this.align = this.blockData.image.align || 'right'
        this.valign = this.blockData.image.valign || 'top'
    }

    ngAfterViewInit() {
        this.checkDimensions()
    }

    checkDimensions() {
        this.blockDimensions = {
            width: this.imageElement?.nativeElement.offsetWidth || this.blockData.image.max?.width,
            height: this.imageElement?.nativeElement.offsetHeight || this.blockData.image.max?.height
        }
        // @ts-ignore
        this.widthInPx = this.blockDimensions.width * ((this.valign === 'bottom' || this.layoutService.isSm()) ? 1 : (this.width / 100))
        this.heightInPx = this.widthInPx * this.imageRatio
        this.imageSrc.next(this.blockData.image.src)
        this.cd.detectChanges()
    }
}

