import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog'
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {DataSet} from '../../../../../../models/DataSet'
import {FlatTreeControl} from '@angular/cdk/tree'
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree'
import {SelectionModel} from '@angular/cdk/collections'
import {defaultDir, noImage} from '../../../../default.blocks'
import {WebalizePipe} from '../../../../../../pipes/webalize.pipe'
import {MatSnackBar} from '@angular/material/snack-bar'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import {WcbConfirmService} from '../../../../../../services/wcb-confirm/wcb-confirm.service'
import {Dir} from '../../../../dir'
import {ImageData} from '../../../../block'
import {DirNameEditorComponent} from './dir-name-editor.component'
import {Subscription} from 'rxjs'
import {environment} from '../../../../../../../../environments/environment'
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs'
import {DirService} from '../../../../../../services/dir.service'
import {Animations} from '../../../../../../animations/animations';
import { ImageApiSrcPipe } from '../../../../../../pipes/image-api-src.pipe';
import { SanitizeUrlPipe } from '../../../../../../pipes/sanitize-url.pipe';
import { MatGridListModule } from '@angular/material/grid-list';
import { WcbUploadFileComponent } from '../../../../../wcb-upload-file/wcb-upload-file.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-image-block-settings',
    templateUrl: './image-block-settings.component.html',
    styleUrls: ['./image-block-settings.component.scss'],
    animations: [Animations.simpleRollIn],
    standalone: true,
    imports: [
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        NgIf,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTreeModule,
        NgClass,
        MatButtonModule,
        MatDividerModule,
        WcbUploadFileComponent,
        MatGridListModule,
        NgFor,
        SanitizeUrlPipe,
        ImageApiSrcPipe,
        TranslateModule,
    ],
})
export class ImageBlockSettingsComponent implements OnInit, OnDestroy {

    @ViewChild(MatTabGroup) tabs?: MatTabGroup

    // dirs
    treeControl = new FlatTreeControl<Dir>(node => node.level || 0, node => node.expandable || false)
    treeFlattener: MatTreeFlattener<Dir, any>
    listOfDirs: MatTreeFlatDataSource<Dir, Dir>
    expandedDirs = new SelectionModel<number>(true)
    dirModel?: Dir
    subs: Array<Subscription> = []

    // editing image
    imageModel?: ImageData
    filesEndPoint: string
    imageRealSrc?: string
    imageApiEndpoint: string

    private _transformer = (node: Dir, level: number) => {
        return {
            ...node,
            expandable: !!node.children && node.children.length > 0,
            level: level
        }
    }

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ImageBlockSettingsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dirService: DirService,
        private webalizePipe: WebalizePipe,
        private snackBarService: MatSnackBar,
        private translate: TranslateService,
        private confirmService: WcbConfirmService,
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this._transformer, node => node.level, node => node.expandable, node => node.children)
        this.listOfDirs = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener)
        this.filesEndPoint = this.dirService.fileEndPoint
        this.imageApiEndpoint = environment.restApiHost + environment.apiImageEndpoint
        this.loadDirs()
        this.subs.push(
            this.dirService.dataChanged.subscribe(() => {
                this.loadDirs(true)
            })
        )
    }

    ngOnInit() {
        this.imageModel = this.data.imageData
        if (this.imageModel) {
            this.imageModel.src = this.imageModel.src === noImage ? '' : this.imageModel.src
            this.imageRealSrc = this.imageModel.src
        }
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe())
    }

    setImageSrc(src: string) {
        if (this.imageModel) {
            this.imageModel.src = src
            this.imageRealSrc = src
        }
        if (this.tabs) {
            this.tabs.selectedIndex = 0
        }
    }

    setImage() {
        this.dialogRef.close(this.imageModel)
    }

    onUploadDone() {
        if (this.dirModel) {
            this.changeDir(this.dirModel)
        }
    }

    loadDirs(openLastSaved = false) {
        this.dirService.list().subscribe((listOfDirs: DataSet<Dir>) => {
            this.listOfDirs.data = listOfDirs.data
            if (!openLastSaved && this.listOfDirs.data && this.listOfDirs.data.length > 0) {
                this.changeDir({...defaultDir, ...listOfDirs.data[0]})
                this.expandedDirs.select(listOfDirs.data[0].tmpID)
                this.expandTreeNodes()
                if (this.tabs) {
                    this.tabs.selectedIndex = this.data.goToFiles ? 1 : 0
                }
            } else if (openLastSaved) {
                const lastSavedItem = {...this.treeControl.dataNodes.find(u => this.dirModel && u.path === this.dirModel.path)} as Dir
                if (lastSavedItem) {
                    this.changeDir(lastSavedItem)
                }
            }
        })
    }

    addDir(isSubDirectory: boolean = false) {
        const dialogRef = this.dialog.open(DirNameEditorComponent, {
            width: '30%',
            disableClose: false,
            data: {
                ...defaultDir,
                name: this.translate.instant('web.edit-mode.dir.' + defaultDir.name)
            }
        });

        dialogRef.afterClosed().subscribe((newDirData) => {
            if (newDirData) {
                const tmpIDs: Array<number> = []
                this.treeControl.dataNodes.map(i => {
                    tmpIDs.push(i.tmpID)
                })
                let tmpID = 100000
                while (tmpIDs.includes(tmpID)) {
                    tmpID++
                }
                const newName = this.webalizePipe.transform(newDirData.name);
                const path = (!isSubDirectory ? ('/' + newName) : (this.dirModel ? this.dirModel.path + '/' + newName : ''))
                const newDir = {...defaultDir, tmpID: tmpID, name: newName, path: path}
                this.dirService.save(newDir).subscribe(
                    () => {
                        this.dirModel = newDir
                        this.snackBarService.open(this.translate.instant('web.edit-mode.dir.snack.saved'))
                    })
            }
        })
    }

    changeDir(dir: Dir) {
        this.expandedDirs.select(dir.tmpID)
        this.setDir()
        this.dirService.get(dir.path, '*.webp').subscribe((files) => {
            dir.files = files.map(img => {
                return {src: environment.fileServer + dir.path + '/' + img, filename: img}
            })
        })
        this.dirModel = dir
    }

    setDir() {
        if (this.dirModel) {
            const model = Object.assign({}, this.dirModel)
            const changedDirs = JSON.parse(JSON.stringify(this.listOfDirs.data))
            const siblings = this.findDirSiblings(changedDirs, model.tmpID)
            if (siblings && siblings.length > 0) {
                const itemIndexInList = siblings.findIndex(s => s.tmpID === model.tmpID)
                siblings.splice(itemIndexInList, 1, model)
            }
            this.listOfDirs.data = changedDirs
            this.expandTreeNodes()
        }
    }

    onDeleteDir(path: string) {
        this.confirmService
            .confirm({
                message: 'web.edit-mode.dir.confirm.delete'
            })
            .subscribe(res => {
                if (res) {
                    this.dirService.delete(path).subscribe(
                        () => {
                            this.changeDir({...defaultDir, ...this.listOfDirs.data[0]})
                            this.snackBarService.open(this.translate.instant('web.edit-mode.dir.snack.deleted'))
                        })
                }
            });
    }

    hasChild = (_: number, node: Dir) => !!node.expandable

    findDirSiblings(dirs: Array<Dir>, tmpID: number): Array<Dir> {
        let siblings: Dir[] = [], subSiblings = []
        dirs.forEach((mi, i) => {
            if (mi.tmpID === tmpID) {
                siblings = dirs
            } else if (mi.children) {
                subSiblings = this.findDirSiblings(mi.children, tmpID)
                if (subSiblings.length > 0) {
                    siblings = subSiblings
                }
            }
        })
        return siblings
    }

    expandTreeNodes() {
        this.expandedDirs.selected.forEach((tmpID) => {
            const node = this.treeControl.dataNodes.find((n) => n.tmpID === tmpID)
            if (node) {
                this.treeControl.expand(node)
            }
        })
    }

    deleteFile(file: any) {
        this.confirmService
            .confirm({
                message: 'web.edit-mode.dir.confirm.delete-file'
            })
            .subscribe(res => {
                if (res) {
                    this.dirService.deleteFile(file.src).subscribe(
                        () => {
                            if (this.dirModel) {
                                this.changeDir(this.dirModel)
                            }
                            this.snackBarService.open(this.translate.instant('web.edit-mode.dir.snack.deleted-file'))
                        })
                }
            })
    }
}
