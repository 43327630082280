import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Route, Router, Routes, UrlSegment} from '@angular/router';
import {lastValueFrom, timer} from 'rxjs';
import {NotFoundComponent} from '../../views/public/not-found/not-found.component';
import {notSavedChangesGuard} from './not-saved-changes.guard';
import {PageEditorComponent} from '../components/web/page-component/page-editor.component';
import {GalleryEditComponent} from '../../views/secured/gallery/gallery-edit/gallery-edit.component';
import {FrontLayoutEditorComponent} from '../components/layouts/front-layout-editor/front-layout-editor.component';

@Injectable()
export class RouterConfigEditorService {

    private _configData?: Routes
    private _promise?: Promise<any> | null
    private _promiseDone = false

    get configData(): any {
        return this._configData
    }

    constructor(private http: HttpClient,
                private router: Router) {
        // check age of loaded config - 1 hour
        timer(3600000, 3600000).subscribe(response => {
            // timer(5000, 5000).subscribe(response => {
            this.renewRoutes(router, 'web').then()
        })
    }

    renewRoutes(router: Router, modulePath: string) {
        // force to load new routes
        this._promiseDone = false
        this._promise = null
        return this.addDynamicRoutes(router, modulePath)
    }

    addDynamicRoutes(router: Router, modulePath: string):
        Promise<Route[]> {

        return new Promise((resolve, reject) => {
                this.loadConfig().then((dynamicRoutes) => {
                    let configIsChanged = false;

                    router.config.forEach(root => {
                        // check/set child routes
                        if ((root as any)._loadedRoutes && (root as any)._loadedRoutes.length > 0) {
                            const foundChild: any =
                                (root as any)._loadedRoutes[0]?.children?.find((child: any) => {
                                    return child.path === modulePath
                                });
                            if (foundChild && (foundChild._loadedRoutes?.length || 0) > 0) {
                                foundChild._loadedRoutes.forEach((childRoute: any) => {
                                    if (childRoute.data && childRoute.data.addDynamicChild) {
                                        childRoute.children = dynamicRoutes[0].children
                                        router.resetConfig(router.config)
                                        configIsChanged = true
                                    }
                                })
                            }
                        }
                    })

                    if (configIsChanged) {
                        resolve(router.config);
                    }
                    resolve([]);
                })
            }
        )
    }

    loadConfig(isBackend = false): Promise<any> {
        const url = '/api/v1/settings/routing'

        if (this._promiseDone) {
            return Promise.resolve(this._configData)
        }

        if (this._promise != null) {
            return this._promise
        }

        this._promise =
            lastValueFrom(this.http.get(url))
                .then((data: any) => {
                    const frontRoutes = {
                        path: '',
                        component: FrontLayoutEditorComponent,
                        children: [] as Route[],
                    }

                    const component: any = {}
                    component['page'] = PageEditorComponent
                    component['gallery'] = GalleryEditComponent

                    data.reduce((routes: any, module: any) => {
                        routes.push({
                            component: component[module.type],
                            pathMatch: 'full',
                            canDeactivate: [notSavedChangesGuard],
                            matcher: (link: UrlSegment[]) => {

                                return link.length === 1 && link[0].path.match(new RegExp('^(' + module.alias + ')$', 'gmy')) ? {
                                    consumed: link,
                                    posParams: {
                                        alias: new UrlSegment(link[0].path, {})
                                    }
                                } : null
                            }
                        })
                        return routes
                    }, frontRoutes.children)

                    // basic front routes
                    frontRoutes.children.push({
                        path: '',
                        pathMatch: 'full',
                        component: PageEditorComponent
                    })

                    frontRoutes.children.push({
                        path: '404',
                        pathMatch: 'full',
                        component: NotFoundComponent,
                        data: {title: 'page-not-found'}
                    })

                    this._configData = [frontRoutes]
                    this._promiseDone = true
                    return this._configData
                })
                .catch((err: any) => {
                    // console.error('err')
                    this._promiseDone = true
                    return Promise.resolve()
                })
        return this._promise
    }
}

export function getRoutes(
    routerConfigService: RouterConfigEditorService
): Route[] {
    return [...routerConfigService.configData]
}
