import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Animations} from '../../../../../animations/animations';
import {
    CompanyAddressBlockSettingsModalComponent
} from './company-address-block-settings-dialog/company-address-block-settings-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, skipWhile} from 'rxjs/operators';
import {CompanyAddressData} from '../../../block';
import { SanitizeUrlPipe } from '../../../../../pipes/sanitize-url.pipe';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: '[app-company-address-block-editor]',
    templateUrl: './company-address-block-editor.component.html',
    styleUrls: ['./company-address-block.component.scss'],
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgTemplateOutlet, SanitizeUrlPipe]
})
export class CompanyAddressBlockEditorComponent implements OnInit, AfterViewInit {

    @Input() id: number | null = null
    @Input() companyAddressData?: CompanyAddressData
    @Output() blockData = new EventEmitter<any>()
    @ViewChild('editable') editable?: ElementRef
    html: BehaviorSubject<string> = new BehaviorSubject<string>('')

    constructor(
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.html.next(this.companyAddressData?.text || '')
    }

    ngAfterViewInit() {
        this.html.asObservable().pipe(
            skipWhile(val => val === ''),
            debounceTime(300)
        ).subscribe(html => this.blockData.emit(this.companyAddressData))
        if (this.editable) {
            // @todo this.initEditor(this.editable)
        }
    }

    onChange() {
        if (this.companyAddressData) {
            this.companyAddressData.text = '' // @todo this.editor.getData()
            this.html.next(this.companyAddressData.text)
        }
    }

    onChangeMap() {
        const dialogRef = this.dialog.open(CompanyAddressBlockSettingsModalComponent, {
            disableClose: true,
            data: {...this.companyAddressData}
        })
        dialogRef.afterClosed().subscribe((settings) => {
            if (settings) {
                this.companyAddressData = {text: '' /* @todo this.editor.getData()*/, ...settings}
                this.blockData.emit(this.companyAddressData)
            }
        })
    }
}
