import { UntypedFormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {WcbConfirmService} from '../../../../shared/services/wcb-confirm/wcb-confirm.service';
import { TranslateModule } from '@ngx-translate/core';
import {WcbSnackBar} from '../../../../shared/services/snack-bar/snack-bar.service';
import {GalleryService} from '../gallery.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Gallery, GalleryImage} from '../gallery';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import {WindowService} from '../../../../shared/services/window.service';
import { SanitizeUrlPipe } from '../../../../shared/pipes/sanitize-url.pipe';
import { EnableAllowedDirective } from '../../../../shared/directives/enableAllowed.directive';
import { WcbUploadFileComponent } from '../../../../shared/components/wcb-upload-file/wcb-upload-file.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-gallery-edit',
    templateUrl: './gallery-edit.component.html',
    styleUrls: ['./gallery-edit.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDividerModule,
        MatIconModule,
        CdkDropList,
        NgFor,
        CdkDrag,
        CdkDragHandle,
        MatButtonModule,
        WcbUploadFileComponent,
        EnableAllowedDirective,
        SanitizeUrlPipe,
        TranslateModule,
    ],
})
export class GalleryEditComponent implements OnInit, OnDestroy {
    public galleryForm?: FormGroup
    public gallery?: Gallery
    public docsEndPoint: string
    private subs: Subscription[] = []


    constructor(
        private fb: UntypedFormBuilder,
        private confirmService: WcbConfirmService,
        private snack: WcbSnackBar,
        private galleryService: GalleryService,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private windowService: WindowService
    ) {
        this.docsEndPoint = this.galleryService.docsEndPoint
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['galleryID'] && params['galleryID'] !== 'new') {
                this.fetchGallery(params['galleryID'])
            } else {
                this.buildItemForm()
            }
        })
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe())
    }

    fetchGallery(galleryID: number) {
        this.subs.push(
            this.galleryService.get(galleryID).subscribe((gallery: Gallery) => {
                this.gallery = gallery
                this.buildItemForm(gallery)
            })
        )
    }

    buildItemForm(gallery: Gallery | null = null) {
        this.galleryForm = this.fb.group({
            id: gallery?.id || null,
            name: [gallery?.name || '', Validators.required],
            active: [gallery?.active],
            description: [gallery?.description || ''],
            images: this.fb.array(gallery?.images?.map(i => this.fb.group({
                id: i.id, alt: i.alt, position: i.position
            })) || [])
        })
    }

    handleClose() {
        this.router.navigate(['/sec', 'gallery'])
    }

    handleSubmit() {
        if (this.galleryForm) {
            const values = this.galleryForm.value
            values.images = values.images.map((i: GalleryImage) => { // save and send only alt data
                return {id: i.id, alt: i.alt}
            })
            this.subs.push(
                this.galleryService.save(values).subscribe((gallery: Gallery) => {
                    this.snack.success('web.gallery.snack.saved')
                    if (!this.gallery?.id) {
                        this.router.navigate(['/sec', 'gallery', gallery.id])
                    } else {
                        this.buildItemForm(gallery)
                    }
                })
            )
        }
    }

    handleUploadResult(isUploaded: boolean) {
        if (isUploaded && this.gallery) {
            this.subs.push(
                this.galleryService.get(this.gallery.id).subscribe((gallery) => {
                    this.gallery = gallery
                    this.buildItemForm(gallery)
                    this.cdRef.markForCheck()
                })
            )
        }
    }

    handleFileDownload(file: GalleryImage, download = true) {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        this.subs.push(
            this.galleryService.fileDownload(file.id).subscribe(response => {
                if (response && this.windowService.isBrowser) {
                    const fileURL = URL.createObjectURL(response);
                    if (download) {
                        a.download = file.file
                        a.href = fileURL
                        a.click()
                        // @ts-ignore
                        this.windowService.getWindow()?.URL.revokeObjectURL(fileURL)
                    } else {
                        this.windowService.getWindow()?.open(fileURL)
                    }
                }
            })
        )
    }

    handleDeleteImage(image: GalleryImage) {
        this.subs.push(
            this.confirmService
                .confirm({
                    message: 'web.gallery.confirm.delete-image'
                })
                .subscribe(res => {
                    if (res) {
                        this.galleryService.deleteFile(image.id).subscribe(data => {
                            this.handleUploadResult(true); // refresh
                            this.cdRef.markForCheck()
                            this.snack.success('web.gallery.snack.image-deleted')
                    });
                    }
                })
        )
    }

    onFileDrop(event: CdkDragDrop<string[]>, docs: Array<GalleryImage>) {
        if (this.gallery) {
            moveItemInArray(docs, event.previousIndex, event.currentIndex)
            this.persistPositions(docs)
        }
    }

    persistPositions(docs: Array<GalleryImage>, reload = false) {
        if (this.gallery) {
            const files = [...docs]
            files.forEach((file, index) => {
                file.position = index
            })
            this.subs.push(
                this.galleryService.saveFilesData(this.gallery.id, files).subscribe((result) => {
                    if (!result || reload) {
                        this.handleUploadResult(true)
                        if (!result) {
                            this.snack.error('web.gallery.snack.file-order-error');
                        }
                    }
                })
            )
        }
    }

    trackImageById(index: number, item: GalleryImage) {
        return item.id;
    }

}
