import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
        });


        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translate.get(
            ['paginator.items-per-page',
                'paginator.next-page',
                'paginator.previous-page',
                'of',
                'paginator.first-page',
                'paginator.last-page']
        ).subscribe(translation => {
            this.itemsPerPageLabel = translation['paginator.items-per-page'];
            this.nextPageLabel = translation['paginator.next-page'];
            this.previousPageLabel = translation['paginator.previous-page'];
            this.lastPageLabel = translation['paginator.last-page'];
            this.firstPageLabel = translation['paginator.first-page'];
            this.changes.next();
        });
    }

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    }
}
