import {ChangeDetectionStrategy, Component} from '@angular/core'
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { CookieSettingsComponent } from './cookie-settings.component/cookie-settings.component';

export interface Cookie {
    type: 'mandatory' | 'marketing' | 'analytics' | 'other',
    name: string,
    cookie: string,
    link?: string
}

@Component({
    selector: 'app-cookie-overview',
    templateUrl: './cookie-overview.component.html',
    styleUrls: ['./cookie-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CookieSettingsComponent, MatTableModule, NgIf, TranslateModule]
})
export class CookieOverviewComponent {

    displayedColumns = ['type', 'name', 'cookie', 'link']
    cookies: Cookie[] = [
        {type: 'mandatory', name: 'Lesoservis', cookie: 'lang, cur, webco'},
        {
            type: 'analytics',
            name: 'Google Analytics',
            cookie: '_gid, _ga, _utma, _utmb, _utmc, _utmv, _utmt_oldTracker, _gcl_au,_gat',
            link: 'https://policies.google.com/privacy?hl=cs_CZ'
        },
        {
            type: 'marketing',
            name: 'Google Adwords',
            cookie: '',
            link: 'https://policies.google.com/privacy?hl=cs_CZ'
        },
        {type: 'marketing', name: 'Zbozi.cz', cookie: ''},
        {type: 'marketing', name: 'Sklik CZ', cookie: ''},
        {type: 'marketing', name: 'Heureka', cookie: ''},
    ]

    constructor() {
    }


}
