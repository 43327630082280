import {enableProdMode, ErrorHandler, APP_INITIALIZER, Injector, importProvidersFrom} from '@angular/core'
import {environment} from './environments/environment'
import * as Sentry from '@sentry/angular-ivy'
import {AppComponent} from './app/app.component';
import {CookieModule} from './app/views/cookie/cookie.module';
import {UpdaterModule} from './app/shared/services/updater/updater.module';
import {AuthModule} from './app/shared/services/auth/auth.module';
import {provideAnimations} from '@angular/platform-browser/animations';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {WbcHammerConfig} from './app/app.hammer.config';
import {HAMMER_GESTURE_CONFIG, BrowserModule, HammerModule, bootstrapApplication} from '@angular/platform-browser';
import {WcbAuthInterceptor} from './app/shared/services/interceptors/auth.interceptor';
import {WcbResponseInterceptor} from './app/shared/services/interceptors/response.interceptor';
import {WcbOfflineInterceptor} from './app/shared/services/interceptors/offline.interceptor';
import {HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient} from '@angular/common/http';
import {WcbUserService} from './app/shared/services/auth/user.service';
import {TranslateService, TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {configServiceFactory, AppRouting} from './app/app.routing';
import {Router} from '@angular/router';
import {MySentryErrorHandler} from './app/shared/services/error.service';
import {RouterConfigEditorService} from './app/shared/services/router-config-editor.service';
import {RouterConfigService} from './app/shared/services/router-config.service';
import {SharedModule} from "./app/shared/shared.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {registerLocaleData} from "@angular/common";
import localeCs from '@angular/common/locales/cs'
import localeSk from '@angular/common/locales/sk'
import localeExtraCs from '@angular/common/locales/extra/cs'
import localeExtraSk from '@angular/common/locales/extra/sk'
import {MAT_ICON_DEFAULT_OPTIONS} from "@angular/material/icon";
import {MAT_TABS_CONFIG} from "@angular/material/tabs";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

registerLocaleData(localeCs, 'cs-CZ', localeExtraCs)
registerLocaleData(localeSk, 'sk-SK', localeExtraSk)

if (environment.sentry) {
    Sentry.init({
        dsn: environment.sentry,
        release: environment.appVersion,
        environment: environment.production ? 'prod' : 'dev',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
            /*new Sentry.Integrations.TryCatch({
                XMLHttpRequest: true
            }),*/
            /*new BrowserTracing({
                tracingOrigins: ['localhost', 'https://' + environment.host],
                routingInstrumentation: Sentry.routingInstrumentation
            }),*/
        ],
        tracesSampleRate: 1,
        tracePropagationTargets: [environment.restApiHost],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: []
    });
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            HammerModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                }
            }),
            AuthModule,
            UpdaterModule,
            CookieModule,
            SharedModule,
            AppRouting),
        RouterConfigService,
        RouterConfigEditorService,

        {provide: ErrorHandler, useClass: MySentryErrorHandler},
        //{provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
        {provide: Sentry.TraceService, deps: [Router]},
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: configServiceFactory,
            deps: [Injector, RouterConfigService, RouterConfigEditorService, TranslateService, WcbUserService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WcbOfflineInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WcbResponseInterceptor,
            multi: true
        },
        {provide: HTTP_INTERCEPTORS, useClass: WcbAuthInterceptor, multi: true},
        {provide: HAMMER_GESTURE_CONFIG, useClass: WbcHammerConfig},
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {overlayPanelClass: 'fit-content'}
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {stretchTabs: false}
        },
        {
            provide: MAT_ICON_DEFAULT_OPTIONS,
            useValue: {fontSet: 'material-symbols-rounded'}
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.error(err));
