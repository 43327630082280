import {Block, ImageData} from './block'
import {MenuItem} from './menu';
import {Dir} from './dir';
import {environment} from '../../../../environments/environment';

export const defaultMenuItem: MenuItem = {
    id: 0,
    tmpID: 1,
    position: 1,
    title: 'Nová stránka',
    link: 'nova-stranka',
    isExternal: false,
    children: [],
    isActive: true,
    isHomepage: false,
    seo: {
        description: '',
        keywords: '',
        disabled: false
    }
}

export const defaultDir: Dir = {
    tmpID: 1,
    name: 'new-folder',
    path: '/',
    children: []
}

export const noImage = environment.defaultEmptyImage
export const defaultText = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Fusce consectetuer risus a nunc. Integer in sapien. Etiam bibendum elit eget erat. Fusce dui leo, imperdiet in, aliquam sit amet, feugiat eu, orci. Etiam sapien elit, consequat eget, tristique non, venenatis quis, ante. Integer malesuada. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Nullam lectus justo, vulputate eget mollis sed, tempor sed magna. Morbi scelerisque luctus velit. Proin pede metus, vulputate nec, fermentum fringilla, vehicula vitae, justo. Suspendisse sagittis ultrices augue. Integer vulputate sem a nibh rutrum consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim.'

const defaultBlock = {
    id: 0,
    tmpID: 1,
    cols: {start: 1, end: 5},
    rows: {start: 0, end: 1},
    position: 0,
    background: environment.css['block-default-background'],
}

export const defaultTextBlock: Block = {
    ...defaultBlock,
    data: defaultText,
    type: 'text'
}

export const defaultImageData: ImageData = {
    src: noImage,
    filename: '',
    alt: '',
    link: '',
    isExternalLink: false
}


export const defaultImageBlock: Block = {
    ...defaultBlock,
    data: defaultImageData,
    type: 'image'
}

export const defaultTextImageBlock: Block = {
    ...defaultBlock,
    data: {
        text: defaultText,
        image: defaultImageData
    },
    type: 'text-image'
}

export const defaultMenuItemListBlock: Block = {
    ...defaultBlock,
    data: {link: ''},
    type: 'menu-item-list'
}

export const defaultContactBlock: Block = {
    ...defaultBlock,
    data: '',
    type: 'contact'
}

export const defaultCompanyAddressBlock: Block = {
    ...defaultBlock,
    data: {
        text: '<h2>Web-co IT s.r.o.</h2><p>sídlo firmy</p><p>Antala Staška 47, 350 02 Cheb</p><p>Tel/fax.: 354 123 456</p><p>IČ: 123 456 789</p>',
        address: 'Antala Staška 47, 350 02 Cheb',
        height: 400
    },
    type: 'company-address'
}

export const defaultGalleryBlock: Block = {
    ...defaultBlock,
    data: '',
    type: 'gallery'
}

export const defaultDownloadsBlock: Block = {
    ...defaultBlock,
    data: '',
    type: 'downloads'
}
