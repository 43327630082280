import {Pipe, PipeTransform} from '@angular/core';
import {DirService} from '../services/dir.service';
import {noImage} from '../components/web/default.blocks';
import {environment} from '../../../environments/environment';

@Pipe({
    name: 'imgAPISrc',
    standalone: true
})
export class ImageApiSrcPipe implements PipeTransform {

    imagesEndPoint: string

    constructor() {
        this.imagesEndPoint = environment.restApiHost + environment.apiImageEndpoint
    }

    transform(src: string | undefined, dimensions?: { height: number, width: number }) {
        if(src) {
            return this.getImageSrc(src, false, dimensions)
        }
        return '';
    }

    getImageSrc(imagePathToFile: string, isEditing: boolean = false, blockDimensions = {width: 150, height: 150}) {
        if (!imagePathToFile) {
            return noImage
        }

        if (isEditing) {
            blockDimensions = {width: 1920, height: 1920}
        }

        const base64Data = /data:image.*/
        const blob = /blob*/
        if (base64Data.test(imagePathToFile) || blob.test(imagePathToFile)) {
            return imagePathToFile
        } else {
            return (this.imagesEndPoint + imagePathToFile +
                '&w=' + Math.floor(blockDimensions.width) + '&h=' + Math.floor(blockDimensions.height))
        }
    }

}
