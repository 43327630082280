import {animate, group, query, state, style, transition, trigger} from '@angular/animations';

export const Animations = {

    fadeIn: [
        style({opacity: 0}),
        animate('600ms ease-in', style({opacity: 1})),
    ],

    routeAnimations: trigger('routeAnimations', [
        transition('* <=> *', [
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    opacity: 0,
                    transform: 'scale(0) translateY(100%)'
                }),
            ]),
            query(':enter', [
                    animate('600ms ease', style({
                        opacity: 1,
                        transform: 'scale(1)'
                    }))],
                {optional: true})
        ])
    ]),

    fadeInOut: trigger('fadeInOut', [
        transition(':enter', [
            style({opacity: 0}),
            animate('400ms ease-in', style({opacity: 1})),
        ]),
        transition(':leave', [
            style({opacity: 1}),
            animate('400ms ease-out', style({opacity: 0}))
        ]),
    ]),

    categoryRotate: trigger('categoryRotate', [
        state('rotate', style({
            transform: 'rotate({{rotate}}deg)', // use interpolation
        }), {params: {rotate: 5}}), // default parameters values required
        transition('* => rotate', [
            style({
                transform: 'rotate({{rotate}}deg) translateZ(0)'
            }),
            // animate('400ms linear')
        ])
    ]),

    simpleRollIn: trigger('simpleRollIn', [
        transition(':enter', [
            style({opacity: 0}),
            animate(350)
        ])
    ]),

    slideInOut: trigger('slideInOut', [
        transition(':enter', [
            style({transform: 'translateY(-100%)', opacity: 0}),
            animate('200ms ease-in', style({transform: 'translateY(0%)', opacity: 1}))
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({transform: 'translateY(-100%)', opacity: 0}))
        ])
    ]),

    slideInOutRight: trigger('slideInOutRight', [
        transition(':enter', [
            style({transform: 'translateX(-100%)', opacity: 0}),
            animate('200ms ease-in', style({transform: 'translateX(0%)', opacity: 1}))
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({transform: 'translateX(-100%)', opacity: 0}))
        ])
    ]),

    slide: trigger('slide', [
        transition(':increment', group([
            query(':enter', [
                style({
                    transform: 'translateX(100%)', opacity: 0
                }),
                animate('0.5s ease-out', style('*'))
            ], {optional: true}),
            query(':leave', [
                animate('0.5s ease-out', style({
                    transform: 'translateX(-100%)', opacity: 0
                }))
            ], {optional: true})
        ])),
        transition(':decrement', group([
            query(':enter', [
                style({
                    transform: 'translateX(-100%)', opacity: 0
                }),
                animate('0.5s ease-out', style('*'))
            ], {optional: true}),
            query(':leave', [
                animate('0.5s ease-out', style({
                    transform: 'translateX(100%)', opacity: 0
                }))
            ], {optional: true})
        ]))
    ]),

    slideText: trigger('slideText', [
        state('move',
            style({'margin-left': '{{slide_left}}'}),
            {params: {slide_left: 0}}),
        state('back',
            style({'margin-left': 0})
        ),
        transition('void => move, * => move', [
            animate('5s linear')
        ]),
        transition('move => back', [
            animate('5s linear')
        ])
    ])

}
