import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class UrlService {

    private currentUrl: string = '';
    public previousUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('')

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.previousUrl$.next(this.currentUrl)
                    this.currentUrl = event.url
                }
            }
        )

    }

}
