<div #image *ngIf="valign === 'top'" class="iw image-on-top"
     [ngClass]="{'image-on-left': align === 'left', 'image-on-right': align === 'right'}">
    <ng-container *ngTemplateOutlet="this.blockData.image.link ? frontImageWithLink : frontImage"></ng-container>
</div>
<div [innerHTML]="blockData.text|safe:'html'" class="text"></div>
<div #image *ngIf="valign === 'bottom'" class="iw image-on-bottom"
     [ngClass]="{'image-on-left': align === 'left', 'image-on-right': align === 'right'}">
    <ng-container *ngTemplateOutlet="this.blockData.image.link ? frontImageWithLink : frontImage"></ng-container>
</div>
<div style="clear:both" fxHide.lt-md></div>

<ng-template #frontImage>
    <img loading="lazy" *appShowIfInView
         appImageFadeIn
         [width]="widthInPx"
         [height]="heightInPx"
         [src]="((imageSrc|async) || '')|imgAPISrc:{width: widthInPx, height: heightInPx}" style="clear: both"
         [style.float]="align"
         [style.width.%]="width"
    />
</ng-template>

<ng-template #frontImageWithLink>
    <a [href]="this.blockData.image.link" target="{{this.blockData.image.isExternalLink ? '_blank' : '_self'}}">
        <ng-container *ngTemplateOutlet="frontImage"></ng-container>
    </a>
</ng-template>
