<!-- front-theme has two "front-theme" classes -->
<div class="front-theme">
    <div class="flex-col flex-100 flex-grow justify-center front-theme" style="width: 100%">
        <mat-sidenav-container>
            <mat-sidenav *ngIf="sidenavMode === 'over'" #sidenav [mode]="sidenavMode"
                         [autoFocus]="false"
                         [fixedInViewport]="sidenavMode === 'over'" [(opened)]="sidenavActive"
                         [fixedTopGap]="128">
                <!-- mobile menu -->
                <app-main-menu *ngIf="sidenavMode === 'over'" [direction]="'column'"></app-main-menu>
            </mat-sidenav>

            <mat-sidenav-content class="flex-col">
                <header>
                    <div class="header-top-wrapper">
                        <!-- desktop header -->
                        <div class="flex-row flex-start hide-sm">
                            <div style="width: 150px">
                                <a [routerLink]="['../']" style="max-width: 100%">
                                    <img class="m-1" style="max-width: calc(100% - 2rem)" src="/assets/images/logo.svg"
                                         alt="{{'basic-info.company'|translate}}"/>
                                </a>
                            </div>

                            <div class="flex-row flex-start items-end flex-grow gap-1">
                                <div class="flex-row flex-wrap flex-grow">
                                    <div class="flex-row flex-start items-center flex-shrink mb-1 mr-1">
                                        <mat-icon class="mr-05">email</mat-icon>
                                        <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                    </div>
                                    <div class="flex-row flex-start items-center flex-shrink mb-1">
                                        <mat-icon class="mr-05">support_agent</mat-icon>
                                        {{'basic-info.phone'|translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- mobile header -->
                        <div class="flex-col hide-gt-sm pb-1 pt-1">
                            <div class="flex-row justify-end items-center hide-gt-xs"
                                 style="font-size: 0.8rem; background-color: #0E361D; width: 100%">
                                <div style="width: auto"
                                     class="flex-row flex-center items-center flex-shrink flex-xs-50 mr-1">
                                    <mat-icon class="mr-05" style="overflow: hidden">email</mat-icon>
                                    <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                </div>
                                <div class="flex-row flex-center items-center flex-xs-50 flex-gt-xs-auto flex-shrink">
                                    <mat-icon class="mr-05" style="overflow: hidden">support_agent</mat-icon>
                                    {{'basic-info.phone'|translate}}
                                </div>
                            </div>
                            <div class="flex-gt-xs-grow justify-start items-end">
                                <div class="flex-100px flex-grow flex-shrink">
                                    <a [routerLink]="['../']" style="max-width: 100%">
                                        <img class="mr-05 mt-05 ml-1" style="max-width: calc(100% - 2rem)"
                                             src="/assets/images/logo.svg"
                                             alt="{{'basic-info.company'|translate}}"/>
                                    </a>
                                </div>
                                <div class="flex-row flex-wrap justify-end items-center flex-grow mb-0 pb-0 pr-05"
                                     ngClass.xs="mt-05">
                                    <div
                                        class="hide-xs justify-start items-center flex-shrink flex-grow flex-70 flex-row flex-wrap"
                                        style="font-size: 0.8rem">
                                        <div class="flex-row justify-start items-center flex-xs-50 mr-1">
                                            <mat-icon class="mr-05">email</mat-icon>
                                            <a href="mailto:{{'basic-info.info'|translate}}">{{'basic-info.info'|translate}}</a>
                                        </div>
                                        <div class="flex-row justify-start items-center flex-xs-50">
                                            <mat-icon class="mr-05">support_agent</mat-icon>
                                            {{'basic-info.phone'|translate}}
                                        </div>
                                    </div>
                                    <button mat-raised-button color="primary"
                                            class="flex-40px justify-center items-center head-btn"
                                            (click)="sidenav?.toggle()">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-form-field appearance="outline"
                                                    class="flex-100 flex-grow flex-shrink mt-05 mb-0">
                                        <input matInput name="search"
                                               placeholder="{{'basic-info.search-placeholder'|translate}}"/>
                                        <mat-icon matSuffix class="mat-bg-accent" style="overflow:hidden">search
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-top-menu-wrapper">
                        <div>
                            <!-- desktop menu -->
                            <app-main-menu class="hide-lt-md"></app-main-menu>
                            <!-- mobile menu toggle -->
                        </div>
                    </div>
                </header>

                <article class="flex-grow">
                    <router-outlet></router-outlet>
                </article>
                <!--aside class="sidebar-right">
                    <div style="height: 5rem">
                    <div class="flex-row justify-center items-center cart mat-bg-accent" [routerLink]="['/cart']" mat-ripple>
                        <mat-icon [matBadge]="cartService.itemsAmountSub|async" matBadgeColor="warn" matBadgePosition="below after">shopping_cart</mat-icon>
                    </div>
                    </div>
                    <app-grid-editor [maxColumns]="1" [location]="'right'"
                              [type]="'aside'"></app-grid>
                </aside-->
            </mat-sidenav-content>
        </mat-sidenav-container>
        <footer>
            <div>
                <app-grid-editor [maxColumns]="4" [location]="'footer'"
                                 [type]="'aside'"></app-grid-editor>
                <p class="copy mt-1">&copy; 2023 {{'basic-info.company'|translate}} |
                    <span class="ml-05 mr-05">Created by <a href="https://www.web-co.cz" title="Web-Co IT s.r.o."
                                                            target="_blank">Web-Co IT s.r.o.</a></span>
                    |<a [routerLink]="['/', 'cookie']" class="ml-05 mr-05" style="white-space: nowrap"
                        title="{{'cookie.settings'|translate}}">{{'cookie.settings'|translate}}</a>
                </p>
            </div>
        </footer>
    </div>
</div>
