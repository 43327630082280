import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdaterPromptComponent} from './updater-prompt.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        UpdaterPromptComponent,
    ],
})
export class UpdaterModule {

}
