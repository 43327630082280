import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core'
import {FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule} from 'ng2-file-upload'
import {environment} from '../../../../environments/environment';
import {WcbSnackBar} from '../../services/snack-bar/snack-bar.service';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {NgIf, NgClass, NgFor} from '@angular/common';

@Component({
    selector: 'app-upload-file',
    templateUrl: './wcb-upload-file.component.html',
    styleUrls: ['./wcb-upload-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatCardModule, NgClass, MatProgressBarModule, FileUploadModule, MatIconModule, MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule, MatButtonModule, TranslateModule]
})
export class WcbUploadFileComponent implements OnInit, OnChanges {
    fileUploader?: FileUploader
    hasErrors = false
    isInactive = false
    @ViewChild('fileInput') fileInput?: ElementRef
    @Input() uploadService?: { docsEndPoint: string, docsDataChanged?: EventEmitter<boolean> }
    @Input() endPoint?: string
    @Input() isMultiple = false
    @Input() id?: string | number
    @Input() destinationPath = ''
    @Input() autoUpload = false
    @Input() autohiding = false
    @Input() fileIDType = ''
    @Input() fileTitle = 'uploader.title'
    @Output() fileIsOk = new EventEmitter<any>()
    @Input() fileTypes: Array<string> = []
    hasDropZoneFileOver = false

    constructor(
        private snack: WcbSnackBar
    ) {
    }

    ngOnInit() {
        if (this.fileTypes.length && this.fileIDType === '') {
            this.fileIDType = this.fileTypes[0];
        }
        if (this.autohiding) {
            this.isInactive = this.autohiding
        }
        this.initUploader();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['id'] || changes['destinationPath']) {
            this.initUploader();
        }
    }

    setFileType(value: string) {
        this.fileIDType = value;
        if (this.fileUploader) {
            this.fileUploader.setOptions({...this.fileUploader.options, url: this.generateUrl()});
        }
    }

    initUploader() {
        this.isInactive = this.autohiding
        this.fileUploader = new FileUploader({
            url: this.generateUrl(),
            autoUpload: this.autoUpload,
            authTokenHeader: environment.authHeaderName,
            authToken: localStorage.getItem(environment.authHeaderName) || undefined,
            additionalParameter: {destinationPath: this.destinationPath}
        });

        this.fileUploader.onCompleteAll.bind(this);
        this.fileUploader.onCompleteAll = () => {
            this.fileIsOk.emit(!this.hasErrors);
            if (this.fileUploader) {
                this.fileUploader.clearQueue();
            }
            if(!this.hasErrors){
                this.snack.success('uploader.snack.upload-ok');
            }
            this.isInactive = this.autohiding;
            this.uploadService?.docsDataChanged?.emit(true)
        };

        this.fileUploader.onCompleteItem = (
            item: FileItem,
            response: any,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            if (response) {
                const data = JSON.parse(response);
                if (data.data) {
                    data.data.fileType = this.fileIDType;
                    this.fileIsOk.emit(data.data);
                }
            }
        }

        this.fileUploader.onAfterAddingFile = (fileItem: FileItem) => {
            this.checkFile(fileItem);
        };

        this.fileUploader.onErrorItem = (
            item: FileItem,
            response: any,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            if (response) {
                const data = JSON.parse(response);
                if (data.data) {
                    this.snack.error(data.message + ' (' + data.error + ')');
                } else {
                    this.snack.error(data.message)
                }
            } else {
                this.snack.error('snack.server-error')
            }
            this.hasErrors = true;
        };
    }

    private generateUrl(): string {
        return (this.uploadService?.docsEndPoint || this.endPoint) +
            (this.fileIDType ? this.fileIDType + '/' : '') +
            (this.id ? this.id : '')
    }

    private checkFile(fileItem: FileItem) {

        // msg files mime type is not detected - it is blank https://github.com/valor-software/ng2-file-upload/issues/811
        if (fileItem.file.type === '' && fileItem.file.name?.endsWith('msg')) {
            this.setFileType('letter')
            return;
        }

        // check file mimetype - all mimes, size->add alert or go
        if (fileItem.file.type && !environment.allowedFiles.reduce((mimes: string[], m) => {
            return mimes.concat(m.mimes)
        }, []).includes(fileItem.file.type)) {
            this.snack.warning('uploader.snack.not-allowed-mimetype', {mime: fileItem.file.type})
            fileItem.remove();
        } else if (fileItem.file.size > environment.allowedUploadSize) {
            this.snack.warning('uploader.snack.not-allowed-filesize', {size: environment.allowedUploadSize / 1024 / 1024})
            fileItem.remove();
        }

        // set the type of file
        if (this.fileTypes.length && this.fileUploader && this.fileUploader.queue.length) {
            environment.allowedFiles.map(type => {
                if (fileItem.file.type && type.mimes.includes(fileItem.file.type)) {
                    this.setFileType(type.type);
                }
            })
        }
    }

    onDropFileOver(event: any) {
        this.isInactive = false
        this.hasDropZoneFileOver = event;
    }

    onShowFileBrowser() {
        this.isInactive = false
        this.hasErrors = false;
        if (this.fileUploader) {
            this.fileUploader.clearQueue();
        }
        this.fileIsOk.emit(false);
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    getFileList() {
        return this.fileUploader?.queue.map(m => m.file.name).join()
    }
}
