import {Injectable} from '@angular/core';
import moment from "moment";

@Injectable({providedIn: 'root'})
export class InterceptorHelpers {

    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    constructor() {
    }

    convertToMoment(data: any) {
        if (data === null || data === undefined) {
            return data;
        }
        if (typeof data === 'object') {
            for (const key of Object.keys(data)) {
                const value = data[key];
                if (this.isIso8601(value)) {
                    data[key] = moment(value);
                } else if (typeof value === 'object') {
                    this.convertToMoment(value);
                }
            }
        } else if (data instanceof Array) {
            data.map(partialData => {
                return this.convertToMoment(partialData);
            });
        }

        return data;
    }

    isIso8601(value: any) {
        if (value === null || value === undefined) {
            return false;
        }
        return this.iso8601.test(value);
    }

    momentToISO(data: any) {
        if (data === null || data === undefined) {
            return data;
        }
        if (typeof data === 'object') {
            for (const key of Object.keys(data)) {
                const value = data[key];
                if (moment.isMoment(value)) {
                    data[key] = value.toISOString(true);
                } else if (typeof value === 'object') {
                    this.momentToISO(value);
                }
            }
        } else if (data instanceof Array) {
            data.map(partialData => {
                return this.momentToISO(partialData);
            });
        }
        return data;
    }
}
