import {MatDialogRef, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {NgClass} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {IConfirmData} from "./wcb-confirm.service";

@Component({
    selector: 'wcb-confirm',
    template: `<div mat-dialog-title class="flex-row items-center"><mat-icon class="mr-1" color="warn">{{data.icon}}</mat-icon>{{ data.title }}</div>
    <div mat-dialog-content [ngClass]="{'tablet-style': data.isTablet}"><p [innerHTML]="data.message"></p></div>
    <div mat-dialog-actions align="end" [ngClass]="{'tablet-style': data.isTablet}"
         class="flex-row space-between items-center">
        <button
            mat-button
            (click)="dialogRef.close(false)">{{'cancel'|translate}}</button>
        <button
            mat-raised-button
            color="warn"
            (click)="dialogRef.close(true)">{{(data.acceptButtonMessage || 'accept')|translate}}</button>
    </div>`,
    styleUrls: ['wcb-confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, NgClass, MatButtonModule, TranslateModule, MatIconModule]
})
export class WcbConfirmComponent {
    constructor(
        public dialogRef: MatDialogRef<WcbConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmData
    ) {
    }
}
