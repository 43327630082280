import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'webalize',
    standalone: true
})
export class WebalizePipe implements PipeTransform {

    transform(str: string) {

        const map: { [key: string]: string } = {
            '-': ' |_',
            'a': 'á|à|ã|â|À|Á|Ã|Â',
            'e': 'ě|é|è|ê|Ě|É|È|Ê',
            'i': 'í|ì|î|Í|Ì|Î',
            'o': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
            'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
            'c': 'č|ç|Ç|Č',
            'd': 'ď|Ď',
            'n': 'ň|ñ|Ň|Ñ',
            'r': 'ř|Ř',
            's': 'š|Š',
            't': 'ť|Ť',
            'y': 'ý|Ý',
            'z': 'ž|Ž'
        };

        str = str.toLowerCase();

        for (const pattern in map) {
            str = str.replace(new RegExp(map[pattern], 'g'), pattern);
        }

        return str;
    };

}
