import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false,
    standalone: true
})
export class FilterPipe implements PipeTransform {
    transform(items: Array<any>, propertyName: string, propertyValue: any = null, negative = false): Array<any> {
        if (propertyValue && propertyValue instanceof Array) {
            return items.filter(item => (item[propertyName] && (propertyValue ?
                (negative ? !propertyValue.includes(item[propertyName]) : propertyValue.includes(item[propertyName])) : true)))
        } else {
            return items.filter(item => {
                return (propertyValue ?
                        (negative ? item[propertyName] !== propertyValue : item[propertyName] === propertyValue) :
                        (negative ? (!item[propertyName] || (item[propertyName] === null)) : !!item[propertyName])
                )
            })
        }
    }
}
