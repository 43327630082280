import {HammerGestureConfig} from '@angular/platform-browser';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class WbcHammerConfig extends HammerGestureConfig {

    override options = {
        cssProps: {
            userSelect: {
                enable: true // enable text selection
            }
        }
    }

    override overrides = {
        swipe: {
            direction: 6 // this code enables only horizontal direction
        },
        pan: {
            enable: false
        },
        pinch: {
            enable: false
        },
        rotate: {
            enable: false
        }
    };
}
