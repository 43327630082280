<mat-nav-list *ngIf="(menuItem$|async) as menuItem">
    <div mat-subheader>{{menuItem.title}}</div>
    <ng-container *ngFor="let child of menuItem.children">
        <a *ngIf="!child.isExternal; else externalLink" mat-list-item
           [routerLink]="['/', child.link]">{{child.title}}</a>
        <ng-template #externalLink>
            <a mat-list-item href="https://{{child.link}}" target="_blank">{{child.title}}</a>
        </ng-template>
    </ng-container>
</mat-nav-list>
