import {NgModule} from '@angular/core'
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar'
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core'

// OTHER
import {environment} from '../../environments/environment'
/* @todo wait for upgrade to v17
    dependecies: {
        "ng-recaptcha": "^12.0.2"
    }
import {RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
 */

/* @todo wait for upgrade to v17
    dependecies: {
        "@angular-material-components/datetime-picker": "^17.0.1",
        "@angular-material-components/moment-adapter": "^17.0.1"
    }
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDateAdapter,
} from '@angular-material-components/datetime-picker'

import {
    NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    NGX_MAT_MOMENT_FORMATS,
    NgxMatMomentAdapter
} from '@angular-material-components/moment-adapter'
*/

import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from '@angular/material-moment-adapter'
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl} from "@angular/material/paginator";
import {CustomMatPaginatorIntl} from "./helpers/mat-paginator-cs_CZ";



@NgModule({
    imports: [
        MatSnackBarModule
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
            }
        },
        // date
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'cs-CZ'
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter
        },
        // pagination
        {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastButtons: true
            }
        },
        // date time
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        /*{provide: NGX_MAT_DATE_FORMATS, useValue: NGX_MAT_MOMENT_FORMATS},
        {
            provide: NgxMatDateAdapter,
            useClass: NgxMatMomentAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },*/
        // google recaptcha
        /* {
             provide: RECAPTCHA_V3_SITE_KEY,
             useValue: environment.captcha.siteKey
         }*/
    ]
})
export class SharedModule {
}
