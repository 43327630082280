import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy
} from '@angular/core'
import {Subscription, timer} from 'rxjs';
import {DataSet} from '../../../../../models/DataSet';
import {map} from 'rxjs/operators';
import {Animations} from '../../../../../animations/animations';
import {WindowService} from '../../../../../services/window.service';
import {SliderService} from '../../../../../../views/secured/slider/slider.service';
import {Slider} from '../../../../../../views/secured/slider/slider';
import {ImageApiSrcPipe} from '../../../../../pipes/image-api-src.pipe';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import { SanitizeUrlPipe } from '../../../../../pipes/sanitize-url.pipe';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ShowIfInViewDirective } from '../../../../../directives/showIfInView.directive';

@Component({
    selector: '[app-slider-block]',
    styleUrls: ['./slider-block.component.scss'],
    templateUrl: './slider-block.component.html',
    animations: [Animations.fadeInOut,
        trigger('overlayIn', [
            state('start', style({ 'fill': 'transparent' })),
            state('blacked', style({ 'fill': 'black' })),
            transition('start => blacked', animate(3500, keyframes([
                style({ 'fill': 'transparent', offset: 0.5 }),
                style({ 'fill': 'black', offset: 1 })
            ])))
        ]),
        trigger('textIn', [
            state('start', style({ 'transform': 'translateY(-100%)' })),
            state('blacked', style({ 'transform': 'translateY(0)' })),
            transition('start => blacked', animate('3000ms ease-in', keyframes([
                style({ transform: 'translateY(-100%)', offset: 0.7 }),
                style({ transform: 'translateY(0)', offset: 1 }),
            ])))
        ]),
        trigger('productIn', [
            state('start', style({ 'transform': 'translateY(100%)' })),
            state('blacked', style({ 'transform': 'translateY(0)' })),
            transition('start => blacked', animate('3500ms ease-in', keyframes([
                style({ transform: 'translateY(100%)', offset: 0.7 }),
                style({ transform: 'translateY(0)', offset: 1 }),
            ])))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ShowIfInViewDirective, MatButtonModule, MatIconModule, NgFor, NgIf, SanitizeUrlPipe, ImageApiSrcPipe]
})
export class SliderBlockComponent implements AfterViewInit, OnDestroy {

    blockDimensions?: { width: number, height: number }
    sliderProductAspectRatio = {width: 392, height: 280}
    sliderAspectRatio = {width: 980, height: 320}
    loading = true
    activeSlide = 0
    preparingSlide?: Slider
    sliders: Slider[] = []
    iteration = 0
    runningTimer: Subscription | null = null
    svgOverlayState = 'start';

    @HostListener('mouseover') onMouseOver() {
        this.pauseTimer()
    }

    @HostListener('mouseout') onMouseOut() {
        this.resumeTimer()
    }

    constructor(
        private element: ElementRef,
        private windowService: WindowService,
        private sliderService: SliderService,
        private imgApiSrc: ImageApiSrcPipe,
        private cdr: ChangeDetectorRef
    ) {
        sliderService.listAll().pipe(
            map((sliders: DataSet<Slider>) => {
                    return sliders?.data || []
                }
            )
        ).subscribe((sliders) => {
            this.sliders = sliders
            this.switchSlide()
            this.startSwitcher()
        })
    }

    ngOnDestroy() {
        this.runningTimer?.unsubscribe()
    }

    ngAfterViewInit() {
        this.checkDimensions()
    }

    checkDimensions() {
        this.blockDimensions = {
            // 980 x 320
            width: this.element.nativeElement.offsetWidth,
            height: (this.element.nativeElement.offsetWidth / 3.0625)
        }
    }

    startSwitcher() {
        this.runningTimer = timer(5000, 8000).subscribe((iteration) => {
            this.switchSlide()
        })
    }

    switchSlide(iteration: number | null = null) {
        const nextIndex = (iteration ? iteration : this.iteration) % (this.sliders.length)
        const nextImage: Slider = this.sliders[nextIndex]

        if (nextImage && (nextImage.path || nextImage.isInteractive)) {
            if (nextImage.path) {
                const image = new Image()
                image.onload = () => {
                    this.afterLoadImage(nextImage)
                }
                image.src = nextImage.path ? this.imgApiSrc.getImageSrc(nextImage.path) : ''
            } else {
                // slide with no background photo
                this.afterLoadImage(nextImage)
            }

        } else {
            // no image
            this.iteration += 1
            this.switchSlide()
        }
    }

    afterLoadImage(nextImage: Slider) {
        this.preparingSlide = nextImage
        this.activeSlide = 0
        this.iteration += 1
        this.cdr.markForCheck()

        // first slide
        if (this.loading) {
            this.loading = false
            this.handleShowNext(false)
        }
    }

    pauseTimer() {
        this.runningTimer?.unsubscribe()
        this.runningTimer = null
    }

    resumeTimer() {
        if (this.runningTimer) {
            this.runningTimer.unsubscribe()
            this.runningTimer = null
        }
        this.startSwitcher()
    }

    handleNext() {
        this.switchSlide(this.iteration)
        this.resumeTimer()
    }

    handlePrev() {
        const prevIndex = (this.iteration - 2);
        this.iteration = prevIndex > -1 ? prevIndex : (this.sliders.length - 1);
        this.switchSlide(this.iteration)
        this.resumeTimer()
    }

    handleShowNext(startNextAnimation = true) {
        if (startNextAnimation) {
            this.svgOverlayState = this.svgOverlayState === 'start' ? 'blacked' : 'start'
        }

        if (this.preparingSlide && this.preparingSlide?.id !== this.activeSlide) {
            setTimeout(() => {
                this.activeSlide = this.preparingSlide?.id || 0
                this.cdr.markForCheck()
            })

        }
    }

}

