import {EventEmitter, inject} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";
import {DataSet} from "../models/DataSet";
import {finalize, map, tap} from "rxjs/operators";
import {DataService} from "./data-source/wbc.table";
import {GalleryImage} from "../../views/secured/gallery/gallery";
import {Document} from "../models/Document";

export abstract class BaseApiService<T extends { id: number | string | undefined }> implements DataService<T> {

    public endPoint!: string;
    public docsEndPoint!: string;
    public defaultColumn: string = 'name';
    dataChanged: EventEmitter<boolean> = new EventEmitter();
    docsDataChanged?: EventEmitter<boolean>;

    http = inject(HttpClient)

    protected initDocsEndpoint() {
        this.docsEndPoint = this.endPoint + 'file/';
        this.docsDataChanged = new EventEmitter()
    }

    /**
     * Perform emit of data changed
     * @emits <boolean>
     */
    protected dataChangedEmit() {
        this.dataChanged.emit(true);
        this.refreshListData()
    }

    /**
     * API request for list of data
     * @returns {Observable<DataSet>}
     * @param queryParams
     * @param reportProgress
     */
    list(queryParams?: HttpParams, reportProgress: boolean = false): Observable<DataSet<T>> {
        if (!queryParams) {
            queryParams = new HttpParams().append('page', '0')
                .append('limit', '-1')
                .append('orderBy', '-' + this.defaultColumn)
                .append('ts', new Date().getTime().toString()); // prevent caching in IE
        }
        return this.http.get<DataSet<T>>(this.endPoint, {params: queryParams, reportProgress: reportProgress});
    }

    /**
     * API request for project details
     * @returns {Observable<<T>>}
     * @param id
     */
    get(id: number | string): Observable<T> {
        return this.http.get<T>(this.endPoint + id);
    }

    /**
     * API request to update or insert project details
     * @returns {Observable<<T>>}
     * @param data
     */
    save(data: T): Observable<T> {
        if (!data.id) {
            return this.http.post<T>(this.endPoint, data).pipe(
                finalize(() => {
                    this.dataChangedEmit();
                })
            );
        } else {
            return this.http
                .put<T>(this.endPoint + data.id, data)
                .pipe(
                    finalize(() => {
                        this.dataChangedEmit();
                    })
                );
        }
    }

    /**
     * API request to delete
     * @param id
     * @returns {Observable<void>}
     */
    delete(id: number | string): Observable<void> {
        return this.http.delete<void>(this.endPoint + id).pipe(
            finalize(() => {
                this.dataChangedEmit();
            })
        );
    }

    /**
     * ########################################################################
     * #########                   Images & Files                     #########
     * ########################################################################
     */


    getAttachmentList(id: string | number, queryParams?: HttpParams): Observable<Document[]> {
        return this.http.get<DataSet<Document>>(this.docsEndPoint + 'list/' + id, {params: queryParams}).pipe(
            map((dataSet) => {
                return dataSet.data
            })
        )
    }

    /**
     * API request to download a document/file
     * @param fileID
     * @param extraEndpoint
     * @param payload
     * @returns {Observable<boolean>}
     */
    fileDownload(fileID: number, extraEndpoint?: string, payload?: any): Observable<File | undefined> {
        let endPoint = this.docsEndPoint
        if (extraEndpoint) {
            endPoint = this.endPoint + extraEndpoint + '/'
        }
        return this.http
            .post(endPoint + fileID, payload, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(
                map(response => {
                    if (response.body) {
                        let filename = response.headers.get('content-disposition');
                        if (filename) {
                            filename = filename.split(';')[1].split('filename')[1].split('=')[1].replace(/"/g, '').trim();
                        }
                        return new File([response.body], filename ?? 'unknown', {type: response.body.type});
                    }
                    return undefined
                })
            );
    }

    protected openPDF(response: File, download = false) {
        if (response) {
            if (download) {
                // crate tmp anchor for downloading filename to be set
                const downloadFileName = response.name;
                const a = document.createElement('a');
                a.href = URL.createObjectURL(response);
                a.download = downloadFileName

                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a);
            } else {
                // open
                const blobUrl = URL.createObjectURL(response)
                const newWindow = window.open(blobUrl)
                if (newWindow) {
                    newWindow.onload = () => {
                        URL.revokeObjectURL(blobUrl)
                    }
                }
            }
        }
    }

    /**
     * API request to upload file to given model (Project, Worker, etc.)
     *
     * @param modelID
     * @param docs
     * @param type
     */
    public saveFilesData(modelID: number, docs: Array<Document | GalleryImage>, type: 'image' | 'doc' = 'image'): Observable<boolean> {
        return this.http.post<boolean>(this.docsEndPoint + modelID + '/' + type, docs).pipe(
            finalize(() => {
                this.docsDataChanged?.emit(true)
            })
        )
    }

    /**
     * API request to delete a document/file
     * @param fileID
     * @returns {Observable<boolean>}
     */
    deleteFile(fileID: number): Observable<boolean> {
        return this.http.delete<boolean>(this.docsEndPoint + fileID).pipe(
            finalize(() => {
                this.docsDataChanged?.emit(true)
            })
        )
    }


    /**
     * ########################################################################
     * #########                  Caching list data                   #########
     * ########################################################################
     */


    listData?: DataSet<T>
    listDataSubject = new ReplaySubject<DataSet<T>>(1)
    listData$ = this.listDataSubject.asObservable()

    getIndexedList(property?: keyof T): Observable<{ [key: number]: T | string }> {
        if (!this.listData) {
            this.getListData()
        }
        return this.listData$.pipe(
            map((items: DataSet<T>) => this.toIndexed(items.data, property || false))
        )
    }

    toIndexed(items: T[], property: keyof T | false) {
        if (!items?.length) {
            return [];
        }
        const itemsIndexed: { [key: number | string]: T | string } = {}
        items.forEach((item) => {
            if (item.id) {
                itemsIndexed[item.id] = (property ? (item[property] ? '' + item[property] : '???') : item)
            }
        })
        return itemsIndexed;
    }

    getListData(params?: HttpParams) {
        this.list(params)
            .pipe(
                tap(items =>
                    this.listData = items
                )
            ).subscribe(
            result => this.listDataSubject.next(result),
        )
    }

    refreshListData() {
        this.listData = undefined;
        this.getListData()
    }

}
