import {
    AfterViewInit, ChangeDetectionStrategy,
    Component, ElementRef, Input,
    OnInit, Output, ViewChild
} from '@angular/core';
import {EventEmitter} from '@angular/core';
import {debounceTime, skipWhile} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: '[app-text-block-editor]',
    templateUrl: './text-block-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class TextBlockEditorComponent implements AfterViewInit {

    html: BehaviorSubject<string> = new BehaviorSubject<string>('')
    @ViewChild('block') block?: ElementRef
    @Input() id?: number
    @Output() blockData = new EventEmitter<string>()


    ngAfterViewInit() {
        this.html = new BehaviorSubject<string>('')
        this.html.asObservable().pipe(
            skipWhile(val => val === ''),
            debounceTime(600)
        ).subscribe(html => this.blockData.emit(html))

        if (this.block) {
            //@todo this.initEditor(this.block)
        }
    }

    onChange() {
        this.html.next(''/* @todo this.editor.getData()*/)
    }

}
