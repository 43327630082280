<h3 mat-dialog-title>{{ 'web.edit-mode.pages.edit.menu-item.block-settings'|translate }}</h3>
<div mat-dialog-content>
    <form *ngIf="settingForm" [formGroup]="settingForm" (ngSubmit)="submit()">
        <div class="flex-row flex-wrap flex-xs-col">
            <div class="flex-100 pr-1">
                <mat-form-field class="full-width">
                    <mat-select
                        matInput
                        formControlName="link"
                        placeholder="{{ 'web.edit-mode.pages.edit.menu-item.title' | translate }}"
                    >
                        <mat-option [value]="item.link"
                                    *ngFor="let item of menuItems$|async">{{item.title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="flex-100 mt-1">
                <button
                    mat-button
                    color="warn"
                    type="button"
                    (click)="dialogRef.close(false)"
                >
                    {{ 'cancel' | translate }}
                </button>
                <span class="flex-1"></span>
                <button mat-raised-button color="primary" [disabled]="settingForm.invalid">
                    {{ 'set' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
