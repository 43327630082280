import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Animations} from '../../../../../animations/animations';

import {
    MenuItemListBlockSettingsModalComponent
} from './menu-item-list-block-settings-dialog/menu-item-list-block-settings-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {MenuItem} from '../../../menu';
import {PageService} from '../../../../../services/page.service';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf, NgFor, AsyncPipe} from '@angular/common';

@Component({
    selector: '[app-menu-item-list-block-editor]',
    templateUrl: './menu-item-list-block-editor.component.html',
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, MatListModule, NgFor, RouterLink, AsyncPipe, TranslateModule]
})
export class MenuItemListBlockEditorComponent implements OnDestroy {

    @Input() id: number | null = null
    @Input() mainMenuItemData?: { link: string }
    @Output() blockData = new EventEmitter<any>()
    menuItem$!: Observable<MenuItem>
    dialogSub?: Subscription

    constructor(
        private pageService: PageService,
        private dialog: MatDialog
    ) {
        this.setMenuItem()
    }

    setMenuItem() {
        this.menuItem$ = this.pageService.listActivePages().pipe(
            map((response) =>
                response.data.filter(i => this.mainMenuItemData && i.link === this.mainMenuItemData.link)[0] || null)
        )
    }

    onChooseMenuItem() {
        const dialogRef = this.dialog.open(MenuItemListBlockSettingsModalComponent, {
            disableClose: true,
            data: {
                link: this.mainMenuItemData && this.mainMenuItemData.link || ''
            }
        })

        this.dialogSub = dialogRef.afterClosed().subscribe((menuItem) => {
            if (menuItem) {
                this.mainMenuItemData = {link: menuItem.link}
                this.setMenuItem()
                this.blockData.emit({link: menuItem.link})
            }
        })
    }

    ngOnDestroy() {
        this.dialogSub?.unsubscribe()
    }

}
