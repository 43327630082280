import {ChangeDetectionStrategy, Component} from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-updater-prompt',
    template: `
        <h1 mat-dialog-title class="mb-1">{{ 'updater.prompt-title'|translate }}</h1>
        <div mat-dialog-content>{{ 'updater.prompt-text'|translate }}</div>
        <div mat-dialog-actions align="end">
            <button mat-button (click)="onNoClick()">{{ 'updater.cancel'|translate }}</button>
            <button mat-button mat-raised-button color="primary" type="button"
                    [mat-dialog-close]='true'>{{ 'updater.update'|translate }}
            </button>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, TranslateModule]
})
export class UpdaterPromptComponent {
    constructor(
        public dialogRef: MatDialogRef<UpdaterPromptComponent>) {
    }

    onNoClick() {
        this.dialogRef.close();
    }
}
