import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, Input, ViewChild
} from '@angular/core';
import moment from 'moment'
import {ClientMessageService} from './message.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {RecaptchaComponent, ReCaptchaV3Service} from 'ng-recaptcha';
import {Animations} from '../../../../../animations/animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-contact-block]',
    templateUrl: './contact-block.component.html',
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, NgSwitch, NgSwitchCase, MatButtonModule, MatIconModule, FormsModule, MatFormFieldModule, MatInputModule, TranslateModule]
})
export class ContactBlockComponent {

    @Input() id?: number
    @Input() isEditing = false

    @ViewChild(RecaptchaComponent) captcha?: RecaptchaComponent
    defaultContactData = {
        date: moment(),
        client: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        captcha: ''
    }

    completed = -1

    contactData: any

    submitting = false

    constructor(private messageService: ClientMessageService,
                private recaptchaV3Service: ReCaptchaV3Service,
                private cdr: ChangeDetectorRef) {
        this.contactData = Object.assign({}, this.defaultContactData)
    }

    sendContactForm() {
        this.submitting = true
        this.recaptchaV3Service.execute('contactForm')
            .subscribe((token: string) => {
                    const data = Object.assign({}, this.contactData)
                    data.captcha = token
                    this.messageService.sendMessage(data)
                        .pipe(
                            catchError((e) => {
                                this.completed = 1
                                return of(false)
                            })
                        )
                        .subscribe((response) => {
                            if (response) {
                                this.completed = 0
                                this.cdr.markForCheck()
                                this.contactData = Object.assign({}, this.defaultContactData)
                            } else {
                                this.completed = 1
                            }
                        })
                },
                () => {
                    this.completed = 1
                }
            )
    }

    resetForm() {
        this.submitting = false
        this.completed = -1
    }

}
