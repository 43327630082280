import {Component, OnInit, Inject, ChangeDetectionStrategy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-company-address-block-settings-modal',
    templateUrl: './company-address-block-settings-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, TranslateModule]
})
export class CompanyAddressBlockSettingsModalComponent implements OnInit {

    public settingForm?: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { address: string, height: number },
        public dialogRef: MatDialogRef<CompanyAddressBlockSettingsModalComponent>,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.settingForm = this.fb.group({
            address: [this.data.address || '', Validators.required],
            height: [this.data.height || 400, Validators.required]
        });
    }

    submit() {
        this.dialogRef.close(this.settingForm?.value);
    }
}
