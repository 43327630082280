<div class="page-grid" [style.grid-template-columns]="gridTemplateColumnsStyles[maxColumns]">
    <div *ngFor="let block of blocks" [style]="{'grid-column': block.cols.start + '/'+ block.cols.end,
                'grid-row': block.rows.start + '/'+ block.rows.end,
                background: block.background}">
        <div class="grid-block" [ngSwitch]="block.type">
            <div app-contact-block [id]="block.tmpID" *ngSwitchCase="'contact'"></div>
            <div app-image-block [id]="block.tmpID" *ngSwitchCase="'image'" [imageData]="getImageData(block.data)"></div>
            <div app-menu-item-list-block [id]="block.tmpID" *ngSwitchCase="'menu-item-list'"
                 [mainMenuItemData]="getLinkData(block.data)"></div>
            <div app-text-image-block [id]="block.tmpID" *ngSwitchCase="'text-image'"
                 [blockData]="getTextImageData(block.data)"></div>
            <div app-company-address-block [id]="block.tmpID" *ngSwitchCase="'company-address'"
                 [companyAddressData]="getCompanyAddressData(block.data)"></div>
            <div app-gallery-block [id]="block.tmpID"
                 *ngSwitchCase="'gallery'">
            </div>
            <div app-slider-block [id]="block.tmpID"
                 *ngSwitchCase="'slider'">
            </div>
            <div app-downloads-block [id]="block.tmpID"
                 *ngSwitchCase="'downloads'">
            </div>
            <div app-text-block [id]="block.tmpID" *ngSwitchDefault [innerHTML]="getTextData(block.data)|safe:'html'"></div>
        </div>
    </div>
</div>
