import {Observable, of} from 'rxjs';
import {WcbConfirmService} from './wcb-confirm/wcb-confirm.service';
import {CanDeactivateFn} from "@angular/router";
import {inject} from "@angular/core";

export interface CanDeactivateComponent {
    canDeactivate: () => Observable<boolean> | boolean;
}

export const notSavedChangesGuard: CanDeactivateFn<CanDeactivateComponent> = (component: CanDeactivateComponent): Observable<boolean> => {
    const confirmService = inject(WcbConfirmService)
    const canDeactivate = component.canDeactivate && component.canDeactivate()

    if (!canDeactivate) {
        return confirmService.confirm({
            title: 'confirm.unsaved-changes',
            message: 'confirm.unsaved-changes-confirm'
        })
    }
    return of(true);
}
