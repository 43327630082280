import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class WcbSnackBar {

    constructor(
        private snack: MatSnackBar,
        private translate: TranslateService
    ) {

    }

    open(message: string, values: any = null, action = 'OK', options: MatSnackBarConfig = {}) {
        this.snack.open(this.translate.instant(message, values), action, options);
    }

    error(message: string, values: any = null, action = 'OK', options: MatSnackBarConfig = {}) {
        options = {panelClass: 'error', ...options}
        this.snack.open(this.translate.instant(message, values), action, {...options, duration: 100000000});
    }

    warning(message: string, values: any = null, action = 'OK', options: MatSnackBarConfig = {}) {
        options = {panelClass: 'warning', ...options}
        this.snack.open(this.translate.instant(message, values), action, options);
    }

    success(message: string, values: any = null, action = 'OK', options: MatSnackBarConfig = {}) {
        options = {panelClass: 'success', ...options}
        this.snack.open(this.translate.instant(message, values), action, options);
    }

}
