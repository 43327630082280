<mat-progress-spinner *ngIf="loading" mode="indeterminate" class="image-loader"
                      diameter="40"></mat-progress-spinner>
<div #image *ngIf="valign === 'top'"
     class="image-on-top"
     [ngClass]="{'image-on-left': align === 'left', 'image-on-right': align === 'right', 'image-on-center': align === 'center'}">
    <ng-container *ngTemplateOutlet="editingImage"></ng-container>
</div>
<div #editable [innerHTML]="blockData.text|safe:'html'"></div>
<div #image *ngIf="valign === 'bottom'"
     class="image-on-bottom"
     [ngClass]="{'image-on-left': align === 'left', 'image-on-right': align === 'right', 'image-on-center': align === 'center'}">
    <ng-container *ngTemplateOutlet="editingImage"></ng-container>
</div>
<div style="clear:both"></div>

<ng-template #editingImage>
    <img draggable="false"
         style="clear: both"
         [style.width.%]="width"
         [src]="((imageSrc|async) || '')|imgAPISrc:blockDimensions" [alt]="imageDataSub.value.alt"/>
    <mat-slider min="10" [max]="maxWidthInPercent" step="1" class="visible-on-hover" color="accent"
                style="bottom: 0; left: 5%; width: 35%;">
        <input matSliderThumb [value]="width" (valueChange)="onZoom($event)">
    </mat-slider>
    <mat-form-field style="right: 190px; bottom: 26px; height: 20px; width: 60px;" class="visible-on-hover">
        <input matInput type="number" step="1" [value]="''+width" #widthInput [min]="10" [max]="maxWidthInPercent"
               (change)="onZoom(widthInput.value)"/>
    </mat-form-field>
    <button mat-button mat-raised-button style="right: 130px; bottom: 10px" class="visible-on-hover"
            type="button"
            (click)="toggleImageValign()">
        <mat-icon *ngIf="valign === 'top'">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="valign === 'bottom'">arrow_drop_up</mat-icon>
    </button>
    <button mat-button mat-raised-button style="right: 70px; bottom: 10px" class="visible-on-hover"
            type="button"
            [disabled]="valign === 'bottom'"
            (click)="toggleImageAlign()">
        <mat-icon *ngIf="align === 'left'">arrow_drop_up</mat-icon>
        <mat-icon *ngIf="align === 'right'">arrow_left</mat-icon>
        <mat-icon *ngIf="align === 'center'">arrow_right</mat-icon>
    </button>
    <button mat-button mat-raised-button style="right: 10px; bottom: 10px" class="visible-on-hover"
            type="button"
            (click)="setImageDimensionsToDefault()">
        <mat-icon>refresh</mat-icon>
    </button>
</ng-template>
