<div mat-dialog-title class="flex-row mb-0 justify-end">
    <button class="mt-1" (click)="dialogRef.close()" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="flex-grow dialog-max-height-none">
    <div class="flex-row justify-center items-center slider">
        <ng-template ngFor [ngForOf]="images" let-slide let-i="index">
            <div class="slide-wrapper" [@slide]="currentIndex">
                <img *ngIf="currentIndex === i" [src]="slide.path|imgAPISrc:dimensions" appImageFadeIn
                     (swiperight)="setPrevImage()" (swipeleft)="setNextImage()"
                     class="slide">
            </div>
        </ng-template>
    </div>
</div>
<div mat-dialog-actions class="pb-1 flex-row justify-center" *ngIf="images && images.length > 1">
    <button mat-icon-button color="primary" (click)="setPrevImage()" type="button">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button color="primary" (click)="setNextImage()" type="button">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>
