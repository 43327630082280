import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs'
import {Injectable} from '@angular/core'
import {GridBlockData} from '../block'
import {Block} from '../block'
import {Md5} from 'ts-md5';
import {finalize} from 'rxjs/operators';
import {PageService} from '../../../services/page.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GridService {
    gridBlocks: { [location: string]: BehaviorSubject<GridBlockData> } = {}

    constructor(
        private pageService: PageService,
        private http: HttpClient) {
    }

    /**
     * Track changes in blocks
     * @param gridID
     * @param type
     * @param location
     * @param blocks
     * @param renewDataHash
     */
    updateBlocks(gridID: number, type: string, location: string, blocks: Block[], renewDataHash = false) {
        const key = type + '-' + location
        const gridBlocks = {
            gridID: gridID,
            location: location,
            type: type,
            blocks: blocks,
            dataHash: this.gridBlocks[key] && !renewDataHash
                ? this.gridBlocks[key].value.dataHash : '' + Md5.hashStr(JSON.stringify(blocks))
        }
        if (this.gridBlocks[key]) {
            this.gridBlocks[key].next(gridBlocks)
        } else {
            this.gridBlocks[key] = new BehaviorSubject<GridBlockData>({
                ...gridBlocks
            })
        }
    }

    /**
     * Check if user changed something
     * @param types
     */
    hasChangedBlocks(types: Array<string>): boolean {
        let isChanged = false
        Object.keys(this.gridBlocks).forEach((key) => {
            const gridBlocks = this.gridBlocks[key].value
            if (types.includes(gridBlocks.type) && gridBlocks.dataHash !== ('' + Md5.hashStr(JSON.stringify(gridBlocks.blocks)))) {
                isChanged = true
            }
        })
        return isChanged
    }

    /**
     *
     * @param types
     */
    getByTypes(types: Array<string>) {
        const blocksOfTypes: GridBlockData[] = []
        Object.keys(this.gridBlocks).forEach((key) => {
            if (types.includes(this.gridBlocks[key].value.type)) {
                blocksOfTypes.push(this.gridBlocks[key].value)
            }
        })
        return blocksOfTypes
    }

    /**
     * API request to update or insert grids/pages blocks
     * @todo refactor
     */
    saveGridBlocks(): Observable<any> {
        const pageBlocks = this.getByTypes(['article', 'aside'])
        return forkJoin([
            ...pageBlocks.map((grid) => {
                const blocksData = JSON.parse(JSON.stringify(grid)) // deep copy
                if (blocksData.dataHash !== ('' + Md5.hashStr(JSON.stringify(grid.blocks)))) {
                    blocksData.pageID = blocksData.gridID
                    blocksData.blocks.map((block: Block) => {
                        if (typeof block.data !== 'string') {
                            block.data = JSON.stringify(block.data)
                        }
                    })
                    return this.http.post<boolean>(this.pageService.blockEndPoint, blocksData)
                } else {
                    return of(true)
                }
            })
        ]).pipe(
            finalize(() => {
                this.pageService.dataChangedEmit()
            })
        )
    }


    /** ####################################################################################################################################
     *  ## Blocks positions <=> grid matrix representation
     *  ################################################################################################################################# */

    initMatrix(blocks: Block[], grid: number[][], maxRows: number, isEditing: boolean = false) {
        if (blocks && blocks.length > 0) {
            const newGrid: number[][] = []
            blocks.forEach((b) => {
                const row = b.rows.start - 1
                newGrid[row] = newGrid[row] ?? []
                for (let i = b.cols.start; i < b.cols.end; i++) {
                    newGrid[row][(i - 1)] = b.tmpID
                }
            })
            this.repairAfterDeletingBlock(blocks, grid, maxRows, newGrid);
        }
    }

    repairAfterDeletingBlock(blocks: Block[], grid: number[][], maxRow: number, newGrid: number[][]) {
        // auto repair row numbers after deleting block
        const autoRepairedGrid: number[][] = []
        let rowIndex = 1;
        newGrid.forEach((row) => {
            autoRepairedGrid.push(row)
            row.forEach(block => {
                const blockForRepair = blocks.find(b => b.tmpID === block);
                if (blockForRepair) {
                    blockForRepair.rows = {start: rowIndex, end: rowIndex + 1}
                }
            })
            rowIndex += 1
        })

        grid = autoRepairedGrid
        maxRow = grid.length
    }

}
