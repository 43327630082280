<div [style.padding-bottom.%]="height" class="image-block">
    <ng-container *ngTemplateOutlet="imageData.link ? frontImageWithLink : frontImage"></ng-container>
</div>


<ng-template #frontImage>
    <img
        appImageFadeIn
        *appShowIfInView
        [loading]="imageCanLazyLoad ? 'lazy' : 'eager'"
        [width]="widthInPx"
        [height]="heightInPx"
        draggable="false"
        [style.width.%]="width"
        [style.top.%]="top"
        [style.left.%]="left"
        [style.max-width.px]="imageData.max?.width"
        [style.max-height.px]="imageData.max?.height"
        [src]="((imageSrc|async) || '')|imgAPISrc:blockDimensions" [alt]="imageData.alt"/>
</ng-template>

<ng-template #frontImageWithLink>
    <a [href]="imageData.link" target="{{imageData.isExternalLink ? '_blank' : '_self'}}">
        <ng-container *ngTemplateOutlet="frontImage"></ng-container>
    </a>
</ng-template>

