import {ChangeDetectionStrategy, Component} from '@angular/core'
import {CanDeactivateComponent} from '../../../services/not-saved-changes.guard';
import { GridComponent } from '../grid-component/grid.component';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [GridComponent]
})
export class PageComponent implements CanDeactivateComponent {

    canDeactivate() {
        return true;
    }

}
