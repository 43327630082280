import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WcbUser} from '../../../views/secured/user/user';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class WcbAuthService {
    private readonly endPoint: string;

    constructor(private http: HttpClient) {
        this.endPoint = 'v1/user/';
        this.endPoint = environment.restApiHost + this.endPoint;
    }

    signUser(username: string, password: string): Observable<WcbUser> {
        return this.http.post<WcbUser>(this.endPoint + 'login', {
            username: username,
            password: password
        });
    }

    getByAuthToken(): Observable<WcbUser> {
        return this.http.get<WcbUser>(this.endPoint);
    }

    logoutUser() {
        return this.http.get(this.endPoint + 'logout');
    }

    resetUserPassword(uID: number) {
        return this.http.put(this.endPoint + 'resetpassword', {id: uID});
    }

    resetUserPasswordByToken(token: string) {
        return this.http.put<{ new: string }>(this.endPoint + 'resetpasswordbytoken', {
            token: token
        });
    }

    requestResetPasswordEmail(email: string) {
        return this.http.put(this.endPoint + 'requestresetpassword', {
            email: email
        });
    }

    setPassword(pass: string[]) {
        return this.http.put(this.endPoint + 'setpassword', {password: pass});
    }
}
