import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppOfflineComponent} from './app-offline.component';

@Injectable({providedIn: 'root'})
export class AppOfflineService {
    dialogRef: MatDialogRef<AppOfflineComponent> | null = null;
    isOpening = false;

    constructor(private dialog: MatDialog) {
    }

    public open(title: string = 'no-connection'): void {
        if (!this.isOpening) {
            this.isOpening = true;
            if (this.dialogRef === null) {
                this.dialogRef = this.dialog.open(AppOfflineComponent, {
                    width: '200px',
                    autoFocus: false,
                    restoreFocus: true,
                    disableClose: true,
                    backdropClass: 'dark-backdrop'
                });
                this.dialogRef.componentInstance.title = title;
                this.dialogRef.afterOpened().subscribe(() => {
                    this.isOpening = false;
                });
            }
        }
    }

    public close() {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
            this.isOpening = false;
        }
    }
}
