import {AfterViewInit, Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appShowIfInView]',
    standalone: true
})
export class ShowIfInViewDirective implements AfterViewInit {
    observer?: IntersectionObserver

    constructor(
        private vcRef: ViewContainerRef,
        private tempRef: TemplateRef<any>
    ) {
    }

    ngAfterViewInit() {
        const observerElement = this.vcRef.element.nativeElement.parentElement
        this.observer = new IntersectionObserver(([entry]) => {
            this.renderContents(entry.isIntersecting)
        })
        this.observer.observe(observerElement)
    }

    renderContents(isIntersecting: boolean) {
        this.vcRef.clear()
        if (isIntersecting) {
            this.observer?.disconnect() // stop observing when its in page
            this.vcRef.createEmbeddedView(this.tempRef).markForCheck()
        }
    }
}
