import {ChangeDetectionStrategy, Component} from '@angular/core'
import {Observable} from 'rxjs';
import {DataSet} from '../../../../../models/DataSet';
import {map} from 'rxjs/operators';
import {Animations} from '../../../../../animations/animations';
import {DownloadsService} from '../../../../../../views/secured/downloads/downloads.service';
import {Document} from '../../../../../models/Document';
import {WindowService} from '../../../../../services/window.service';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: '[app-downloads-block]',
    templateUrl: './downloads-block.component.html',
    animations: [Animations.slideInOut],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, MatIconModule, AsyncPipe]
})
export class DownloadsBlockComponent {

    fileList$: Observable<Document[]>

    constructor(
        private windowService: WindowService,
        private downloadsService: DownloadsService) {

        this.fileList$ = downloadsService.listAll().pipe(
            map((downloads: DataSet<Document>) => {
                    return downloads?.data || []
                }
            )
        )
    }

    handleFileDownload(file: Document, download = true) {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        this.downloadsService.fileDownload(file.id).subscribe(response => {
            if (response && this.windowService.isBrowser) {
                const fileURL = URL.createObjectURL(response);
                if (download) {
                    a.download = file.file
                    a.href = fileURL
                    a.click()
                    // @ts-ignore
                    this.windowService.getWindow()?.URL.revokeObjectURL(fileURL)
                } else {
                    this.windowService.getWindow()?.open(fileURL)
                }
            }
        })
    }

}

