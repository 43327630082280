<div class="p-1" style="max-width: 100vw">
    <h2 class="mt-1">{{'cookie.overview-title'|translate}}</h2>
    <p>{{'cookie.overview-text'|translate}}</p>

    <div class="settings">
        <h2>{{'cookie.settings'|translate}}</h2>
        <app-cookie-settings></app-cookie-settings>
    </div>

    <h2 class="mt-1">{{'cookie.our-cookies'|translate}}</h2>

    <p>{{'cookie.our-cookies-text'|translate}}</p>

    <div style="max-width: 100%; overflow: auto">
        <mat-table [dataSource]="cookies" class="mt-1 mb-1" style="min-width: 680px;">

            <ng-container matColumnDef="type">
                <mat-header-cell class="flex-15" mat-header-cell
                                 *matHeaderCellDef>{{'cookie.type'|translate}}</mat-header-cell>
                <mat-cell class="flex-15" mat-cell
                          *matCellDef="let cookie"> {{'cookie.types.' + cookie.type|translate}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell class="flex-15" mat-header-cell
                                 *matHeaderCellDef>{{'cookie.name'|translate}}</mat-header-cell>
                <mat-cell class="flex-15" mat-cell *matCellDef="let cookie"> {{cookie.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cookie">
                <mat-header-cell class="flex-30" mat-header-cell
                                 *matHeaderCellDef>{{'cookie.cookie'|translate}}</mat-header-cell>
                <mat-cell class="flex-30" mat-cell *matCellDef="let cookie"> {{cookie.cookie}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="link">
                <mat-header-cell class="flex-40" mat-header-cell
                                 *matHeaderCellDef>{{'cookie.link'|translate}}</mat-header-cell>
                <mat-cell class="flex-40" mat-cell *matCellDef="let cookie"><a *ngIf="cookie.link"
                                                                               href="{{cookie.link}}"
                                                                               target="_blank">{{cookie.link}}</a>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
