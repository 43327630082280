import {Injectable} from '@angular/core';
import {WcbSnackBar} from '../../shared/services/snack-bar/snack-bar.service';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {CookieAcceptModalComponent} from './cookie-accept-modal.component/cookie-accept.modal.component';
import {LayoutService} from '../../shared/services/layout.service';
import {environment} from "../../../environments/environment";

export interface ConsentData {
    c: 0 | 1, // consent - mandatory
    a: 0 | 1, // analytical
    m: 0 | 1  // marketing
}

@Injectable({providedIn: 'root'})
export class CookieConsentService {

    consentLocalStorageName = 'webco_consent'
    consent: BehaviorSubject<ConsentData> =
        new BehaviorSubject<ConsentData>(JSON.parse(localStorage.getItem(this.consentLocalStorageName) || 'false') || {
            c: 0,
            a: 0,
            m: 0
        })
    destroyed = new Subject()

    constructor(private snack: WcbSnackBar,
                private dialog: MatDialog,
                private layoutService: LayoutService,
                private router: Router) {
        if (environment.module.web && this.consent.value.c === 0) {
            this.router.events.pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.destroyed)
            ).subscribe((event: any) => {
                const adminRoutes = new RegExp(/^\/(sec|tracker|pub)\/?.*$/)
                if (!event.url.match(adminRoutes)) {
                    this.showAcceptModal()
                }
            })
        } else {
            this.destroyed.next(0)
            this.destroyed.complete()
        }
    }

    showAcceptModal() {
        setTimeout(() => {
            this.dialog.open(CookieAcceptModalComponent, {
                closeOnNavigation: true,
                disableClose: true,
                width: this.layoutService.isSm() ? '80vw' : '40vw',
            })
        }, 2000)
    }

    setSettings(consentData: ConsentData): ConsentData {
        consentData.c = 1
        consentData.a = consentData.a ? 1 : 0
        consentData.m = consentData.m ? 1 : 0
        return this.setConsentToStorage(consentData)
    }

    acceptMandatory(): ConsentData {
        return this.setConsentToStorage({c: 1, a: 0, m: 0})
    }

    acceptAll(): ConsentData {
        return this.setConsentToStorage({c: 1, a: 1, m: 1})
    }

    setConsentToStorage(consentData: ConsentData) {
        this.consent.next(consentData)
        localStorage.setItem(this.consentLocalStorageName, JSON.stringify(consentData))
        this.snack.success('cookie.saved')
        this.destroyed.next(0)
        this.destroyed.complete()
        return this.consent.value
    }
}
