<div mat-dialog-title class="flex-row items-center">
    <h1 class="flex-row justify-start items-center title pb-1">
        <mat-icon class="mr-1">create_new_folder</mat-icon>
        {{ 'web.edit-mode.dir.edit-title'|translate }}
    </h1>
</div>
<div mat-dialog-content class="flex-col flex-grow">
    <form ngForm class="flex-col" *ngIf="dirModel">
        <mat-form-field>
            <input matInput required minlength="3"
                   [(ngModel)]="dirModel.name" name="name" type="text">
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-raised-button color="primary" (click)="setDir()" type="submit">{{'Ok'|translate}}</button>
    <button
        color="default"
        mat-raised-button
        (click)="dialogRef.close(false)">{{'close'|translate}}</button>
</div>
