import {ChangeDetectionStrategy, Component} from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-offline',
    templateUrl: './app-offline.component.html',
    styleUrls: ['./app-offline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatIconModule, TranslateModule]
})
export class AppOfflineComponent {
    title: string = 'offline';
    constructor(public dialogRef: MatDialogRef<AppOfflineComponent>) {
    }

}
