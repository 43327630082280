import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Dir} from '../../../../dir'
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector: 'app-dir-name-editor',
    templateUrl: './dir-name-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatIconModule, NgIf, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, TranslateModule]
})

export class DirNameEditorComponent {

    dirModel: Dir

    constructor(
        public dialogRef: MatDialogRef<DirNameEditorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.dirModel = this.data
    }

    setDir() {
        this.dialogRef.close(this.dirModel)
    }

}
