<div mat-dialog-title class="text-center">
    <mat-icon style="font-size: 5rem; width: auto; height: auto; margin-top: 1rem">cookie</mat-icon>
    <h2>{{'cookie.settings-title'|translate}}</h2>
</div>
<div mat-dialog-content class="flex-col">
    <p [innerHTML]="'cookie.settings-text'|translate" class="text-center mb-1"></p>
    <div class="flex-col justify-center gap-1 flex-gt-sm-row">
        <button type="button" mat-raised-button color="primary"
                (click)="handleAcceptAll()">{{'cookie.allow-all'|translate}}</button>
        <button type="button" mat-raised-button
                (click)="handleAcceptMandatory()">{{'cookie.allow-mandatory'|translate}}</button>
    </div>
    <button type="button" mat-flat-button class="mt-1"
            (click)="handleGoToSettings()">{{'cookie.settings'|translate}}</button>
</div>
