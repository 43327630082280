import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppLoaderComponent} from './app-loader.component';

@Injectable({providedIn: 'root'})
export class AppLoaderService {
    dialogRef: MatDialogRef<AppLoaderComponent> | null = null;
    counter = 0;
    isOpening = false;

    constructor(private dialog: MatDialog) {
    }

    public open(title: string = 'please-wait'): void {
        this.counter += 1;
        if (!this.isOpening) {
            this.isOpening = true;
            if (this.dialogRef === null) {
                this.dialogRef = this.dialog.open(AppLoaderComponent, {
                    width: '200px',
                    autoFocus: false,
                    restoreFocus: true,
                    disableClose: true,
                    backdropClass: 'light-backdrop'
                });
                this.dialogRef.componentInstance.title = title;
                this.dialogRef.afterOpened().subscribe(() => {
                    this.isOpening = false;
                });
            }
        }
    }

    public close() {
        this.counter -= 1;
        if (this.counter === 0 && this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
            this.isOpening = false;
        }
    }
}
