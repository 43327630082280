<mat-progress-spinner *ngIf="loading" mode="indeterminate" class="image-loader"
                      diameter="40"></mat-progress-spinner>
<div [style.padding-bottom.%]="height" class="image-block">
    <img draggable="false" #image
         [style.width.%]="width"
         [style.top.%]="top"
         [style.left.%]="left"
         [src]="imageData.src|imgAPISrc:blockDimensions" [alt]="imageData.alt"/>
</div>
<mat-slider min="10" [max]="maxWidthInPercent" step="1" class="visible-on-hover"
            color="accent"
            style="left: 5%; bottom: 0; width: 60%">
    <input matSliderThumb [value]="width" (valueChange)="onZoom($event)">
</mat-slider>
<mat-slider *ngIf="width > 100" color="accent"
            [min]="-(width-100)" max="0" step="1" class="visible-on-hover"
            style="left: 5%; bottom: 20px; width: 60%">
    <input matSliderThumb [value]="left" (valueChange)="onSetLeft($event)">
</mat-slider>
<mat-form-field style="right: 70px; bottom: 26px; height: 20px; width: 60px" class="visible-on-hover"
                appearance="outline">
    <input matInput type="number" step="1" [value]="''+width" #widthInput [min]="10" [max]="maxWidthInPercent"
           (change)="onZoom(widthInput.value)"/>
</mat-form-field>
<button mat-button mat-raised-button style="right: 10px; bottom: 10px" class="visible-on-hover"
        type="button"
        (click)="setImageDimensionsToDefault()">
    <mat-icon>refresh</mat-icon>
</button>
