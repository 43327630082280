import {WcbUserService} from './user.service';
import {WcbAuthService} from './auth.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        WcbAuthService,
        WcbUserService,
    ]
})
export class AuthModule {
}
