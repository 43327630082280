import * as Sentry from '@sentry/angular-ivy';
import {ErrorHandler, inject, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {WcbSnackBar} from './snack-bar/snack-bar.service';

@Injectable({
    providedIn: 'root'
})

export class MySentryErrorHandler implements ErrorHandler {

    private snack = inject(WcbSnackBar)

    handleError(error: any) {
        let duration = 30000;
        let isUserError = false;
        const capturingError = error.originalError || error

        if (capturingError instanceof HttpErrorResponse) {
            let message: string;
            switch (capturingError.status) {
                case 400: {
                    message = 'snack.' + (capturingError.error ? capturingError.error.message : 'server-error');
                    duration = 5000;
                    isUserError = true;
                    break;
                }
                case 401: {
                    message = 'snack.login-failed';
                    duration = 5000;
                    isUserError = true;
                    break;
                }
                case 403: {
                    message = 'snack.' + (capturingError.error ? capturingError.error.message : 'message-access-denied');
                    duration = 5000;
                    isUserError = true;
                    break;
                }
                case 500: {
                    message = 'snack.server-error';
                    break;
                }
                default: {
                    message = 'snack.' + (capturingError.error ? capturingError.error.message : 'server-error');
                }
            }
            //these exceptions are logged to sentry by backend
            this.snack.error(message, {}, 'OK', {duration: duration});
        } else {
            // Capture handled exception non HTTPResponseException and send it to Sentry.
            Sentry.captureException(error);
        }

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            console.error('An error: ', capturingError);
        }

        // const eventId = Sentry.captureException(extractedError);
        // Optionally show user dialog to provide details on what happened.
        // Sentry.showReportDialog({ eventId });
    }

}
