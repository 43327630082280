<mat-card class="flex-col p-1" [ngClass]="{inactive: isInactive, 'pt-0 mt-1': !isInactive}" *ngIf="fileUploader">
    <mat-progress-bar
        style="margin: -1rem -1rem 0.5rem -1rem; width: auto;"
        [color]="'accent'"
        [value]="fileUploader.progress"
    ></mat-progress-bar>
    <div class="title" *ngIf="fileTitle">{{fileTitle | translate}}</div>
    <input
        #fileInput
        type="file"
        ng2FileSelect
        [uploader]="fileUploader"
        style="display: none"
        [multiple]="isMultiple"
    />
    <div class="file-title" [ngClass]="{'mt-1': !fileTitle}">{{'uploader.chosen'|translate}}
        : <strong>{{ fileUploader.queue[0] ? getFileList() : 'uploader.no-file'|translate}}</strong>
    </div>

    <div ng2FileDrop class="flex-row justify-center items-center drop-zone"
         [ngClass]="{'file-over': hasDropZoneFileOver}"
         [uploader]="fileUploader"
         (fileOver)="onDropFileOver($event)"
         (click)="onShowFileBrowser()"
    >
        <mat-icon>note_add</mat-icon>&nbsp;{{'uploader.drop-area'|translate}}
    </div>

    <div class="flex-row justify-end action">
        <mat-form-field class="flex-50" *ngIf="fileTypes.length">
            <mat-select placeholder="{{'uploader.file-type'|translate}}"
                        [ngModel]="fileIDType" (selectionChange)="setFileType($event.value)">
                <mat-option *ngFor="let type of fileTypes"
                            [value]="type">{{'uploader.file-types.' + type|translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-button
                mat-raised-button
                type="button"
                class="flex-48 flex-offset-2 mat-accent"
                (click)="fileUploader.uploadAll()"
                [disabled]="fileUploader.isUploading || !fileUploader.queue.length"
        >
            <mat-icon>file_upload</mat-icon>&nbsp;{{ 'uploader.upload' | translate }}
        </button>
    </div>
</mat-card>
