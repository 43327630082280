// window-service.ts
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

declare global {
    interface Window {
        rc: { retargetingHit: Function, conversionHit: Function }
    }
}

@Injectable({providedIn: 'root'})
export class WindowService {

    isBrowser = true

    constructor(
        @Inject(DOCUMENT) private _doc: Document,
        @Inject(PLATFORM_ID) public platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId)
    }

    getWindow(): Window | null {
        return this._doc.defaultView;
    }

    getLocation(): Location {
        return this._doc.location;
    }

    createElement(tag: string): HTMLElement {
        return this._doc.createElement(tag);
    }

    getWidth(): number {
        return this._doc.defaultView ? this._doc.defaultView.innerWidth : 0;
    }

    getHeight(): number {
        return this._doc.defaultView ? this._doc.defaultView.innerHeight : 0;
    }

    matchMedia(mediaQuery: string) {
        return this._doc.defaultView ? this._doc.defaultView.matchMedia(mediaQuery).matches : false
    }
}
