<div class="dialog-container">
    <div mat-dialog-title class="flex-row items-center">
        <h1 class="flex-row justify-start items-center title">
            <mat-icon class="mr-1">image</mat-icon>
            {{ 'web.edit-mode.images.edit.title'|translate }}
        </h1>
    </div>
    <div mat-dialog-content class="flex-row gap-1 flex-grow">
        <mat-tab-group class="flex-100" mat-align-tabs="start" mat-stretch-tabs="false" #tabs>
            <mat-tab label="{{'web.edit-mode.images.edit.general'|translate}}">
                <div class="flex-row p-1" *ngIf="imageModel">
                    <div class="flex-row flex-40 items-center justify-center">
                        <img class="max-100" [src]="imageRealSrc|imgAPISrc:{width: 512, height: 0}"
                             [alt]="imageModel.alt"/>
                    </div>
                    <form class="flex-col flex-59 flex-offset-1" ngForm>
                        <mat-form-field>
                            <input matInput minlength="3"
                                   placeholder="{{'web.edit-mode.images.edit.alt'|translate}}"
                                   [(ngModel)]="imageModel.alt" name="alt" type="text">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput required minlength="3"
                                   placeholder="{{'web.edit-mode.images.edit.src'|translate}}"
                                   [(ngModel)]="imageModel.src" name="src" type="text">
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput minlength="3"
                                   placeholder="{{'web.edit-mode.images.edit.link'|translate}}"
                                   [(ngModel)]="imageModel.link" name="link" type="text">
                        </mat-form-field>
                        <mat-checkbox [(ngModel)]="imageModel.isExternalLink" name="isExternalLink">
                            {{'web.edit-mode.images.edit.isExternalLink'|translate}}
                        </mat-checkbox>
                    </form>
                </div>
            </mat-tab>
            <mat-tab label="{{'web.edit-mode.images.edit.files'|translate}}">
                <ng-template matTabContent>
                    <div class="flex-row" style="overflow: hidden">
                        <div class="flex-col flex-25">
                            <mat-tree [dataSource]="listOfDirs" [treeControl]="treeControl" class="flex-grow">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="0" @simpleRollIn>
                                    <li class="mat-tree-node"
                                        [ngClass]="{'mat-color-primary': dirModel && node.tmpID === dirModel.tmpID}">
                                        <mat-icon>folder</mat-icon>
                                        <button mat-button (click)="changeDir(node)">{{node.name}}</button>
                                    </li>
                                </mat-tree-node>
                                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding="0"
                                               @simpleRollIn>
                                    <li class="flex-row space-between items-center mat-tree-node"
                                        [ngClass]="{'mat-color-primary': dirModel && node.tmpID === dirModel.tmpID}">
                                        <div class="justify-start items-center">
                                            <mat-icon>folder</mat-icon>
                                            <button mat-button (click)="changeDir(node)">{{node.name}}</button>
                                        </div>
                                        <button mat-icon-button matTreeNodeToggle
                                                (click)="expandedDirs.toggle(node.tmpID)"
                                                [attr.aria-label]="('web.edit-mode.images.edit.toggle-menu'|translate)+node.title">
                                            <mat-icon> {{treeControl.isExpanded(node) ? 'expand_less' : 'expand_more'}}</mat-icon>
                                        </button>
                                    </li>
                                </mat-tree-node>
                            </mat-tree>
                            <mat-divider class="mt-05 mb-05"></mat-divider>
                            <button mat-button color="primary" (click)="addDir()" class="mt-05">
                                <mat-icon>create_new_folder</mat-icon>
                                {{'web.edit-mode.dir.add-folder'|translate}}</button>
                        </div>
                        <div class="flex-73 left-border pl-1 mt-1">
                            <div class="flex-row justify-start items-center mb-1" *ngIf="dirModel">
                                <div class="flex-60 ml-1 mr-1">
                                    <app-upload-file [endPoint]="filesEndPoint"
                                                     [autohiding]="true"
                                                     [isMultiple]="true"
                                                     [destinationPath]="dirModel.path"
                                                     (fileIsOk)="onUploadDone()"></app-upload-file>
                                </div>
                                <button mat-button color="primary" (click)="addDir(true)" class="flex-20 mt-05">
                                    <mat-icon>create_new_folder</mat-icon>
                                    {{'web.edit-mode.dir.add-sub-folder'|translate}}</button>
                                <button mat-button color="warn" (click)="onDeleteDir(dirModel.path)"
                                        class="flex-20 mt-05">
                                    <mat-icon>delete_forever</mat-icon>
                                    {{'web.edit-mode.dir.delete'|translate}}</button>
                            </div>
                            <div class="p-05" *ngIf="dirModel">
                                <mat-grid-list cols="5" rowHeight="1:1" gutterSize="1em">
                                    <mat-grid-tile *ngFor="let img of dirModel.files" class="mat-elevation-z4">
                                        <mat-grid-tile-header>
                                            <button mat-icon-button (click)="deleteFile(img)">
                                                <mat-icon color="warn">delete</mat-icon>
                                            </button>
                                        </mat-grid-tile-header>
                                        <img [src]="imageApiEndpoint+img.src + '&w=256&h=256' | safe"
                                             (click)="setImageSrc(img.src)"
                                             style="max-width: 100%"/>
                                        <mat-grid-tile-footer>{{img.filename}}</mat-grid-tile-footer>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

    </div>
    <div mat-dialog-actions align="end">
        <button mat-button mat-raised-button color="primary" (click)="setImage()" type="button">
            <mat-icon class="mr-1">done</mat-icon>
            {{'web.edit-mode.images.edit.set'|translate}}
        </button>
        <button
            type="button"
            color="default"
            mat-raised-button
            (click)="setImage()">{{'close'|translate}}</button>
    </div>
</div>
