import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {WindowService} from './window.service';

interface LayoutState {
    isMobile?: boolean
    sideBarOpened?: boolean
}

@Injectable({providedIn: 'root'})
export class LayoutService {

    layoutState: BehaviorSubject<LayoutState> = new BehaviorSubject<LayoutState>({
        isMobile: false,
        sideBarOpened: true
    });

    constructor(
        private windowService: WindowService
    ) {

    }

    private publishLayoutChange(layoutState: LayoutState) {
        this.layoutState.next({...this.layoutState.value, ...layoutState})
    }

    toggleSideBar() {
        this.publishLayoutChange({sideBarOpened: !this.layoutState.value.sideBarOpened})
    }

    adjustLayout(options: LayoutState) {
        this.publishLayoutChange({
            isMobile: this.isSm(),
            sideBarOpened: !this.isSm()
        });
    }

    isSm() {
        return this.windowService.matchMedia(`(max-width: 959px)`);
    }
}
