import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, HostListener, Input,
    OnInit
} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {ImageData} from '../../../block'
import {defaultImageData, noImage} from '../../../default.blocks'
import {MatDialog} from '@angular/material/dialog'
import { ImageApiSrcPipe } from '../../../../../pipes/image-api-src.pipe';
import { ImageFadeInDirective } from '../../../../../directives/image.fadeIn.directive';
import { ShowIfInViewDirective } from '../../../../../directives/showIfInView.directive';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-image-block]',
    templateUrl: './image-block.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, ShowIfInViewDirective, ImageFadeInDirective, AsyncPipe, ImageApiSrcPipe]
})
export class ImageBlockComponent implements OnInit, AfterViewInit {

    width = 100
    height = 100
    left = 0
    top = 0
    imageSrc: BehaviorSubject<string> = new BehaviorSubject<string>('')
    maxWidthInPercent = 0
    imageRatio = 1
    blockDimensions?: { width: number, height: number }
    loading = false
    widthInPx = 150
    heightInPx = 150

    @Input() id: number | null = null
    @Input() imageData: ImageData = defaultImageData
    @Input() imageCanLazyLoad = true

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkDimensions()
    }

    constructor(
        private dialog: MatDialog,
        private element: ElementRef,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        if (this.imageData.max) {
            this.imageRatio = this.imageData.max.height / this.imageData.max.width
        }
        this.height = this.imageData.height || this.height
        this.width = this.imageData.width || this.width
        this.left = this.imageData.left || this.left
        this.top = this.imageData.top || this.top
        this.imageData.src = this.imageData.src || noImage
    }

    ngAfterViewInit() {
        this.checkDimensions()
    }

    checkDimensions() {
        this.blockDimensions = {
            width: this.element.nativeElement.offsetWidth,
            height: this.element.nativeElement.offsetHeight
        }
        this.widthInPx = this.blockDimensions.width * (this.imageData?.width || 100) / 100
        this.heightInPx = this.widthInPx * this.imageRatio
        this.imageSrc.next(this.imageData.src);
        this.cd.detectChanges()
    }

}
