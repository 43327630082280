import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, EventEmitter, Output, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import {MenuItem} from '../menu';
import {PageService} from '../../../services/page.service';
import {DataSet} from '../../../models/DataSet';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, NavigationStart, Router, RouterLinkActive, RouterLink } from '@angular/router';
import {filter, startWith} from 'rxjs/operators';
import { FilterPipe } from '../../../pipes/filter.pipe';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import {Page} from "../page";

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatListModule, NgClass, NgFor, NgIf, MatMenuModule, forwardRef(() => MenuItemComponent), RouterLinkActive, RouterLink, FilterPipe]
})
export class MainMenuComponent implements OnInit {

    menu: MenuItem[] = []
    activeItemID = 0
    @Input() direction: 'row' | 'column' = 'row'

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private pageService: PageService
    ) {
    }

    ngOnInit() {
        this.loadMenu()
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            startWith(this.router)
        ).subscribe(() => {
            this.activeItemID = 0
        })

        this.pageService.dataChanged.subscribe(() => {
            this.loadMenu()
        })
    }

    loadMenu() {
        this.pageService.listActivePages().subscribe((menu: DataSet<MenuItem>) => {
            this.menu = menu.data || []
            this.cd.detectChanges()
        })
    }

    activeItemByChildren(isActive: boolean, activeItemID: number) {
        this.activeItemID = isActive ? activeItemID : this.activeItemID
    }
}

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatMenuModule, NgFor, NgIf, NgClass, RouterLinkActive, RouterLink, FilterPipe]
})
export class MenuItemComponent implements OnInit {

    @Input() items?: MenuItem[]
    @Output() isActivated = new EventEmitter<boolean>()
    isActive = false
    @ViewChild('childMenu') childMenu?: MatMenu

    constructor(private router: Router) {

    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            startWith(this.router)
        ).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlParts = event.url.split('/')
                if (this.items && this.items.findIndex(i => i.link === urlParts[urlParts.length - 1]) > -1) {
                    this.isActive = true
                    this.isActivated.emit(true)
                }
            }
        })
    }

    activeByChildren(isActive: boolean) {
        this.isActive = isActive
        this.isActivated.emit(isActive)
    }


}
