import {Observable} from 'rxjs';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {AppBlockChooserComponent} from './app-block-chooser.component';

export interface ChooserDialogData {
    title?: string
    message?: string
    excludeBlockTypes?: Array<string>
}

@Injectable()
export class AppBlockChooserService {
    constructor(private dialog: MatDialog, private translate: TranslateService) {
    }

    public choose(data: ChooserDialogData = {}): Observable<string | false> {
        data.title = data.title || this.translate.instant('web.edit-mode.block.chooser.title');
        data.message = data.message || this.translate.instant('web.edit-mode.block.chooser.message');
        let dialogRef: MatDialogRef<AppBlockChooserComponent>;
        dialogRef = this.dialog.open(AppBlockChooserComponent, {
            width: '50%',
            disableClose: false,
            data: {title: data.title, message: data.message, excludeBlockTypes: data.excludeBlockTypes || []}
        });
        return dialogRef.afterClosed();
    }
}
