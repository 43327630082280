import {ChangeDetectionStrategy, Component, Inject} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import {CookieConsentService} from '../cookie.service';
import {Router} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-cookie-accept-modal',
    templateUrl: './cookie-accept.modal.component.html',
    styleUrls: ['./cookie-accept.modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatDialogModule, MatIconModule, MatButtonModule, TranslateModule]
})
export class CookieAcceptModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CookieAcceptModalComponent>,
        private cookieService: CookieConsentService,
        private router: Router
    ) {

    }

    handleAcceptMandatory() {
        this.cookieService.acceptMandatory();
        this.dialogRef.close(true)
    }

    handleAcceptAll() {
        this.cookieService.acceptAll()
        this.dialogRef.close(true);
    }

    handleGoToSettings() {
        this.router.navigate(['/', 'cookie'])
        this.dialogRef.close()
    }

}
