<div class="flex-col">
    <div class="flex-row flex-wrap justify-start items-center cookie">
        <mat-icon class="flex-40px">check</mat-icon>
        <div class="flex-auto flex-grow flex-shrink">{{'cookie.mandatory-title'|translate}}</div>
        <mat-checkbox class="flex-grow flex-auto flex-gt-sm-20" checked="true" disabled>
            <label>{{'cookie.allowed'|translate}}</label>
        </mat-checkbox>
        <div class="mb-1 text">
            {{'cookie.mandatory-text'|translate}}
        </div>
    </div>
    <div class="flex-row flex-wrap justify-start items-center cookie">
        <mat-icon class="flex-40px">{{consent.a ? 'check' : 'close'}}</mat-icon>
        <div class="flex-auto flex-grow flex-shrink">{{'cookie.analytics-title'|translate}}</div>
        <mat-checkbox class="flex-grow flex-auto flex-gt-sm-20" [(ngModel)]="consent.a">
            <label>{{('cookie.' + (consent.a ? 'allowed' : 'disallowed'))|translate}}</label>
        </mat-checkbox>
        <div class="mb-1 text">
            {{'cookie.analytics-text'|translate}}
        </div>

    </div>
    <div class="flex-row flex-wrap justify-start items-center cookie">
        <mat-icon class="flex-40-px">{{consent.m ? 'check' : 'close'}}</mat-icon>
        <div class="flex-auto flex-shrink flex-grow">{{'cookie.marketing-title'|translate}}</div>
        <mat-checkbox class="flex-auto flex-grow flex-gt-sm-20" [(ngModel)]="consent.m">
            <label>{{('cookie.' + (consent.m ? 'allowed' : 'disallowed'))|translate}}</label>
        </mat-checkbox>
        <div class="mb-1 text">
            {{'cookie.marketing-text'|translate}}
        </div>
    </div>

    <div class="flex-col justify-center gap-1 flex-gt-sm-row">
        <button mat-raised-button (click)="handleSetSettings()">{{'cookie.set-settings'|translate}}</button>
        <button mat-raised-button color="primary" (click)="handleAcceptAll()">{{'cookie.allow-all'|translate}}</button>
    </div>

</div>
