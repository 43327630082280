<div class="flex-row justify-center items-center slider-wrapper">
    <div class="flex-row flex-100 justify-center items-center" *appShowIfInView>
        <button mat-icon-button type="button" class="arrow left" (click)="handlePrev()">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-icon-button type="button" class="arrow" (click)="handleNext()">
            <mat-icon>chevron_right</mat-icon>
        </button>

        <ng-container *ngFor="let slide of sliders">

            <object *ngIf="slide.id === activeSlide && slide.type === 'svg'&& !slide.isInteractive && slide.path" type="image/svg+xml"
                    (@fadeInOut.done)="handleShowNext()" @fadeInOut
                    (swiperight)="handleNext()" (swipeleft)="handlePrev()"
                    [data]="(slide.path|imgAPISrc|safe:'resourceUrl')"></object>

            <img *ngIf="slide.id === activeSlide && slide.type === 'image'  && !slide.isInteractive && slide.path" @fadeInOut
                 (@fadeInOut.done)="handleShowNext()"
                 [src]="slide.path|imgAPISrc:blockDimensions"/>

            <svg
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                *ngIf="slide.id === activeSlide && slide.isInteractive"
                @fadeInOut
                (@fadeInOut.done)="handleShowNext()"
                (swiperight)="handleNext()" (swipeleft)="handlePrev()"
                width="100%"
                [attr.viewBox]="'0 0 '+ sliderAspectRatio.width + ' ' + sliderAspectRatio.height"
                [attr.id]="'svg-'+slide.id"
            >
                <image
                    *ngIf="slide.imageID && slide.path"
                    y="0"
                    x="0"
                    [attr.id]="'image-'+slide.id"
                    [attr.xlink:href]="slide.path|imgAPISrc:sliderAspectRatio"
                    preserveAspectRatio="none"
                    [attr.height]="sliderAspectRatio.height"
                    [attr.width]="sliderAspectRatio.width"
                />

                <rect
                    [@overlayIn]="(slide.path ? svgOverlayState : 'blacked')"
                    style="fill-opacity:0.5;fill-rule:nonzero;stroke:none;stroke-width:15;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers"
                    [attr.id]="'overlay-'+slide.id"
                    [attr.height]="sliderAspectRatio.height"
                    [attr.width]="sliderAspectRatio.width"
                    x="0"
                    y="0"
                />

                <a [attr.href]="('https://' + (slide.link || ''))" [@productIn]="svgOverlayState">
                    <image
                        *ngIf="slide.productPath"
                        y="20px"
                        [attr.x]="(slide.textPosition === 'right' ? 0 : '60%')"
                        [style.margin]="(slide.textPosition === 'right' ? '0 20px' : '0 20px')"
                        [attr.id]="'product-'+slide.id"
                        [attr.xlink:href]="slide.productPath|imgAPISrc:sliderProductAspectRatio"
                        preserveAspectRatio="none"
                        [attr.height]="sliderProductAspectRatio.height"
                        [attr.width]="sliderProductAspectRatio.width"
                        style="cursor: pointer"
                    />
                </a>

                <g [attr.id]="'text-'+slide.id" [@textIn]="svgOverlayState">
                    <foreignObject
                        [attr.x]="(slide.textPosition === 'left' ? 0 : '40%')"
                        y="80px"
                        height="100%" width="58%"
                        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                        <p xmlns="http://www.w3.org/1999/xhtml"
                           [style.text-align]="(slide.textPosition || 'right')"
                           [style.margin]="(slide.textPosition === 'left' ? '0 1rem 0 2rem' : '0 1rem')"
                           style="white-space: pre-wrap;font-style:normal;font-weight:lighter;
                           font-size:26px;line-height:125%;font-family:Segoe UI, sans-serif;
                           letter-spacing:0;word-spacing:0;color:#f2f0d9">
                            {{slide.name}}</p>
                    </foreignObject>
                    <foreignObject
                        [attr.x]="(slide.textPosition === 'left' ? 0 : '40%')"
                        y="120px"
                        height="100%" width="58%"
                        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility">
                        <p xmlns="http://www.w3.org/1999/xhtml"
                           [style.text-align]="(slide.textPosition || 'right')"
                           [style.margin]="(slide.textPosition === 'left' ? '0 1rem 0 2rem' : '0 1rem')"
                           style="white-space: pre-wrap;font-style:normal;font-weight:bold;
                           font-size:40px;line-height:125%;font-family:Segoe UI, sans-serif;
                           letter-spacing:0;word-spacing:0;color:#f2f0d9; border-bottom: 5px solid #c74900">
                            <a style="color: inherit"
                               [href]="('https://' + (slide.link || ''))">{{slide.description || ''}}</a>
                        </p>
                    </foreignObject>
                </g>
            </svg>


        </ng-container>
    </div>
</div>

