<div class="app-contact-form mat-elevation-z2 pt-1" [@slideInOut]>
    <div class="title mt-1">{{'web.contact.title'|translate}}</div>
    <div *ngIf="completed !== -1" class="alert" [@slideInOut]
         [ngClass]="{success: completed === 0, error: completed, hidden: completed === -1}" [ngSwitch]="completed">
        <p *ngSwitchCase="0">{{'web.contact.snack.sent'|translate}}</p>
        <p *ngSwitchCase="1">{{'web.contact.snack.error'|translate}}</p>
        <button mat-raised-button *ngSwitchCase="1" (click)="resetForm()">
            <mat-icon>refresh</mat-icon>
            {{'web.contact.reset'|translate}}
        </button>
    </div>
    <form #contactForm="ngForm" (ngSubmit)="sendContactForm()" class="flex-col mb-1">
        <mat-form-field>
            <mat-label>{{'web.contact.client'|translate}}</mat-label>
            <input matInput name="client" required
                   #client="ngModel" [(ngModel)]="contactData.client">
            <mat-icon matSuffix>person</mat-icon>
            <mat-error *ngIf="client.errors && (client.dirty || client.touched) && (client.errors)"
                       class="form-error-msg">
                {{'web.contact.client-is-required'|translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'web.contact.email'|translate}}</mat-label>
            <input matInput type="email" name="email" required
                   #email="ngModel"
                   [(ngModel)]="contactData.email">
            <mat-icon matSuffix>drafts</mat-icon>
            <mat-error *ngIf="email.errors && (email.dirty || email.touched) && (email.errors)"
                       class="form-error-msg">
                {{'web.contact.email-is-required'|translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'web.contact.phone'|translate}}</mat-label>
            <input type="tel" matInput name="phone"
                   [(ngModel)]="contactData.phone">
            <mat-icon matSuffix>call</mat-icon>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'web.contact.subject'|translate}}</mat-label>
            <input matInput name="subject"
                   [(ngModel)]="contactData.subject">
            <mat-icon matSuffix>question_answer</mat-icon>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'web.contact.message'|translate}}</mat-label>
            <textarea matInput name="message" required
                      #message="ngModel" [(ngModel)]="contactData.message"></textarea>
            <mat-icon matSuffix>create</mat-icon>
            <mat-error *ngIf="message.errors && (message.dirty || message.touched) && (message.errors)"
                       class="form-error-msg">
                {{'web.contact.message-is-required'|translate}}
            </mat-error>
        </mat-form-field>
        <button mat-raised-button [disabled]="contactForm.invalid || submitting" color="accent">
            <mat-icon class="mr-1">send</mat-icon>
            {{'web.contact.send'|translate}}
        </button>
    </form>
</div>
